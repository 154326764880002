<template>
  <div class="Page" :class="{ 'Page--landscape': landscape, 'Page--marginless': marginless }">
    <div class="Print w-100 h-100 d-flex flex-column">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    landscape: {
      type: Boolean,
      default: false
    },
    marginless: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.Page {
  position: relative;
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);

  .Print {
    padding: 0.5cm;
  }
}
.Page--landscape {
  width: 29.7cm;
  height: 21cm;
}
.Page--marginless {
  .Print {
    padding: 0
  }
}
</style>