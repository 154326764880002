<template>
  <div class="DonutChart">
    <GChart         
      type="PieChart"
      :data="chartData"
      :options="chartOptions" />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

export default {
  name: 'DonutChart',
  components: { GChart },
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Verdeling openbaar en privaat parkeren',
        },
        legend: { position: 'none' },
        pieHole: 0.55,
        colors: [
          '#3B547B',
          '#689AC5'
        ],
        height: 190,
        width: 190,
        chartArea: { top: 0, bottom: 0, left: 20, right: 20 },
        pieStartAngle: 270,
        pieSliceText: 'none',
        slices: {
          2: {
            color: 'transparent'
          }
        },
        theme: 'maximized',
        animation: {
          duration: 500
        }
      }
    }
  },
  computed: {
    chartData() {
      let chartSerieLabels = ['locatie', 'aandeel', { role: 'tooltip' } ]
      let data = [
        chartSerieLabels
      ]

      this.data.forEach(entry => {
        data.push([entry[0], entry[1], entry[2]])
      })
      
      return data
    }
  }
}
</script>

<style>
.DonutChart {
  height: calc(190px / 2);
  overflow: hidden;
}
</style>