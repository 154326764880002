<template>
  <div class="CollapsableSegment">
    <h4 class="CollapsableSegment__Header d-flex justify-content-between" @click="togggleVisibility">
      <span v-if="label" class="flex-grow-1">{{ label }}</span>
      <span>
        <slot name="header" />
      </span>
      <SvgIcon class="ml-3" :icon="icon" :hasFill="true" />
    </h4>
    <b-collapse v-model="visibilityState">
      <div class="pt-3">
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'

export default {
  name: 'CollapsableSegment',
  components: { SvgIcon },
  props: {
    label: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visibilityState: false
    }
  },
  computed: {
    /**
     * Change the open / close icon
     */
    icon() {
      return this.visibilityState ? 'chevron-up-regular' : 'chevron-down-regular'
    }
  },
  watch: {
    /**
     * If visibility prop changes, it overrides the current visibilityState
     */
    visible() {
      this.visibilityState = this.visible
    }
  },
  created() {
    // The visibility prop dictates the initial visibility state
    this.visibilityState = this.visible
  },
  methods: {
    /**
     * Toggle the visibility of the legend items
     */
    togggleVisibility() {
      this.visibilityState = ! this.visibilityState
    }
  }
}
</script>

<style lang="scss">
.CollapsableSegment {
  &__Header {
    cursor: pointer;
    fill: #7F8FA4;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      width: calc(100% + 10px);
      left: -5px;
      border-bottom: 1px solid lighten(#7F8FA4, 20%);
    }

    &:hover {
      color: darken(#7F8FA4, 20%);
      fill: darken(#7F8FA4, 20%);
    }
  }
}
</style>