import {isLocatievoorstel} from "@/services/statusTranslations";

import privilegesMixin from "@/mixins/common/privilegesMixin";

import {CPO_TYPE, cpos, getCpoById} from "@/data/cpos";
import {mapGetters} from "vuex";

export default {
  mixins: [privilegesMixin],
  data() {
    return {
      fields: {
        cpo: {
          label: 'Charge Point Operator',
          value: '',
          type: 'select'
        },
      }
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentConfig']),
    isAllowedToEditCpo() {
      return this.superuser || this.admin
    },
    isLocatievoorstel() {
      return isLocatievoorstel({status: this.status})
    },
    cposByDeployment() {
      return cpos
        .filter(cpo => cpo.countries.includes(this.DeploymentConfig.country))
        .sort((a,b) => a.name.localeCompare(b.name))
    },
    cpoSelectOptions() {
      return this.cposByDeployment.map(cpo => ({value: cpo.uuid, text: cpo.name}))
    },
    currentCpo() {
      if (this.stakeholders === undefined) {
        return false
      }

      return this.stakeholders.find(stakeholder => stakeholder.type === CPO_TYPE)
    },
  },
  methods: {
    $_chargingpointCpoMixin_getCpoByFieldSelection() {
      return cpos.find(cpo=>cpo.uuid === this.fields.cpo.value)
    },
    $_chargingpointCpoMixin_getCpoById(uuid) {
      return getCpoById(cpos, uuid)
    },
    $_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders() {
      // if there was an cpo already assigned, filter it out and add the selected one
      const stakeholders = this.stakeholders.filter(stakeholder => stakeholder.type !== CPO_TYPE)
      if (this.fields.cpo.value !== null) {
        stakeholders.push(this.$_chargingpointCpoMixin_getCpoByFieldSelection())
      }

      return stakeholders
    }
  }
}