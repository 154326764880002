import {ACTION_SIDEBAR_TAB} from "@/valueholders/enums";


/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const DEFAULT_TAB = ACTION_SIDEBAR_TAB.INFO

const state = {
  selectedActionSidebarTab: DEFAULT_TAB,
  ready: false,
}

const getters = {
  getSelectedActionSidebarTab: state => state.selectedActionSidebarTab,
  isAppReady: state => state.ready,
}

const actions = {
  selectTab({commit}, { tabName }) {
    commit('setSelectedActionSidebarTab', { tabName })
  },
}

const mutations = {
  setSelectedActionSidebarTab(state, { tabName }) {
    state.selectedActionSidebarTab = tabName ? tabName : DEFAULT_TAB
  },
  setAppReady(state, { value }) {
    state.ready = value
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
