import {provincieCodeByMunicipalityCode} from "@/services/municipalities";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    admin() {
      return this.hasAdminAccess({code: this.getActiveMunicipality})
    },
    hasAccess() {
      return this.superuser ||
        this.admin ||
        provincieCodeByMunicipalityCode({code: this.getActiveMunicipality}) === 28
    },
  }
}