export default {
  methods: {
    $_chartMixin_calculateViewWindow(limit = null, ...entryValues) {
      const spacer = 1.3

      // determine highest entry value
      const maxValue = Math.max(...entryValues)

      const limitViewWindow = limit * spacer
      const maxValueViewWindow = Math.round(maxValue * spacer)

      // at least to be shown
      const minHeight = 10

      // calculate if highest value in dataset, limit or just a min height will be the view window
      return Math.max(maxValueViewWindow, limitViewWindow, minHeight)
    }
  }
}