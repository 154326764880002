<template>
  <MapBoxPopup
    :coordinates="coordinates"
    :show="show"
    @close="handleClose">

    <div>
      <div class="d-flex flex-column" v-if="id">

        <div v-if="address">
          {{ address }}
        </div>
        <div>
          {{ statusLabel }}
        </div>
        <div v-if="currentCpo">
          CPO: {{ currentCpo.name }}
        </div>
        <div>
          ID: {{ `${code}-${id}` }}
        </div>
        <div v-if="prio.order">
          Prioriteit: {{ prio.order }} {{ prio.fase === 1 ? '(Fase 1)' : '' }} {{ isBraLiProvincieCode && prio.fase === 2 ? '(Fase 2)' : '' }}
        </div>
        <div v-if="user.name">
          <span class="text-muted">{{ user.name }}</span>
        </div>
        <div class="mt-3" v-if="remark">
          Je kunt de laadpaal bewerken aan de rechterzijkant onder de locatieinformatie tab
        </div>

      </div>

      <div v-else>
        <Form @submit="handleSubmitAdd">
          <FormField
            v-model="fields.remark.value"
            v-bind="fields.remark" />
          <FormField
            v-if="isPlanModeAdmin"
            v-model="fields.status.value"
            v-bind="fields.status"
            :options="statusOptions" />
          <FormField
            v-if="isPlanModeAdmin && isAllowedToEditCpo"
            v-model="fields.cpo.value"
            v-bind="fields.cpo"
            :options="cpoSelectOptions" />
          <b-button
            type="submit"
            class="mt-2"
            size="sm"
            variant="primary">
            Toevoegen
          </b-button>
        </Form>
      </div>

    </div>

  </MapBoxPopup>
</template>

<script>

/**
 * TODO: Refactor to better quality code
 */

import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'
import MapBoxPopup from '@/components/common/MapBoxPopup'

import { subscribe, unsubscribe } from '@/services/pusher'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import {EventBus} from "@/services/eventbus";
import {statusSlugToLabel} from "@/services/statusTranslations";

import chargingpointEditMixin from "@/mixins/chargingpoint/chargingpointEditMixin";
import chargingpointCpoMixin from "@/mixins/chargingpoint/chargingpointCpoMixin";
import userMixin from "@/mixins/common/userMixin";
import chargingpointsLoadMixin from "@/mixins/chargingpoint/chargingpointsLoadMixin";

export default {
  name: 'ChargerEditorPopup',
  components: { MapBoxPopup, Form, FormField },
  mixins: [chargingpointEditMixin, chargingpointsLoadMixin, chargingpointCpoMixin, userMixin],
  data() {
    return {
      show: false,

      coordinates: null,

      // Properties (matches ChargerEditorPopup)
      refId: null,
      id: null,
      code: null,
      remark: null,
      user: {
        name: null
      },
      stakeholders: [],
      status: null,
      address: null,

      prio: {
        order: null,
        exact: null,
        fase: null
      },

      fields: {
        remark: {
          label: 'Toelichting',
          value: '',
          type: 'textarea'
        },
        status: {
          label: 'Status',
          value: 'suggestion',
          type: 'select'
        },
      },

      // drag & drop
      draggingPoint: null,

      doNotDeselectChargingpoint: false
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints',
      'getChargingPointByRefId',
      'getLayers',
      'getNextChargingpointId',
      'getChargingPointIndexByRefId',
      'isChargingpointSelected',
    ]),
    isPlanModeAdmin() {
      return true
    },
    isBraLiProvincieCode() {
      return ['9006', '9007'].includes(this.getActiveMunicipality)
    },

    statusLabel() {
      return statusSlugToLabel({status: (this.status || 'definitive')})
    },
    /**
     * Duplicate ChargingPoint Id check
     *  In edge cases, users may create charging points with the same ID
     *  This computed works together with a watcher to take corrective action when duplicates appear
     */
    duplicateIdUsage() {
      let duplicates = this.getChargingPoints
        .map(item => item.data.properties.id)
        .reduce((result, id, index) => {
          result[id] = result[id] ? result[id].concat([index]) : [index]
          return result
        }, {})

      duplicates = Object
        .values(duplicates)
        .filter(entries => entries.length !== 1)
        .map(entries => entries.map(index => this.getChargingPoints[index].ref['@ref'].id))

      return duplicates
    },
  },
  watch: {
    /**
     * Hide the popup whenever the municiaplity changes
     */
    getActiveMunicipality: async function(code, previous) {
      this.handleClose()

      // Update Pusher connection
      unsubscribe({ channel: previous })
      let { channel } = await subscribe({ channel: code })
      this.channel = channel
    },

    /**
     * Changes to the pusher channel
     *  There are non for this component at this moment
     */
    // channel(channel) {
    //   if (channel === null) return
    // },

    /**
     * Handle the existance of duplicate id usage
     */
    duplicateIdUsage: async function(duplicates) {
      if (duplicates.length === 0) return

      let originalRefId = duplicates[0][0]

      /*
       * check if original chargingpoint still exists, so that only in case of real duplication the id is changed
       * fix for: https://ev-it.atlassian.net/browse/TOOLS-793
      */
      const { chargingpointExists } = await this.$_chargingpointEditMixin_exists({
        code: this.getActiveMunicipality,
        refId: originalRefId
      })

      if (chargingpointExists === false) {
        console.log('data seems out of date - reload chargingpoints')

        // if selected
        this.handleClose()

        await this.$_chargingpointsLoadMixin_loadChargingPoints({
          code: this.getActiveMunicipality
        })

        return
      }

      // Only adjust one chargingpoint ( the last one ). This will change the dataset and trigger the watcher again
      let duplicationRefId = duplicates[0][duplicates[0].length -1]

      let chargingpoint = this.getChargingPointByRefId({ refId: duplicationRefId })
      let id = this.getNextChargingpointId
      chargingpoint.data.properties.id = id

      // Save with new id
      this.$_chargingpointEditMixin_save({
        code: chargingpoint.data.code,
        id,
        ref: chargingpoint.ref,
        stakeholders: chargingpoint.data.properties.stakeholders || [],
        status: chargingpoint.data.properties.status,
        user: {
          name: chargingpoint.data.properties.user.name,
        },
        coordinates: chargingpoint.data.coordinates,
        remark: chargingpoint.data.properties.remark,
        geocoder: false
      })
    }
  },
  /**
   * Bind event handlers to MapBox & connect to Pusher
   */
  created: async function(){
    this.$store.map.on('click', 'chargingpoints', this.handleClickMarkerEvent)
    this.$store.map.on('click', this.handleAddMarkerEvent)

    EventBus.$on('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$on('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.on('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.on('mouseleave', 'chargingpoints', this.hidePointer)

    // Drag & drop
    this.$store.map.on('mousedown', 'chargingpoints', this.startDrag)
    this.$store.map.on('touchstart', 'chargingpoints', this.startDrag)

    await this.$_chargingpointEditMixin_connectToPusher()
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.$store.map.off('click', 'chargingpoints', this.handleClickMarkerEvent)
    this.$store.map.off('click', this.handleAddMarkerEvent)

    EventBus.$off('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$off('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.off('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.off('mouseleave', 'chargingpoints', this.hidePointer)

    this.$store.map.off('mousedown', 'chargingpoints', this.startDrag)
    this.$store.map.off('touchstart', 'chargingpoints', this.startDrag)
  },


  /************************************************************************************
   * START COPY & PASTE CODE + minimal modifications
   *  TODO: Review everything below this line...
   */
  methods: {
    ...mapActions('planmode', [
      'selectChargingPoint',
      'deselectChargingPoint',
    ]),
    ...mapMutations('planmode', [
      'updateChargingpoint'
    ]),

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.$store.map.getCanvas().style.cursor = ''
    },

    /**
     * Drag & Drop
     */
    startDrag(e) {
      if (e.features.length !== 1) return

      // Prevent the default map drag behavior.
      e.preventDefault()

      this.$store.map.getCanvas().style.cursor = 'grab'

      this.$store.map.on('mousemove', this.handleMove)
      this.$store.map.once('mouseup', this.handleUp)
    },

    /*
     * Load the outside selected point's properties
    */
    handleSelectChargingpointEvent(refId) {
      let chargingpoint = this.getChargingPointByRefId({ refId })

      this.setChargingpointData({ chargingpoint })

      this.show = true
    },

    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      // Get the charging point to get the exact coordinates & user details
      let chargingpoint = this.getChargingPointByRefId({
        refId: e.features[0].properties.refId
      })

      // if chargingpoint layer is not visible don't react to clicks
      const chargingpointLayer = this.getLayers.find(layer => layer.id === `chargingpoints-${chargingpoint.data.properties.status}`)
      if (chargingpointLayer.visible === false) {
        return
      }

      // if there is already a popup opened, set a flag to not deselect the chargingpoint in the store.
      // This is to avoid a flickering of the sidebar, since we need to wait for the nextTick and the current popup is closed

      if (this.isChargingpointSelected && this.show) {
        this.doNotDeselectChargingpoint = true
      }

      this.show = false

      this.$nextTick(() => {
        this.setChargingpointData({ chargingpoint })

        this.show = true
        this.doNotDeselectChargingpoint = false
      })
    },
    setChargingpointData({ chargingpoint }) {
      this.selectChargingPoint({ refId: chargingpoint.ref['@ref'].id });

      this.refId = chargingpoint.ref['@ref'].id;

      this.id = chargingpoint.data.properties.id
      this.code = chargingpoint.data.code
      this.remark = chargingpoint.data.properties.remark || ''
      this.user.name = chargingpoint.data.properties.user
        ? chargingpoint.data.properties.user.name
        : (chargingpoint.data.properties.user_name || 'EVTools')
      this.stakeholders = chargingpoint.data.properties.stakeholders || []
      this.status = chargingpoint.data.properties.status || 'definitive'

      this.address = chargingpoint.data.address ? chargingpoint.data.address.simple_address : ''

      this.coordinates = chargingpoint.data.coordinates

      this.prio = {
        order: chargingpoint.data.prio ? chargingpoint.data.prio.order : null,
        exact: chargingpoint.data.prio ? chargingpoint.data.prio.exact : null,
        fase:  chargingpoint.data.prio ? chargingpoint.data.prio.fase : null,
      }
    },
    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false
      this.refId = null

      // if this flag is set, we don't want to deselect the chargingpoint in the store to avoid a flickr,
      // while switching between chargingpoints on the map
      if (this.doNotDeselectChargingpoint === false) {
        this.deselectChargingPoint();
      }
    },

    /**
     * Show the form to add a charging point
     */
    handleAddMarkerEvent(e) {
      if (e._defaultPrevented) return

      // Only admins & consultants may add a point
      if (! this.isPlanModeAdmin) return

      this.id = null
      this.coordinates = e.lngLat.toArray()
      // this.showMarker = true
      this.show = true
    },

    /**
     * Track movement
     */
    handleMove(e) {
      if (e._defaultPrevented) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return
      }

      if (this.refId !== null) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return
      }

      let features = this.$store.map.queryRenderedFeatures(e.point, {
        layers: ['chargingpoints']
      })

      // if not already dragging a point, and no feature is at the cursor, exit
      if (this.draggingPoint === null && features.length === 0) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return null
      }

      // store the point being dragged
      if (this.draggingPoint === null) {
        let point = this.getChargingPointByRefId({
          refId: features[0].properties.refId
        })

        this.draggingPoint = {
          refId: point.ref['@ref'].id,
          coordinates: point.data.coordinates,
          original: point.data.coordinates
        }
      }

      // Set a UI indicator for dragging.
      this.$store.map.getCanvas().style.cursor = 'grabbing'

      // Note that we're updating the dataset without persisting the location
      // persisting occurs when the user lets go of the marker
      let coords = e.lngLat

      this.draggingPoint.coordinates = [coords.lng, coords.lat]

      // Make a local update to the chargingpoint collection
      let index = this.getChargingPointIndexByRefId({ refId: this.draggingPoint.refId })
      let chargingpoint = this.getChargingPointByRefId({ refId: this.draggingPoint.refId })
      chargingpoint.data.coordinates = [coords.lng, coords.lat]
      this.updateChargingpoint({ chargingpoint, index })
    },
    /**
     * End of movement
     */
    handleUp() {
      this.$store.map.getCanvas().style.cursor = ''

      if (this.draggingPoint === null) {
        this.$store.map.off('mousemove', this.handleMove)
        this.$store.map.off('touchmove', this.handleMove)
        return
      }

      this.$bvModal.msgBoxConfirm(
        'Bevestig het verplaatsen van dit punt.', {
          okVariant: 'danger',
          okTitle: 'Bevestigen',
          cancelTitle: 'Annuleren'
        })
        .then(confirmed => {
          let chargingpoint = this.getChargingPointByRefId({ refId: this.draggingPoint.refId })

          if (confirmed) {

            this.$_chargingpointEditMixin_save({
              code: chargingpoint.data.code,
              ref: chargingpoint.ref, // full ref
              id: chargingpoint.data.properties.id,
              stakeholders: chargingpoint.data.properties.stakeholders,
              status: chargingpoint.data.properties.status,
              user: {
                name: chargingpoint.data.properties.user_name || chargingpoint.data.properties.user.name,
              },
              coordinates: chargingpoint.data.coordinates,
              remark: chargingpoint.data.properties.remark,
              geocoder: true
            })

          } else if (this.draggingPoint.refId) {

            let index = this.getChargingPointIndexByRefId({ refId: this.draggingPoint.refId })
            chargingpoint.data.coordinates = this.draggingPoint.original
            this.updateChargingpoint({ chargingpoint, index })
          }

          this.draggingPoint = null

          // Unbind mouse/touch events
          this.$store.map.off('mousemove', this.handleMove)
          this.$store.map.off('touchmove', this.handleMove)
        })
    },

    /**
     *
     */
    handleSubmitAdd() {
      let id = this.getNextChargingpointId

      let status = !this.isPlanModeAdmin ? 'suggestion' : (this.fields.status.value || 'suggestion')

      const stakeholders = this.$_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders()

      this.$_chargingpointEditMixin_save({
        code: this.getActiveMunicipality,
        id,
        stakeholders: stakeholders,
        status: status,
        user: {
          name: this.currentUserName,
        },
        coordinates: this.coordinates,
        remark: this.fields.remark.value,
        geocoder: true
      })

      this.show = false
    },
  },


}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
.FormField .invalid-feedback {
  top: 0 !important;
}
</style>