<template>
  <div class="FeaturePanel d-flex">
    <aside class="FeaturePanel__Aside flex-shrink-0 mr-3">
      <h3 class="FeaturePanel__Title">
        Feature Instellingen
      </h3>
      <div class="w-75 mt-3">
        <p>
          Wanneer de prognose kaart en het upload portaal beiden zijn uitgeschakeld, wordt de gemeente geheel uitgeschakeld voor eindgebruikers.
        </p>
      </div>
    </aside>

    <div class="FeaturePanel__Main flex-grow-1">

      <div class="d-flex align-items-center">
        <span class="FeaturePanel__Label mr-3">
          Prognose Kaart
        </span>

        <ToggleSwitch :disabled="!hasLayers" v-model="data.prognose" />
        
        <span class="flex-grow-1 ml-3 text-muted">(Vereist kaart lagen)</span>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="FeaturePanel__Label mr-3">
          Plankaart
        </span>

        <ToggleSwitch :disabled="!data.prognose || !hasLayers" v-model="planmodeModel" />
        
        <span class="flex-grow-1 ml-3 text-muted">(Vereist prognose kaart)</span>
        <SvgIcon id="plankaart" icon="info-circle-regular" />
        <b-tooltip target="plankaart" triggers="hover">
          De plankaart feature is afhankelijk van de prognose kaart.
        </b-tooltip>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="FeaturePanel__Label mr-3">
          Upload Portaal
        </span>

        <ToggleSwitch v-model="data.upload" />
        
        <span class="flex-grow-1"></span>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="FeaturePanel__Label mr-3">
          Factsheet
        </span>

        <ToggleSwitch v-model="data.factsheet" />
        
        <span class="flex-grow-1 ml-3 text-muted"></span>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="FeaturePanel__Label mr-3">
          Laadpalen Export
        </span>

        <ToggleSwitch v-model="data.export" />
        
        <span class="flex-grow-1 ml-3 text-muted"></span>
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="FeaturePanel__Label mr-3">
          Monitoring
        </span>

        <ToggleSwitch v-model="data.monitoring" />

        <span class="flex-grow-1 ml-3 text-muted"></span>
      </div>

    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/form/ToggleSwitch'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'FeaturePanel',
  components: { SvgIcon, ToggleSwitch },
  props: {
    values: {
      type: Object,
      required: true
    },
    hasLayers: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      data: {
        prognose: true,
        planmode: true,
        upload: true,
        factsheet: false,
        export: false,
        monitoring: false,
      },

      trackChanges: true
    }
  },
  computed: {
    /**
     * The planmode is dependent on the prognose feature, so it is automatically disabled when the prognose feature is disabled
     */
    planmodeModel: {
      get() {
        return this.data.prognose === false ? false : this.data.planmode
      },
      set(value) {
        this.data.planmode = value
      }
    },
  },
  watch: {
    /**
     * Values passed via props override the data, without signaling input changes
     */
    values() {
      this.setValues()
    },
    /**
     * When any input changes, pass it on
     */
    data: {
      handler(data) {
        if (this.trackChanges) {
          this.$emit('input', data)
        }
      },
      deep: true
    }
  },
  created() {
    this.setValues()
  },
  methods: {
    /**
     * Set prop values as new data, without signaling input changes
     */
    setValues() {
      this.trackChanges = false
      this.data = this.values

      this.$nextTick(() => {
        this.trackChanges = true
      })
    }
  }
}
</script>

<style lang="scss">
.FeaturePanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) { 
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 175px;
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }
}
</style>