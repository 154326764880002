<template>
  <div class="ActiveTab InfoTab">
    <ActiveTabHeader :chargingpoint="chargingpoint">
      <h4>Locatieinformatie</h4>
    </ActiveTabHeader>

    <div class="ActiveTab__Content">
      <LocatieInformatieContent v-if="!isPlanModeActive" :chargingpoint="chargingpoint" />
      <LocatieInformatieEdit v-else />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ActiveTabHeader from "@/components/map/sidebar/ActiveTabHeader";
import LocatieInformatieContent from "@/components/map/sidebar/LocatieInformatieContent";
import LocatieInformatieEdit from "@/components/map/sidebar/LocatieInformatieEdit";

export default {
  components: {LocatieInformatieEdit, LocatieInformatieContent, ActiveTabHeader},
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
      isPlanModeActive: 'isActive',
    }),
  }
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.LocatieInformatie {
  margin-bottom: 1rem;

  &__Attachments {
    &__Image {
      width: 49.5%;
      position: relative;

      &__Delete, &__Inline {
        z-index: 999;
        position: absolute;
        right: 0;
        top: 7px;
        width: 20px;
        height: 20px;

        .svg-class {
          transform: scale(1.5);

          path {
            fill: #ffffff;
          }
        }

        &:hover {
          cursor: pointer;

          .svg-class path {
            fill: #081736;
          }
        }
      }

      &__Inline {
        right: 7px;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }

  &__Content {
    color: #7F8FA4;
    //font-size: .9rem;

    strong {
      color: map_get($theme-colors, 'primary')
    }

    p {
      margin-bottom: .5rem
    }
  }
}
</style>
