<template>
  <NavBar class="AdminNavBar">
    
    <router-link 
      class="d-inline-flex align-center mr-4" 
      :to="{ name: 'UsersDashboard' }">
      <SvgIcon icon="users-cog-regular" :hasFill="true" />
    </router-link>
    
    <router-link 
      v-if="isStored" 
      class="d-inline-flex align-center mr-4" 
      :to="{ name: 'Upload', params: { municipality: municipalitySlug } }">
      <SvgIcon icon="file-upload-regular" :hasFill="true" />
    </router-link>
    <span 
      v-else
      class="d-inline-flex align-center mr-4" >
      <SvgIcon icon="file-upload-regular" :hasFill="true" />
    </span>

    <router-link 
      v-if="isStored"
      class="d-inline-flex align-center mr-4" 
      :to="{ name: 'Viewer', params: { municipality: municipalitySlug } }">
      <SvgIcon icon="map-regular" :hasFill="true"  />
    </router-link>
    <span 
      v-else
      class="d-inline-flex align-center mr-4" >
      <SvgIcon icon="map-regular" :hasFill="true" />
    </span>
    
  </NavBar>
</template>

<script>
import NavBar from '@/components/common/NavBar'
import SvgIcon from '@/components/common/SvgIcon'

import { mapGetters } from 'vuex'

import { codeToSlug } from '@/services/municipalities'

export default {
  name: 'AdminNavBar',
  components: {
    NavBar, SvgIcon
  },
  computed: {
    ...mapGetters('access', 
      [ 'getActiveMunicipality']
    ),
    ...mapGetters('config', [
      'isStoredByCode'
    ]),
    municipalitySlug() {
      return codeToSlug({ code: this.getActiveMunicipality }) 
    },
    isStored() {
      return this.isStoredByCode({ code: this.getActiveMunicipality })
    }
  }
}
</script>
<style>
.AdminNavBar span:not(.NavBar__Profile):not(.NavBar__Logout) .SvgIcon {
  cursor: not-allowed;
}
</style>