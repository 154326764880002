<template>
  <div>
    <v-tour :name="tourName" :options="options" :steps="steps" :callbacks="callbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
            :class="{'v-step--wide': tour.steps[tour.currentStep].customOptions.wide}"
          >
            <div class="v-step__header" slot="header">
              <div
                class="v-step__header__close"
                @click="tour.skip"
              >
                x
              </div>
              <div v-if="tour.steps[tour.currentStep].header" class="content" v-html="tour.steps[tour.currentStep].header.title" />
            </div>

            <div slot="actions">
              <template v-if="tour.steps[tour.currentStep].customOptions.controls">
                <b-button v-if="! tour.isFirst" class="mr-1" size="sm" variant="outline-logo" @click="tour.previousStep">Vorige</b-button>
                <b-button v-if="! tour.isLast" class="mr-1" size="sm" variant="logo" @click="tour.nextStep">Volgende</b-button>
                <b-button v-else class="mr-1" size="sm" variant="logo" @click="stopTour(tour.stop)">Sluiten</b-button>
              </template>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {hasSeenTour} from "@/valueholders/constants";

export default {
  props: {
    tourName: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    begin: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        highlight: true,
        enabledButtons: {
          buttonSkip: false,
          buttonPrevious: true,
          buttonNext: true,
          buttonStop: true
        },
      })
    },
  },
  data() {
    return {
      callbacks: {
        onSkip: this.handleOnSkip,
      }
    }
  },
  computed: {
    ...mapGetters('app', [
      'isAppReady',
    ]),
  },
  watch: {
    begin(next) {
      if (next) {
        this.$nextTick(() => this.$tours[this.tourName].start())
      }
    }
  },
  methods: {
    ...mapActions('tours', ['hideTour']),
    handleOnSkip() {
      this.hideTour()
      // this.hideTour({ tourName: this.tourName, version: this.version })
    },
    // since onFinish callback doesn't work properly, this workaround
    stopTour(stopFn) {
      stopFn()
      this.hideTour()
      // this.hideTour({ tourName: this.tourName, version: this.version })
    },
    hideTour() {
      localStorage.setItem(this.tourName, hasSeenTour)
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/sass/config";

$header-background-color: map_get($theme-colors, 'logo');
$content-background-color: lighten(map_get($theme-colors, 'logo'), 40%);

.modal-release-notes {
  .modal {
    &-header {
      background-color: $header-background-color;
      color: white;

      .close {
        color: white;
        opacity: 1;
        text-shadow: none;
      }
    }

    &-content {
      background-color: $content-background-color;
      color: map_get($theme-colors, 'primary');
    }
  }
}

.v-tour {
  &__target--highlighted {
    pointer-events: none;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4) !important;
  }
}

.v-step {
  text-align: left !important;
  background-color: $content-background-color !important;
  color: map_get($theme-colors, 'primary') !important;

  &--wide {
    max-width: 600px !important;
  }

  &__header {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid map_get($theme-colors, 'primary') !important;
    background-color: $header-background-color !important;
    color: white !important;
    font-size: 1.25rem;
    font-weight: 600;
    padding: .5rem 1rem !important;

    &__close {
      cursor: pointer;
      padding: .5rem .75rem .75rem;
      margin: -.5rem;
      float: right;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1;
    }
  }

  &__arrow {
    z-index: 0;

    &--dark::before {
      background-color: $content-background-color !important;
    }
  }

  .content-icon {
    width: 15px;
  }
}

.v-step[data-popper-placement^=right]>.v-step__arrow {
  left: 0 !important;
}
.v-step[data-popper-placement^=left]>.v-step__arrow {
  right: -10px !important;
}
</style>