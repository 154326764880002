<template>
  <SideBar class="AdminSideBar">
    <Tabs :tabs="tabs" :active="activeTab" @selected="handleTabClick" />
    <template v-if="activeTab === 'search'">
      <Fuse 
        class="p-4" 
        :list="list" 
        :options="fuseOptions"
        placeholder="Gemeentenaam, code of provincie" 
        @resultsChanged="handleResultsChanged" />

      <ul v-if="results" class="SearchResults py-3">
        <li 
          class="SearchResult pr-3 pl-5 py-1" 
          v-for="municipality in results" 
          :key="municipality.code"
          :class="{
            'SearchResult__Stored': municipality.state !== 'new',
            'SearchResult__Active': municipality.selected,
            'SearchResult__Live': municipality.state === 'live',
            'SearchResult__Upload': municipality.state === 'upload-only',
            'SearchResult__Disabled': municipality.state === 'disabled',
          }"
          @click="handleSelectMunicipality({ municipality })">
          {{ municipality.label }}
          <span class="SearchResult__Icon"></span>
        </li>
      </ul>
    </template>
    <div class="p-4" v-if="activeTab === 'info'">
      <p>
        Instructies over het gebruik van het dashboard ... 
      </p>
    </div>
  </SideBar>
</template>

<script>

// TODO: Turn search result list item in component?

import SideBar from '@/components/common/SideBar'
import Tabs from '@/components/common/Tabs'
import Fuse from '@/components/common/Fuse'

import { allMunicipalities, provincieLabelByProvincieCode } from '@/services/municipalities'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AdminSideBar',
  components: {
    SideBar, Tabs, Fuse
  },
  data() {
    return {
      activeTab: 'search',
      tabs: [{
        label: 'Gemeenten',
        id: 'search'
      }, {
        label: 'Instructies',
        id: 'info'
      }],
      /**
       * Fuse.js options
       */
      fuseOptions: {
        threshold: 0.2,
        keys: ['label', 'code', 'provincielabel'],
        sortFn: (a,b) => {
          /**
           * Fuse only provides score & original position info. We use the position and the number of stored 
           * configs to determine whether to use priority first, or position first.
           */
          return (a.idx < this.noOfStoredConfigs ? (
            // This line is the default sortFn
            a.score === b.score ? (a.idx < b.idx ? -1 : 1) : a.score < b.score ? -1 : 1 
          ) : (a.idx < b.idx ? -1 : 1))
        }
      },
      noOfStoredConfigs: 0,
      /**
       * Generated by Fuse
       */
      results: []
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    ...mapGetters('config', [
      'hasConfig', 'getConfigByCode'
    ]),
    /**
     * Get the complete list of municipalities and enrich the basic meta data with config details
     */
    list() {
      return allMunicipalities().map(municipality => {
        let config = this.getConfigByCode({ code: municipality.code })

        // Pass info for a state indicator
        municipality.state = 'new'
        municipality.prio = 0
        if (config && config.stored) {
          if (config.prognose.enabled) {
            municipality.state = 'live'
            municipality.prio = 10
          }
          else if (config.upload) {
            municipality.state = 'upload-only'
            municipality.prio = 5
          } else {
            municipality.state = 'disabled'
            municipality.prio = 1
          }
        }

        // Add provincie
        municipality.provincielabel = provincieLabelByProvincieCode({
          code: municipality.provincie
        })

        // Highlight the active municipality
        municipality.selected = municipality.code === this.getActiveMunicipality

        return municipality
      }).sort((a,b) => (a.prio === b.prio ? (a.slug > b.slug ? 1 : -1) : (a.prio < b.prio ? 1 : -1)))
    },
  },
  watch: {
    list() {
      this.noOfStoredConfigs = this.list.filter(config => config.prio > 0).length
    }
  },
  methods: {
    ...mapMutations('access', [
      'setActiveMunicipality'
    ]),
    handleTabClick({ id }) {
      this.activeTab = id
    },
    handleResultsChanged({ results }) {
      this.results = results.map(result => result.item)
    },
    /**
     * Navigate to the selected municipality
     */
    handleSelectMunicipality({ municipality }) {
      console.log(municipality)

      this.$router.push({
        name: 'Dashboard',
        params: {
          municipality: municipality.slug
        }
      })
      // this.setActiveMunicipality({ code: municipality.code })
    }
  }
}
</script>

<style lang="scss">
.AdminSideBar {
  .SideBar__main {
    padding: 0 !important;
    flex-shrink: 1;
    max-height: calc(100% - 63px);
    overflow-y: visible;
  }

  .SearchResults {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    border-top: 1px solid darken(#E8EAF1, 10%);

    /**
     * Nifty trick: https://stackoverflow.com/a/33859457/1533140
     */
    // .SearchResult.SearchResult__Stored + .SearchResult:not(.SearchResult__Stored) {
    //   margin-top: 1.5rem;
    // }
    .SearchResult {
      position: relative;
      cursor: pointer;

      &__Stored {
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 1.5rem;
          height: 14px;
          width: 14px;
          background: red;
          border-radius: 50%;
          opacity: 0.5;
        }
      }
      &__Live:after {
        background: green;
      }
      &__Upload:after {
        background: orange;
      }

      &__Active, &:hover  {
        background: darken(#E8EAF1, 10%);
        color: darken(#E8EAF1, 40%);

        &:after {
          opacity: 0.8;
        }
      }

    }
  }
}
</style>