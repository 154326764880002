/**
 * Export format to support Node.js scripts
 */
module.exports = {
  cpos: [
    {uuid: '4ba20825-ee3d-4b57-a16c-3d1bdc01970b', type: 'cpo', name: "Vattenfall", countries: ["nl"]},
    {uuid: 'a062dc4a-fbb6-4036-8927-792be60ae09a', type: 'cpo', name: "Engie", countries: ["nl"]},
    {uuid: '33967046-5e84-4db4-aa2a-27971b354ceb', type: 'cpo', name: "OPCHARGE", countries: ["nl"]},
    {uuid: '87feb792-a3e7-4182-ac58-ef532527e07d', type: 'cpo', name: "PARKnCHARGE", countries: ["nl"]},
    {uuid: 'e4a9e585-7249-45e8-be1d-85a0da47a903', type: 'cpo', name: "Unica", countries: ["nl"]},
    {uuid: '0aff4995-66af-4f44-a25e-878b9e009b3b', type: 'cpo', name: "Baas BV - EVReady", countries: ["nl"]},
    {uuid: '828167a6-02ef-4919-8df2-52118662b6ee', type: 'cpo', name: "Awesems", countries: ["nl"]},
    {uuid: '46dd3b7c-de0c-47fd-9312-c629027f045c', type: 'cpo', name: "Joulz", countries: ["nl"]},
    {uuid: 'd3cad164-c1c1-46db-8838-ff2905167623', type: 'cpo', name: "Allego", countries: ["nl", "be"]},
    {uuid: 'e6a13c70-975c-4416-a143-6bcc269afd34', type: 'cpo', name: "Total", countries: ["nl", "be"]},
    {uuid: 'ae33b0a0-1d6d-44e9-88b3-cc398d5b93e2', type: 'cpo', name: "Bluecorner", countries: ["be"]},
  ],
  getCpoById: (cpos, uuid) => {
    const cpo = cpos.find(cpo => cpo.uuid === uuid)

    if (typeof cpo === 'undefined') {
      return ''
    }

    return cpo
  },
  CPO_TYPE: 'cpo',
}
