<script>
import { mapMutations } from 'vuex'
export default {
  created() {
    this.setPageTitle({ title: 'Niet gevonden (404)' })
  },
  methods: {
    ...mapMutations(['setPageTitle'])
  },
  render(h) {
    return h('div', 'Helaas, deze pagina bestaat niet (meer).')
  }
}
</script>
