<template>
  <div class="TheComments" v-if="!isLoading">
    <div class="TheComments__container TC-Container">
      <!-- TODO:: fix font-size / make it look pretty -->
      <form v-if="isAllowedToComment" class="TC-Container__input" @submit.stop.prevent="handleComment">
        <b-alert class="w-100" :show="error !== false" variant="danger">{{ error }}</b-alert>
        <b-form-textarea
          id="commentTextArea"
          size="sm"
          v-model="newComment"
          placeholder="Plaats een opmerking"
          :disabled="disabled || editingId !== null"
          rows="1"
          max-rows="6"
        ></b-form-textarea>
        <div class="w-100 d-flex justify-content-start">
          <ValidationVoter
            v-if="isAllowedToValidate"
            :validation-choice="validationChoice"
            @choice="setValidationChoice"
          />

          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="pull-right"
            :disabled="disabled || editingId !== null"
          >
            Plaats opmerking
          </b-button>
        </div>
      </form>
      <header class="TC-Container__header d-flex justify-content-between align-items-center">
        <ValidationCounter />

        <div class="pull-right">
          <span class="mr-1">{{ commentCounter }}</span>
          <span>{{ commentCounter === 1 ? 'Opmerking' : 'Opmerkingen' }}</span>
        </div>
      </header>
      <section class="TC-Container__body">
        <ul>
          <li
            class="TC-Comment"
            v-for="{ id : refId, name, canEdit, canRemove, isValidator, date, body, lines, validation, edited_at, edited_by } in comments"
            :key="refId"
          >
            <div class="TC-Comment__meta d-flex justify-content-between">
              <div class="mr-1">
                <strong>{{ name }}</strong> <br/>

                <div v-if="validation">
                  <template v-if="isValidator">
                    <div
                      class="TC-Comment__validation TC-Comment__validation-approved d-flex justify-content-start mt-1"
                      v-if="validation === APPROVED"
                    >
                      <b-icon-check class="TC-Comment__validation__icon"/> <strong>{{ validation }}</strong>
                    </div>
                    <div
                      class="TC-Comment__validation TC-Comment__validation-rejected d-flex justify-content-start mt-1"
                      v-if="validation === REJECTED"
                    >
                      <b-icon-x class="TC-Comment__validation__icon" /> <strong>{{ validation }}</strong>
                    </div>
                  </template>
                  <div v-else>
                    <b-tooltip :target="'noValidatorAnymore' + name" triggers="hover">
                      deze gebruiker is geen validator meer, daarom telt zijn vote niet mee
                    </b-tooltip>
                    <span
                      :id="'noValidatorAnymore' + name"
                      class="TC-Comment__subline"
                    >
                      <strong>{{ validation }}</strong>
                   </span>
                  </div>
                </div>
              </div>
              <span class="TC-Comment__date">{{ date }}</span>
            </div>

            <template v-if="editingId === refId" class="TC-Comment__body">
              <ValidationVoter
                v-if="validation"
                class="mb-2"
                :validation-choice="validationChoice"
                @choice="setValidationChoice"
              />

              <p>
                <b-form-textarea
                  id="commentTextArea"
                  size="sm"
                  v-model="editComment"
                  :disabled="disabled"
                  rows="1"
                  max-rows="6"></b-form-textarea>
              </p>
            </template>
            <p v-else class="TC-Comment__body">
              <span v-for="(line, index) in lines" :key="index">
                <span>{{ line }}</span>
                <br/>
              </span>

              <b-tooltip v-if="edited_at" target="editedAt" triggers="hover">
                {{ edited_at }} <br /> by {{ edited_by }}
              </b-tooltip>
              <span
                id="editedAt"
                class="TC-Comment__body__edited"
                v-if="edited_at"
              >
               (edited)
              </span>
            </p>

            <div v-if="editingId === refId" class="TC-Comment__edit d-flex justify-content-end mt-3">
              <b-button size="sm mr-2" variant="outline-danger" :disabled="disabled" @click="handleCancelEditing">
                Annuleren
              </b-button>
              <b-button size="sm" variant="dark" :disabled="disabled" @click="handleSaveEdit">
                Opslaan
              </b-button>
            </div>
            <div
              v-else-if="canEdit"
              class="TC-Comment__edit d-flex justify-content-end"
            >
              <span
                v-if="canRemove"
                class="d-flex align-items-center u-clickable u-underline mr-2"
                :class="{'u-disabled' : disabled}"
                @click="handleDelete({ refId })"
              >
                <b-icon-trash class="mr-1" />
                <strong>Verwijderen</strong>
              </span>
              <span
                class="d-flex align-items-center u-clickable u-underline"
                :class="{'u-disabled' : disabled}"
                @click="handleStartEditing({ refId, body, validation })"
              >
                <b-icon-pencil-square class="mr-1" />
                <strong>Bewerken</strong>
              </span>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
  <p v-else class="text-muted">
    Loading...
  </p>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {BIconPencilSquare, BIconTrash, BIconCheck, BIconX} from 'bootstrap-vue'

import {VALIDATION_VOTE} from "@/valueholders/enums";
import ValidationCounter from "@/components/map/sidebar/ValidationCounter";
import ValidationVoter from "@/components/map/sidebar/ValidationVoter";

import {multilineUnicodeString} from "@/helpers/string";

import userMixin from "@/mixins/common/userMixin";
import privilegesMixin from "@/mixins/common/privilegesMixin";

export default {
  name: "TheComments",
  mixins: [privilegesMixin, userMixin],
  components: {ValidationVoter, ValidationCounter, BIconPencilSquare, BIconCheck, BIconTrash, BIconX},
  props: {
    chargingpoint: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newComment: null,
      validationChoice: null,

      disabled: false,
      error: false,
      isLoading: true,

      editingId: null,
      editComment: '',
    };
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getCommentsBySelectedChargingpoint',
      'getApprovedCountBySelectedChargingpoint',
      'getRejectedCountBySelectedChargingpoint',
    ]),
    ...mapGetters('users', [
      'getUsersByCode'
    ]),
    commentCounter() {
      return this.comments.length || 0
    },
    comments() {
      return this.getCommentsBySelectedChargingpoint.map((comment) => {
        const isValidator = this.chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === comment.data.UserId) !== -1

        return {
          id: comment.ref['@ref'].id,
          name: comment.data.UserName,
          userId: comment.data.UserId,
          date: comment.data.Time ? this.toLocalDate({isoString: comment.data.Time}) : 'Datum onbekend',
          body: comment.data.Message,
          lines: (comment.data.Message || '').match(/[^\r\n|\r|\n]+/g),
          validation: comment.data.validation || false,
          deleted_at: comment.data.deleted_at || false,
          edited_at: comment.data.edited_at ? this.toLocalDate({isoString: comment.data.edited_at}) : false,
          edited_by: comment.data.editor ? comment.data.editor.name : null,
          canEdit: (comment.data.UserId === this.currentUserId) || this.superuser,
          canRemove: (comment.data.UserId === this.currentUserId && ! comment.data.validation) || this.superuser,
          isValidator: isValidator,
        }
      }).filter(comment => comment.deleted_at === false).reverse()
    },
    validations() {
      return this.comments.filter(comment => comment.validation !== false)
    },
    isAllowedToComment() {
      // this.$auth.user TODO:: add permission check
      return true
    },
    isAllowedToValidate() {
      /* has validators assigned? */
      if (! this.chargingpoint.data.properties.validators) {
        return false
      }

      /* is user eligible to vote at all */
      if (this.chargingpoint.data.properties.validators.findIndex(validator => validator.user_id === this.currentUserId) === -1) {
        return false
      }

      /* user hasn't already voted */
      if (this.validations.findIndex(validation => validation.userId === this.currentUserId) !== -1) {
        return false
      }

      return true
    },
  },
  created() {
    this.APPROVED = VALIDATION_VOTE.APPROVED
    this.REJECTED = VALIDATION_VOTE.REJECTED

    this.fetchCommentsData()
  },
  watch: {
    chargingpoint() {
      this.fetchCommentsData()
    }
  },
  methods: {
    ...mapActions('planmode', ['addOrUpdateComment', 'addOrUpdateChargingPoint', 'deleteComment', 'fetchCommentsByChargingpointUuid',]),
    async fetchCommentsData() {
      this.isLoading = true

      const token = await this.$auth.getTokenSilently();
      this.fetchCommentsByChargingpointUuid({
        token: token,
        code: this.getActiveMunicipality,
        chargingpointUuid: this.chargingpoint.data.uuid
      })
        .catch(() => this.error = 'De opmerkingen konden niet worden geladen')
        .finally(() => this.isLoading = false)
    },
    toLocalDate({isoString}) {
      const format = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'}

      let [y, m, d, hh, mm, ss, ms] = isoString.match(/\d+/g);
      let date = new Date(Date.UTC(y, m - 1, d, hh, mm, ss, ms));

      return date.toLocaleString([], format);
    },
    async saveComment(data) {
      const token = await this.$auth.getTokenSilently();

      const api = await fetch('/api/commentsave', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });

      return await api.json()
    },
    async fetchComments() {
      const token = await this.$auth.getTokenSilently();

      const api = await fetch('/api/commentsload', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ chargingpointUuid: this.chargingpoint.data.uuid, code: this.getActiveMunicipality })
      });

      return await api.json()
    },
    async fetchDeleteComment({refId}) {
      const token = await this.$auth.getTokenSilently();

      const api = await fetch('/api/commentdelete', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ code: this.getActiveMunicipality, refId })
      });

      return await api.json()
    },
    handleComment: async function () {
      if (this.newComment === null || this.newComment.trim() === '') {
        this.error = 'Opmerking mag niet leeg zijn'
        return
      }

      this.disabled = true
      this.error = false

      if (! multilineUnicodeString(this.newComment)) {
        this.error = 'Uw invoer bevat karakters die uit veiligheidsoverweging worden geweigert.'
        this.disabled = false
        return
      }

      const data = this.composeCommentData(this.newComment)

      this.saveComment(data)
        .then(response => {
          this.updateCommentsAndChargingpointFromResponse(response)

          this.validationChoice = null
          this.newComment = ''
          this.disabled = false
        })
        .catch(() => {
          this.error = 'De opmerking kon niet worden opgeslagen.'
          this.disabled = false
        })
    },
    updateCommentsAndChargingpointFromResponse(response) {
      if (response.error_description ) {
        throw response.error_description;
      }

      if (response.comment) {
        this.addOrUpdateComment({comment: response.comment})
      }

      if (response.comments) {
        response.comments.forEach(comment => {
          if (! comment) {
            return
          }

          this.addOrUpdateComment({comment: comment})
        })
      }

      if (response.chargingpoint) {
        this.addOrUpdateChargingPoint({chargingpoint: response.chargingpoint})
      }
    },
    composeCommentData(message, refId = null) {
      return {
        code: this.getActiveMunicipality,
        refId: refId,
        chargingpointUuid: this.chargingpoint.data.uuid,
        userId: this.currentUserId,
        userName: this.currentUserName,
        message: message,
        validation: this.validationChoice
      }
    },
    handleStartEditing({refId, body, validation}) {
      this.handleCancelEditing()
      this.validationChoice = validation ? validation : null
      this.editComment = body
      this.editingId = refId
    },
    handleCancelEditing() {
      this.validationChoice = null
      this.editingId = null
      this.editComment = ''
    },
    handleSaveEdit: async function () {
      if (this.editComment === null || this.editComment.trim() === '') {
        this.error = 'Opmerking mag niet leeg zijn'
        return
      }

      this.disabled = true
      this.error = false

      if (! multilineUnicodeString(this.editComment)) {
        this.error = 'Uw invoer bevat karakters die uit veiligheidsoverweging worden geweigert.'
        this.disabled = false
        return
      }

      const data = this.composeCommentData(this.editComment, this.editingId)

      this.saveComment(data)
        .then(response => {
          this.updateCommentsAndChargingpointFromResponse(response)

          this.handleCancelEditing()
          this.disabled = false
        })
        .catch(() => {
          this.error = 'De opmerking kon niet worden opgeslagen.'
          this.handleCancelEditing()
          this.disabled = false
        })
    },
    // TODO:: add isLoading state for deleting
    handleDelete: async function ({refId}) {
      this.disabled = true
      this.error = false

      this.$bvModal.msgBoxConfirm('Bevestig het verwijderen van dit comment.', {
        okVariant: 'danger',
        okTitle: 'Bevestigen',
        cancelTitle: 'Annuleren'
      })
        .then(async (decision) => {
          if (! decision) {
            this.disabled = false
            return
          }

          this.fetchDeleteComment({refId})
            .then(response => {
              this.deleteComment({comment: response.comment})
              this.disabled = false
            })
            .catch(() => {
              this.error = 'De opmerking kon niet worden opgeslagen.'
              this.disabled = false
            })
        })
    },
    setValidationChoice(choice) {
      if (this.validationChoice === choice) {
        this.validationChoice = null
        return
      }

      this.validationChoice = choice
    }
  },
};
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.u-clickable {
  cursor: pointer;
}

.pull-right {
  margin-left: auto
}

.no-outlines {
  box-shadow: none !important;
}

//GENERAL COMPONENT LAYOUT
$padding-sm: 6px;

.TheComments {
  position: relative;
  max-height: 100%;
}

//NAMESPACED COMMENT BLOCK LAYOUT
.TC-Container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  max-width: 33vw;
  height: 100%;

  &__body {
    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
    }
  }

  &__header,
  &__input {
    padding: $padding-sm;
    border-bottom: 1px solid $border-color;
  }

  &__header {
    font-weight: 500;
    font-size: .9rem
  }

  &__input {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    justify-items: right;
    gap: 10px;
  }

  // standard bootstrap colors
  &__validation {
    &-approved {
      &.checked {
        color: #155724 !important;
        background: #d4edda !important;
        border-color: #c3e6cb !important;
      }

      &:hover {
        color: #fff !important;
        background-color: #28a745 !important;
        border-color: #28a745 !important;
      }
    }

    &-rejected {
      &.checked {
        color: #721c24 !important;
        background: #f8d7da !important;
        border-color: #f5c6cb !important;
      }

      &:hover {
        color: #fff !important;
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
      }
    }
  }
}

//NAMESPACED COMMENT LAYOUT
.TC-Comment {
  padding: $padding-sm; // TODO:: double check, maybe doesn't need the padding left + right, so with a long name, also the date fits
  border-bottom: 1px solid $border-color;

  p {
    margin-bottom: 0;
  }

  &:last-child {
    border: none;
  }

  &__meta {
    padding-bottom: $padding-sm;
  }

  &__date,
  &__subline {
    color: #bbbbbb;
    font-size: 0.82rem;
  }

  &__edit {
    font-size: 0.82rem;
  }

  &__body {
    &__edited {
      font-size: 0.9rem;
      color: #bbbbbb;
    }
  }

  &__validation {
    &-approved {
      color: #5d8c30 !important;
    }
    &-rejected {
      color: map_get($theme-colors, 'danger');
    }

    &__icon {
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-left: -4px;
    }
  }
}
</style>
