<template>
  <div class="ActiveTab MonitoringTab">
    <ActiveTabHeader :chargingpoint="chargingpoint">
      <h4>Monitoring</h4>

      <b-form-select v-if="connectionPoints.length > 0" class="mt-1" v-model="selectedPointToShow" :options="pointsForSelection"></b-form-select>
    </ActiveTabHeader>

    <div class="ActiveTab__Content">
      <p v-if="loading" class="text-muted">
        Loading...
      </p>
      <template v-else>
        <template v-if="isRealized">
          <Feedback v-if="fake" class="mb-3" :dismissible="false" :feedback="{ variant: 'warning' }">
            <strong>Dit is demo data</strong> <br />
            <span>Bij interesse neem contact met ons op via <MailtoSupport /></span>
          </Feedback>

          <div v-if="connectionPoints.length > 0" class="LocatieInformatie__Content">
            <ChartOccupancyRate :data="data" title="Bezettingsgraad" />
            <ChartChargedVolume :data="data" :options="options" title="Geladen kWh" />
            <ChartUniqueUsers :data="data" title="Unieke gebruikers" />
            <ChartSessionCount :data="data" title="Aantal sessies" />
          </div>
          <p v-else>
            Geen monitoring data
          </p>
        </template>
        <p v-else>
          Monitoring is alleen beschikbaar voor gerealiseerde laadpalen.
        </p>

      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Feedback from '@/components/form/Feedback'
import ActiveTabHeader from "@/components/map/sidebar/ActiveTabHeader";
import ChartOccupancyRate from "@/components/map/sidebar/Charts/ChartOccupancyRate";
import ChartChargedVolume from "@/components/map/sidebar/Charts/ChartChargedVolume";
import ChartSessionCount from "@/components/map/sidebar/Charts/ChartSessionCount";
import ChartUniqueUsers from "@/components/map/sidebar/Charts/ChartUniqueUsers";
import {vtfEvseIdToLocationName} from "@/helpers/ocpi";
import fakeMonitoringResponse from "@/data/fakeMonitoringResponse.json";
import MailtoSupport from "@/components/common/MailtoSupport";
import {CHARGINGPOINT_STATUS} from "@/valueholders/enums";

const BUSINESS_RULE_VOLUME_RTDM = 250 // TODO:: this comes from the global settings
const LAADPAAL_SELECTED = "laadpaal_is_selected"

export default {
  components: {
    ChartOccupancyRate,
    ChartChargedVolume,
    ChartSessionCount,
    ChartUniqueUsers,
    ActiveTabHeader,
    Feedback,
    MailtoSupport,
  },
  data() {
    return {
      fake: false,
      data: {},
      selectedPointToShow: LAADPAAL_SELECTED,
      loading: false
    }
  },
  computed: {
    ...mapGetters('config', [
      'isMonitoringEnabled',
    ]),
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
    ...mapGetters('monitoring', {
      connectionPoints: 'getSelectedConnectionPoints',
      locationData: 'getSelectedLocationData',
    }),
    ...mapGetters('access',  {
      activeMunicipality: 'getActiveMunicipality',
    }),
    isRealized() {
      return this.chargingpoint.data.properties.status === CHARGINGPOINT_STATUS.REALIZED
    },
    thresholdChargedVolume() {
      if (this.selectedPointToShow === LAADPAAL_SELECTED) {
        return BUSINESS_RULE_VOLUME_RTDM * this.locationData.connectionPoints.length
      }

      return BUSINESS_RULE_VOLUME_RTDM
    },
    options() {
      return {
        limit: this.thresholdChargedVolume,
      }
    },
    pointsForSelection() {
      const inflectedLabel = this.locationData.connectionPoints.length > 1 ? "laadpunten" : "laadpunt"

      const pointsForSelection = [
        { value: LAADPAAL_SELECTED, text: `Laadpaal (${this.locationData.connectionPoints.length} ${inflectedLabel})` },
      ]

      this.locationData.connectionPoints.forEach((entry) => pointsForSelection.push(
        {
          value: entry.evseId,
          text: entry.evseId
        }
      ))

      return pointsForSelection
    }
  },
  watch: {
    selectedPointToShow(selection) {
      this.loadData(selection)
    },
    chargingpoint() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  destroyed() {
    this.deselectLocation()
  },
  methods: {
    ...mapMutations('monitoring', [
      'addConnectionPoints',
      'addLocationData'
    ]),
    ...mapActions('monitoring', [
      'selectLocation',
      'deselectLocation',
    ]),
    init() {
      if (this.isMonitoringEnabled === false) {
        this.initWithFakeData()
        this.fake = true
        return
      }

      this.initWithRealData()
    },
    initWithFakeData() {
      this.selectLocation('DEMO-LAADPUNT')

      // if data is already loaded
      if (this.locationData && this.locationData.connectionPoints.length > 0) {
        this.useAggregatedData()
        return
      }

      this.loading = true

      Promise.resolve(fakeMonitoringResponse)
        .then(this.handleResponse)
        .finally(() => this.loading = false)
    },
    initWithRealData() {
      let location = this.chargingpoint.data.properties.evse

      // we will have one adapter per CPO, since they can define their evseIds different
      if (this.chargingpoint.data.properties.operator === "Vattenfall") {
        location = vtfEvseIdToLocationName(this.chargingpoint.data.properties.evse)
      }

      this.selectLocation(location)

      // if data is already loaded
      if (this.locationData && this.locationData.connectionPoints.length > 0) {
        this.useAggregatedData()
        return
      }

      this.loading = true

      this.fetchConnectionPointsByLocation(location, this.activeMunicipality)
        .then(this.handleResponse)
        .finally(() => this.loading = false)
    },
    async fetchConnectionPointsByLocation(location, code) {
      const token = await this.$auth.getTokenSilently();

      const api = await fetch('/monitoring/api/monitoringDataByLocation', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ location, code })
      });

      return api.json();
    },
    handleResponse(response) {
      if (response.statusCode !== 200) {
        console.error("error fetching connection points:", response.error_description)
        return
      }

      if (response.data.connectionPoints.length === 0) {
        console.info("No connection points were found")
        return
      }

      this.addConnectionPoints({connectionPoints: response.data.connectionPoints})
      this.addLocationData({locationData: response.data.locationData})

      this.useAggregatedData()
    },
    loadData(selection) {
      this.selectedPointToShow = selection

      if (selection === LAADPAAL_SELECTED) {
        this.useAggregatedData()
        return
      }

      const index = this.locationData.connectionPoints.findIndex(connectionPoint => connectionPoint.evseId === selection)

      if (index === -1) {
        this.useAggregatedData()
        return
      }

      this.data = this.locationData.connectionPoints[index].data
    },
    useAggregatedData() {
      this.data = this.locationData?.aggregated
    }
  }
}
</script>

<style lang="scss">
  .MonitoringTab {
    &.ActiveTab {
      overflow-x: hidden;
    }

    &__SelectDropdown {
      position: absolute;
      right: 60px;
      top: 8px;
    }
  }

  .Chart {
    &__Header {
      display: flex;
      font-size: 1.15rem;

      &__Icon {
        width: 25px;
        height: 25px;
        display: flex;
        margin-right: 3px;
      }
    }
  }

  .ggl-tooltip {
    white-space: nowrap;
    padding: 5px;
  }

  // fix flickering tooltip: https://github.com/google/google-visualization-issues/issues/2162
  div.google-visualization-tooltip { pointer-events: none }
  svg > g > g:last-child { pointer-events: none }
  svg > g:last-child > g:last-child { pointer-events: none }
</style>