export default {
  computed: {
    currentEmail() {
      return this.$auth.user.email
    },
    currentUserId() {
      return this.$auth.user.sub
    },
    currentUserName() {
      return this.$auth.user.name
    },
  }
}