<template>
  <MapBoxPopup 
    :coordinates="coordinates" 
    :show="show"
    @close="handleClose">

    <div>
      <div class="d-flex flex-column">
        <div v-if="address">
          {{ address }}
        </div>
        <div>
          {{ statusLabel }} 
        </div>
        <div v-if="cpo">
          CPO: {{ cpo.name }}
        </div>
        <div>
          ID: {{ `${code}-${id}` }}
        </div>
        <div v-if="prio.order">
          Prioriteit: {{ prio.order }} {{ prio.fase === 1 ? '(Fase 1)' : '' }} {{ isBraLiProvincieCode && prio.fase === 2 ? '(Fase 2)' : '' }}
        </div>
        <div v-if="user.name">
          <span class="text-muted">{{ user.name }}</span>
        </div>
        <ValidationCounter class="mt-1" />
        <div class="mt-3" v-if="remark">
          Je kunt de toelichting vinden aan de rechterzijkant onder de locatieinformatie tab
        </div>
      </div>
    </div>

  </MapBoxPopup>
</template>

<script>

import MapBoxPopup from '@/components/common/MapBoxPopup'

import {mapActions, mapGetters} from 'vuex'

import { EventBus } from '@/services/eventbus'
import ValidationCounter from "@/components/map/sidebar/ValidationCounter";

import {CPO_TYPE} from '@/data/cpos'

export default {
  name: 'ChargerDetailsPopup',
  components: {ValidationCounter, MapBoxPopup },
  data() {
    return {
      show: false,

      coordinates: null,

      // Properties (matches ChargerEditorPopup)
      id: null,
      code: null,
      remark: null,
      user: {
        name: null
      },
      stakeholders: [],
      status: null,
      address: null,

      prio: {
        order: null,
        exact: null,
        fase: null
      },

      doNotDeselectChargingpoint: false,
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    ...mapGetters('planmode', [
      'getChargingPointByRefId',
      'getLayers',
      'isChargingpointSelected',
    ]),
    statusLabel() {
      let options = {
        rejected: 'Afgekeurd',
        suggestion: 'Voorgesteld',
        definitive: 'Gevalideerd',
        'in-progress': 'In voorbereiding',
        realized: 'Gerealiseerd',
        'realized-private': 'Gerealiseerd (semi-publiek)',
        alert: 'Aandachtspunt',
        'fastcharger-realized': 'Gerealiseerde snellader',
        'fastcharger-definitive': 'Gevalideerde snellader',
        'fastcharger-suggestion': 'Voorgestelde snellader',
        'fastcharger-rejected': 'Afgekeurde snellader',
      }
      return options[this.status] || 'Onbekend'
    },
    isBraLiProvincieCode() {
      return ['9006', '9007'].includes(this.getActiveMunicipality)
    },
    cpo() {
      return this.stakeholders.find(stakeholder => stakeholder.type === CPO_TYPE)
    },
  },
  watch: {
    /**
     * Hide the popup whenever the municiaplity changes
     */
    getActiveMunicipality() {
      this.show = false
    }
  },
  /**
   * Bind event handlers to MapBox
   */
  created() {
    this.$store.map.on('click', 'chargingpoints', this.handleClickMarkerEvent)

    EventBus.$on('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$on('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.on('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.on('mouseleave', 'chargingpoints', this.hidePointer)
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.$store.map.off('click', 'chargingpoints', this.handleClickMarkerEvent)

    EventBus.$off('select-chargingpoint', this.handleSelectChargingpointEvent)
    EventBus.$off('deselect-chargingpoint', this.handleClose)

    // Cursor
    this.$store.map.off('mouseenter', 'chargingpoints', this.showPointer)
    this.$store.map.off('mouseleave', 'chargingpoints', this.hidePointer)
  },
  methods: {
    ...mapActions('planmode', [
      'selectChargingPoint',
      'deselectChargingPoint',
    ]),
    /**
     * Mouse hover effects
     */
    showPointer() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.$store.map.getCanvas().style.cursor = ''
    },
    /**
     * Load the outside selected point's properties
     */
    handleSelectChargingpointEvent(refId) {
      let chargingpoint = this.getChargingPointByRefId({ refId })

      this.setChargingpointData({ chargingpoint })

      this.show = true
    },
    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      // debugging mapbox features::
      // console.log('event:: ',e.features[0])

      // Get the charging point to get the exact coordinates & user details
      let chargingpoint = this.getChargingPointByRefId({
        refId: e.features[0].properties.refId
      })

      // if chargingpoint layer is not visible don't react to clicks
      const chargingpointLayer = this.getLayers.find(layer => layer.id === `chargingpoints-${chargingpoint.data.properties.status}`)
      if (chargingpointLayer.visible === false) {
        return
      }

      // if there is already a popup opened, set a flag to not deselect the chargingpoint in the store.
      // This is to avoid a flickering of the sidebar, since we need to wait for the nextTick and the current popup is closed

      if (this.isChargingpointSelected && this.show) {
        this.doNotDeselectChargingpoint = true
      }

      this.show = false

      this.$nextTick(() => {
        this.setChargingpointData({ chargingpoint })

        this.show = true
        this.doNotDeselectChargingpoint = false
      })
    },
    setChargingpointData({ chargingpoint }) {
      this.selectChargingPoint({ refId: chargingpoint.ref['@ref'].id });

      this.id = chargingpoint.data.properties.id
      this.code = chargingpoint.data.code
      this.remark = chargingpoint.data.properties.remark || ''
      this.user.name = chargingpoint.data.properties.user
        ? chargingpoint.data.properties.user.name
        : (chargingpoint.data.properties.user_name || 'EVTools')
      this.stakeholders = chargingpoint.data.properties.stakeholders || []
      this.status = chargingpoint.data.properties.status || 'definitive'

      this.address = chargingpoint.data.address ? chargingpoint.data.address.simple_address : ''

      this.coordinates = chargingpoint.data.coordinates

      this.prio = {
        order: chargingpoint.data.prio ? chargingpoint.data.prio.order : null,
        exact: chargingpoint.data.prio ? chargingpoint.data.prio.exact : null,
        fase:  chargingpoint.data.prio ? chargingpoint.data.prio.fase : null
      }
    },
    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false

      // if this flag is set, we don't want to deselect the chargingpoint in the store to avoid a flickr,
      // while switching between chargingpoints on the map
      if (this.doNotDeselectChargingpoint === false) {
        this.deselectChargingPoint();
      }
    }
  }
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>