<template>
  <div style="display:none"></div>
</template>

<script>
import { mapGetters } from 'vuex'

import { 
  decielen
} from '@/data/inkomensdecielen'

export default {
  name: 'RegularPrognoseLayer',
  props: {
    loaded: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('config', [
      'breakpoints', 'layers'
    ]),
    ...mapGetters('scenarios', [
      'model', 'year', 'access', 'forecast'
    ]),
    ...mapGetters('prognose', [
      'getHighlighted', 'labelLayer'
    ]),
    unavailable() {
      return this.layers.hexagons.source === ''
    },
    config() {
      return this.layers.hexagons
    },
    relevantForcast() {
      return ['determinative', 'inhabitants', 'visitors', 'commuters'].includes(this.forecast)
    }
  },
  watch: {
    loaded() {
      if (! this.$store.map.getLayer('hexagons')) {
        this.addLayer()
      }
    },
    /**
     * Scenario updates trigger a repaint
     */
    model() {
      this.updatePrognose()
    },
    year() {
      this.updatePrognose()
    },
    access() {
      this.updatePrognose()
    },
    forecast() {
      if (! this.relevantForcast) {
        if (this.$store.map.getLayer('hexagons')) {
          this.removeLayer()
        }
      } else {
        if (! this.$store.map.getLayer('hexagons')) {
          this.addLayer()
        } else {
          this.updatePrognose()
        }
      }
    },
    /**
     * The highlighted area
     *  TODO: Separate layer for the highlighted hexagon, to avoid repaints
     */
    getHighlighted() {
      this.updatePrognose()
    },
    /**
     * If the config changed we'll need to update the layer.
     *  For a vector layer this requires removing the current layer first.
     */
    config() {
      this.removeLayer()
      this.addLayer()
    }
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  beforeDestroy() {
    if (this.loaded) {
      this.removeLayer()
    }
  },
  methods: {
    addLayer() {

      // Don't go any further if the layer details are missing
      // Or if the layer is not relevant to the selected forecast
      if (this.unavailable || ! this.relevantForcast) {
        return
      }

      this.$store.map.addSource('hexagons', {
        type: 'vector',
        url: this.config.url
      })
      this.$store.map.addLayer({ 
        "id": "hexagons",
        "type": "fill",
        "source": "hexagons",
        "source-layer": this.config.source,
        "paint": {
          "fill-color": this.fillHexagons(),
          // Note: The legend opacity is linked
          "fill-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0.7,
            13,
            0.4
          ],
          "fill-outline-color": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            "hsla(232, 67%, 60%, 0)",
            22,
            "hsla(232, 67%, 60%, 0.9)"
          ]
        }
      }, this.labelLayer)
    },
    removeLayer() {
      if (this.$store.map.getLayer('hexagons')) {
        this.$store.map.removeLayer('hexagons')
        this.$store.map.removeSource('hexagons')
      }
    },
    updatePrognose() {
      if (this.$store.map.getLayer('hexagons')) {
        this.$store.map.setPaintProperty('hexagons', 'fill-color', this.fillHexagons())
      }
    },

    // The paint instruction for the hexagons
    fillHexagons() {
      let value = this.forecastFilterSwitch({ 
        forecast: this.forecast 
      })

      let styleCondition = [
        "case",
      ]

      // The selected tiles
      this.getHighlighted.forEach(fid => {
        styleCondition.push([
          "==",
          [
            "get",
            "fid"
          ],
          fid,
        ])
        styleCondition.push("hsl(23, 100%, 42%)")
      })

      return styleCondition.concat([
        // The layer we want to ignore
        [
          "<",
          value,
          0.05
        ],
        "hsla(0, 0%, 0%, 0)",
        // 0 - 1
        [
          "<=",
          value,
          this.breakpoints[0]
        ],
        "hsl(213, 50%, 80%)",
        // 1+ - 2
        [
          "<=",
          value,
          this.breakpoints[1]
        ],
        "hsl(213, 65%, 55%)",
        // 2+ - 4
        [
          "<=",
          value,
          this.breakpoints[2]
        ],
        "hsl(213, 80%, 35%)",
        // 4+
        [
          ">",
          value,
          this.breakpoints[2]
        ],
        "hsl(213, 95%, 20%)",
        // Default (required by MapBox)
        "hsla(0, 0%, 0%, 0)"
      ])
    },

    /**
     * This method generates the core element of the paint instructionset to 
     * paint the hexagons by the data contained in the hexagon layer.
     * 
     * The output is based on the 3 filters available to the user
     * 
     * The forecast param is 1 filter input. This one is passed in like this 
     * because for one variation we need to combine all other forecast 
     * variations generated by this method.
     */
    forecastFilterSwitch({ forecast }) {

      // three of the filters.
      let year = this.year
      let access = this.access
      let model = this.model

      // Data fields in layer
      let fields = {
        bewonersD1: ['coalesce', [
          "get",
          "BewonersD1"
        ], 0],
        bewonersD2: ['coalesce', [
          "get",
          "BewonersD2"
        ], 0],
        bewonersD3: ['coalesce', [
          "get",
          "BewonersD3"
        ], 0],
        bewonersD4: ['coalesce', [
          "get",
          "BewonersD4"
        ], 0],
        bewonersD5: ['coalesce', [
          "get",
          "BewonersD5"
        ], 0],
        visit: ['coalesce', [
          "get",
          "Bezoekers"
        ], 0],
        commuter: ['coalesce', [
          "get",
          "Forenzen"
        ], 0],
        parking: ['coalesce', [
          "get",
          "Pubparkeer"
        ], 0]
      }

      switch (forecast + '-' + access) {

        // Public - Inhabitants
        case 'inhabitants-public': 
          return [
            "*", 
              ["+", 
                ["*", fields.bewonersD1, decielen[model].D1[year]],
                ["*", fields.bewonersD2, decielen[model].D2[year]],
                ["*", fields.bewonersD3, decielen[model].D3[year]],
                ["*", fields.bewonersD4, decielen[model].D4[year]],
                ["*", fields.bewonersD5, decielen[model].D5[year]],
              ], 
              fields.parking]

        // Private - Inhabitants
        case 'inhabitants-private': 
          return [
            "*", 
              ["+", 
                ["*", fields.bewonersD1, decielen[model].D1[year]],
                ["*", fields.bewonersD2, decielen[model].D2[year]],
                ["*", fields.bewonersD3, decielen[model].D3[year]],
                ["*", fields.bewonersD4, decielen[model].D4[year]],
                ["*", fields.bewonersD5, decielen[model].D5[year]],
              ], 
              ["-", 1, fields.parking]]

        // Public - visitors
        case 'visitors-public': 
          return ["*", fields.visit, fields.parking, decielen[model].D4[year]]

        // Private - visitors
        case 'visitors-private': 
          return ["*", fields.visit, ["-", 1, fields.parking], decielen[model].D4[year]]

        // Public - commuters
        case 'commuters-public': 
          return ["*", fields.commuter, fields.parking, decielen[model].D4[year]]

        // Private - commuters
        case 'commuters-private': 
          return ["*", fields.commuter, ["-", 1, fields.parking], decielen[model].D4[year]]

        // Determinative
        case 'determinative-public': 
        case 'determinative-private':
          return ["max", 
            this.forecastFilterSwitch({ forecast: 'inhabitants' }),
            this.forecastFilterSwitch({ forecast: 'visitors' }),
            this.forecastFilterSwitch({ forecast: 'commuters' })
          ]
      }
      
      // Default
      return 0
    }
  }
}
</script>