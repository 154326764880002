<template>
  <div class="LocatieInformatie__Content">
    <div v-if="!isLoading">
      <b-alert :show="error !== false" variant="danger">{{ error }}</b-alert>

      <div class="LocatieInformatie__Attachments mb-3">
        <strong>Bijlagen</strong>
        <template v-if="isAllowedToUploadAttachments">
          <div class="LocatieInformatie__Attachments d-flex justify-content-between" :class="{ 'is-dragging': isDragging }">
            <LocatieInformatieUploadAttachment
              :attachment="attachments.top"
              @remove="$_chargingpointAttachmentsMixin_handleFileRemove"
              @change="$_chargingpointAttachmentsMixin_handleFileChange"

              @dragenter="isDragging = true"
              @dragover="() => {}"
              @dragleave="isDragging = false"
              @drop="$_chargingpointAttachmentsMixin_handleDrop"
            />

            <LocatieInformatieUploadAttachment
              :attachment="attachments.side"
              @remove="$_chargingpointAttachmentsMixin_handleFileRemove"
              @change="$_chargingpointAttachmentsMixin_handleFileChange"

              @dragenter="isDragging = true"
              @dragover="() => {}"
              @dragleave="isDragging = false"
              @drop="$_chargingpointAttachmentsMixin_handleDrop"
            />
          </div>
        </template>
        <p v-else>
          Alleen gebruikers met de rol Beheerder mogen bijlagen uploaden.
        </p>
      </div>

      <Form @submit="handleSubmitUpdate">
        <template v-if="address">
          <div v-if="canEditAddress">
            <strong>Adres</strong><br/>
            <FormField
              v-model.trim="fields.address.street.value"
              v-bind="fields.address.street" />
            <div class="row">
              <div class="col-6">
                <FormField
                  v-model.trim="fields.address.number.value"
                  v-bind="fields.address.number" />
              </div>
              <div class="col-6">
                <FormField
                  v-model.trim="fields.address.postalcode.value"
                  v-bind="fields.address.postalcode" />
              </div>
            </div>
            <FormField
              v-model.trim="fields.address.city.value"
              v-bind="fields.address.city" />
          </div>

          <template v-else>
            <p>
              <strong>
                Adres <SvgIcon id="ecomovement-hint" icon="info-circle-regular" />
              </strong> <br/>
              {{ address.simple_address }} <br/>
              {{ address.postalcode }} {{ address.city }}
            </p>
            <b-tooltip target="ecomovement-hint" triggers="hover">
              EcoMovement data kan niet worden aangepast.
            </b-tooltip>
          </template>

          <p v-if="address.source">
            <strong>Bron</strong> <br />
            {{ addressSource }}
          </p>

          <p v-if="address.coordinates['lat'] && address.coordinates['lng']">
            <strong>Coordinaten</strong><br/>
            {{ address.coordinates['lat'] }}, <br/>
            {{ address.coordinates['lng'] }}
          </p>
        </template>
        <p v-else>
          <strong>Adres</strong><br/>
          Onbekend
        </p>

        <p v-if="plannerName">
          <strong>Gebruiker</strong><br/>
          {{ plannerName }}
        </p>

        <p v-if="prio && prio.order">
          <strong>Prioriteit</strong><br/>
          {{ prio.order }}
          {{ prio.fase === 1 ? '(Fase 1)' : '' }}
          {{ isBraLiProvincieCode && prio.fase === 2 ? '(Fase 2)' : '' }}
        </p>

        <FormField
          v-model="fields.remark.value"
          v-bind="fields.remark" />
        <FormField
          v-model="fields.status.value"
          v-bind="fields.status"
          :disabled="! isAllowedToEditStatus"
          :options="statusOptions" />
        <FormField
          v-if="isLocatievoorstel"
          v-model="fields.cpo.value"
          v-bind="fields.cpo"
          :options="cpoSelectOptions" />

        <p>
          <strong>Locatiebeheer</strong>
        </p>
        <template v-if="superuser">
          <FormField
            v-model="fields.beheerder.value"
            v-bind="fields.beheerder"
            :options="beheerderOptions" />
          <div class="d-flex align-items-center add-validator">
            <FormField
              v-model="fields.validatie.value"
              v-bind="fields.validatie"
              :options="usersByCodeAsOptions" />
            <b-icon-plus
              variant="dark"
              class="add-validator__icon u-clickable ml-2 mt-1"
              @click="$_chargingpointValidatorsMixin_addValidator"
            />
          </div>
          <div class="the-validators">
            <ul v-if="newValidators.length > 0">
              <li
                v-for="(validator, index) in newValidators"
                :key="validator.user_id"
                class="mb-1 d-flex"
              >
                {{ validator.email }}
                <b-icon-trash
                  variant="dark"
                  class="the-validators__validator__icon u-clickable pull-right"
                  @click="$_chargingpointValidatorsMixin_removeValidator(index)"
              />
              </li>
            </ul>
            <p v-else class="text-muted">
              Geen validatoren geselecteerd
            </p>
          </div>
        </template>
        <template v-else>
          <p>
            <strong>Beheerder</strong><br />
            {{ beheerder ? beheerder.name : 'Geen' }}
          </p>
          <div class="the-validators">
            <p class="mb-0">
              <strong>Validatoren</strong>
            </p>
            <ul v-if="hasValidators">
              <li
                v-for="validator in validators"
                :key="validator.user_id"
              >
                {{ validator.name }}
              </li>
            </ul>
            <span v-else>
              Geen
            </span>
          </div>
        </template>

        <div class="mt-2 d-flex justify-content-end">
          <b-button
            v-if="canRemove"
            class="mr-1"
            size="sm"
            variant="danger"
            @click.prevent="handleChargingpointDelete"
          >
            Verwijder
          </b-button>
          <b-button
            type="submit"
            size="sm"
            variant="primary">
          Opslaan
        </b-button>
        </div>
      </Form>
    </div>
    <p v-else class="text-muted">
      Loading...
    </p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {BIconPlus, BIconTrash} from "bootstrap-vue";

import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'
import SvgIcon from '@/components/common/SvgIcon'
import LocatieInformatieUploadAttachment from '@/components/map/sidebar/LocatieInformatieUploadAttachment'

import {reduceUserInformation} from "@/helpers/user";

import chargingpointDataMixin from "@/mixins/chargingpoint/chargingpointDataMixin";
import chargingpointEditMixin from "@/mixins/chargingpoint/chargingpointEditMixin";
import chargingpointCpoMixin from "@/mixins/chargingpoint/chargingpointCpoMixin";
import chargingpointValidatorsMixin from "@/mixins/chargingpoint/chargingpointValidatorsMixin";
import chargingpointAttachmentsMixin from "@/mixins/chargingpoint/chargingpointAttachmentsMixin";
import userMixin from "@/mixins/common/userMixin";

import { ATTACHMENT_ID } from "@/valueholders/enums";

export default {
  components: {LocatieInformatieUploadAttachment, Form, FormField, SvgIcon, BIconPlus, BIconTrash},
  mixins: [chargingpointDataMixin, chargingpointEditMixin, chargingpointCpoMixin, chargingpointValidatorsMixin, chargingpointAttachmentsMixin, userMixin],
  data() {
    return {
      error: false,
      fields: {
        address: {
          street: {
            label: 'Straat',
            value: null,
          },
          number: {
            label: 'Huisnummer',
            value: null,
          },
          postalcode: {
            label: 'Postcode',
            value: null,
          },
          city: {
            label: 'Stad',
            value: null,
          },
        },
        remark: {
          label: 'Toelichting',
          value: this.remark,
          type: 'textarea'
        },
        status: {
          label: 'Status',
          value: 'suggestion',
          type: 'select'
        },
        beheerder: {
          label: 'Beheerder',
          value: null,
          type: 'select'
        },
        validatie: {
          label: 'Validatie',
          value: null,
          type: 'select'
        },
      },
      isDragging: false,
    }
  },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
      isPlanModeActive: 'isActive',
    }),
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    canRemove() {
      // return this.getEditMode && (isPlanModeAdmin() || (isAnalist() && (this.userName === this.currentUserName) && (this.status.value !== 'definitive')))
      return true // TODO: Add permission check - Role can differ per municipality!
    },
    isAllowedToUploadAttachments() {
      return this.superuser || this.admin
    },
    isAllowedToEditStatus() {
      // superuser always are allowed to change the status
      if (this.superuser) {
        return true
      }

      // if there is no beheerder, everyone may change the status
      if (this.chargingpoint.data.properties.beheerder === undefined) {
        return true
      }

      // is user the beheerder?
      return this.currentUserId === this.chargingpoint.data.properties.beheerder.user_id
    },
    // only NONE ecomovement chargingpoints are enabled for editing the address
    canEditAddress() {
      return ! this.chargingpoint.data.properties.ecomovement_id
    },
    isLoading() {
      return this.isLoadingFauna || this.isLoadingAws
    }
  },
  created: async function(){
    this.ATTACHMENT_ID = ATTACHMENT_ID;

    /* check what users may do/don't */
    this.init()

    /* connect to pusher */
    await this.$_chargingpointEditMixin_connectToPusher()
  },
  destroyed() {
    this.fields.status.class = ''
  },
  watch: {
    // re-init when selected chargingpoint changes
    chargingpoint(next, previous) {
      if (next.data.uuid === previous.data.uuid) {
        return
      }

      this.init()
    },
  },
  methods: {
    init() {
      this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
      this.setInitialValues()
    },

    /* fill form with data */
    setInitialValues() {
      let point = this.chargingpoint

      this.fields.address.street.value = point.data.address?.street || null
      this.fields.address.number.value = point.data.address?.number || null
      this.fields.address.postalcode.value = point.data.address?.postalcode || null
      this.fields.address.city.value = point.data.address?.city || null

      this.fields.status.value = point.data.properties.status || 'suggestion'
      this.fields.cpo.value = this.currentCpo ? this.currentCpo.uuid : ''
      this.fields.beheerder.value = point.data.properties.beheerder?.user_id || null
      this.fields.remark.value = point.data.properties.remark || ''
      this.newValidators = point.data.properties.validators || []
    },

    /**
     * Update the marker
     */
    async handleSubmitUpdate() {
      let point = this.chargingpoint

      await this.$_chargingpointAttachmentsMixin_deleteAttachments()
      await this.$_chargingpointAttachmentsMixin_uploadAttachments()

      // always compute address, and let fauna change detection decide if the address was updated
      const address = this.computeAddress()

      // if there was an cpo already assigned, filter it out and add the (if) selected one
      const stakeholders = this.$_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders()

      const beheerder = this.removeOrUpdateBeheerder()

      try {
        await this.$_chargingpointEditMixin_save({
          code: this.getActiveMunicipality,
          ref: point.ref, // full ref
          id: point.data.properties.id,
          stakeholders: stakeholders,
          status: this.fields.status.value,
          user: {
            name: point.data.properties.user_name || point.data.properties.user.name,
          },
          coordinates: point.data.coordinates,
          remark: this.fields.remark.value,
          geocoder: false,
          validators: this.newValidators,
          beheerder,
          address,
        })
      } catch (error) {
        this.error = error.message
        this.isLoadingFauna = false
      }
    },

    removeOrUpdateBeheerder() {
      let beheerder = this.usersByCode.find(user => user.user_id === this.fields.beheerder.value)
      if (beheerder !== undefined) {
        beheerder = reduceUserInformation(beheerder)
      }

      return beheerder || null
    },

    computeAddress() {
      const address = {}
      Object.entries(this.fields.address).forEach(field => {
        const [key, value] = field
        address[key] = value.value
      })

      return address
    },

    /**
     * Delete the marker from the data set
     */
    handleChargingpointDelete() {
      this.$bvModal.msgBoxConfirm(
        'Bevestig het verwijderen van dit punt.', {
          okVariant: 'danger',
          okTitle: 'Bevestigen',
          cancelTitle: 'Annuleren'
        })
        .then(confirmed => {
          if (confirmed) {

            /* this.delete broadcasts to close the popup/sidepanel */
            this.$_chargingpointEditMixin_delete({
              code: this.getActiveMunicipality,
              ref: this.chargingpoint.ref // full ref
            })
          }
        })
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.hidden {
  display: none !important;
}

.FormField {
  input {
    color: $text-muted;
  }

  label, legend {
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    color: map_get($theme-colors, 'primary')
  }
}

.ActionSidebar {
  .add-validator {
    .custom-select {
      max-width: 196px;
    }
  }
}

.add-validator {
  width: calc(100% - 26px) !important;

  &__icon {
    font-size: 25px;
  }
}

.the-validators {
  ul {
    list-style: none;
    padding: 0;
  }
}

.ratio {
  overflow: hidden;
}

.is-dragging {
  .LocatieInformatie__Attachments__Image {
    animation-duration: .3s;
    animation-name: wobbel;
    animation-iteration-count: infinite;
    transition-timing-function: ease-out;
  }
}

@keyframes wobbel {
  0% { transform: rotate(0); }

  25% { transform: rotate(-2deg); }

  50% { transform: rotate(0); }

  75% { transform: rotate(2deg); }

  100% { transform: rotate(0); }
}
</style>