<template>
  <div class="MunicipalityPanel mt-3 mb-4 flex-grow-1">
    <PanelHeader :enableSave="difference" :busy="busy" @save="handleSaveEvent" />
    
    <div clas="mt-3 mx-4">
      <Tabs :tabs="tabs" :active="activeTab" @selected="handleTabClick" />
    </div>

    <Feedback v-if="!isStored" class="mx-4 mt-4" :dismissible="false" :feedback="{ 
      message: 'Let op! Deze configuratie is nog nooit opgeslagen.', 
      variant: 'warning' 
    }" />
    <Feedback v-if="activeTab === 'users'" class="mx-4 mt-4" :dismissible="false" :feedback="{ variant: 'warning' }">
      <strong class="mr-3">Wijzigingen worden meteen doorgevoerd.</strong> 
      <span>Door het maken van wijzigingen kan de sessie van de gebruiker onderbroken worden.</span>
    </Feedback>

    <section v-if="activeTab === 'layers'" class="MunicipalityPanel__Section d-flex flex-column p-4">
      <LayerConfigPanel :value="layers" @input="handleLayersChange" class="mt-4 pb-5" />
    </section>

    <section v-if="activeTab === 'users'" class="MunicipalityPanel__Section d-flex flex-column p-4">
      <UserPanel :code="getActiveMunicipality" class="mt-4 pb-5" />
    </section>
    
    <section v-if="activeTab === 'prognose'" class="MunicipalityPanel__Section d-flex flex-column p-4">
      <FeaturePanel :values="features" :hasLayers="!noActiveLayers" @input="handleFeaturesChange" class="mt-4 pb-5" />
      <PrognosePanel :values="prognose" @input="handlePrognoseChange" class="mt-4 pb-5" />
    </section>

    <section v-if="activeTab === 'chargingpoints'" class="MunicipalityPanel__Section d-flex flex-column p-4">
      <AssignValidatorsPanel />
    </section>

  </div>
</template>

<script>

import PanelHeader from '@/components/common/PanelHeader'
import LayerConfigPanel from '@/components/admin/municipalities/LayerConfigPanel'
import FeaturePanel from '@/components/admin/municipalities/FeaturePanel'
import PrognosePanel from '@/components/admin/municipalities/PrognosePanel'
import UserPanel from '@/components/admin/municipalities/UserPanel'
import AssignValidatorsPanel from '@/components/admin/chargingpoints/AssignValidatorsPanel'

import Tabs from '@/components/common/Tabs'
import Feedback from '@/components/form/Feedback'

import { labelByCode } from '@/services/municipalities'
import { layers as layerDetails } from '@/data/layerDetails'

import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    AssignValidatorsPanel,
    PanelHeader, LayerConfigPanel, UserPanel,
    FeaturePanel, PrognosePanel, Tabs, Feedback 
  },
  data() {
    return {
      activeTab: 'prognose',
      tabs: [
        {
          label: 'Features & Prognose Instellingen',
          id: 'prognose'
        },
        {
          label: 'Kaartlagen',
          id: 'layers'
        },
        {
          label: 'Gebruikers',
          id: 'users'
        },
        {
          label: 'Validatie',
          id: 'chargingpoints'
        },
      ],

      // UI States
      difference: false,
      busy: false,

      // Datasets for the panels
      layers: {},
      prognose: {},
      planmode: {},
      upload: {},
      factsheet: {},
      export: {}
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    ...mapGetters('config', {
      config: 'config',
      isStored: 'isStored',
      mapOptions: 'mapOptions',
      configYears: 'years',
      configLimit: 'limit',
      configModels: 'models',
      configBreakpoints: 'breakpoints',
      configLayers: 'layers',
      configPlanmode: 'planmode',
      configPrognoseEnabled: 'isPrognoseEnabled',
      configUploadEnabled: 'isUploadEnabled',
      configFactsheetEnabled: 'isFactsheetEnabled',
      configExportEnabled: 'isExportEnabled',
      configMonitoringEnabled: 'isMonitoringEnabled',
    }),
    /**
     * The model data for the Features panel
     */
    features() {
      return {
        prognose: this.prognose.enabled,
        planmode: this.planmode.enabled,
        upload: this.upload.enabled,
        factsheet: this.factsheet.enabled,
        export: this.export.enabled,
        monitoring: this.monitoring.enabled,
      }
    },
    /**
     * The config structure, as stored in FaunaDB.
     */
    data() {
      return {
        code: this.getActiveMunicipality,
        name: labelByCode({
          code: this.getActiveMunicipality
        }),
        // Either the upload portal or the prognose needs to be available for a municipality to be active
        enabled: (this.upload.enabled || this.prognose.enabled),
        planmode: this.planmode.enabled,
        upload: this.upload.enabled,
        factsheet: this.factsheet.enabled,
        export: {
          enabled: this.export.enabled
        },
        monitoring: {
          enabled: this.monitoring.enabled
        },
        prognose: {
          enabled: this.prognose.enabled,
          limit: this.prognose.limit,
          years: this.prognose.years,
          breakpoints: [
            this.prognose.breakpoint_one,
            this.prognose.breakpoint_two,
            this.prognose.breakpoint_three
          ],
          models: this.prognose.models
        },
        map: this.mapOptions,
        layers: this.layers
      }
    },
    /**
     * Detect when there is no layer configuration at all
     */
    noActiveLayers() {
      return Object.values(this.layers).filter(layer => layer.status !== 'hidden').length === 0
    }
  },
  watch: {
    /**
     * Upon changing the active municipality, the config is reloaded
     *  TODO: Implement warning notice if anything has changed?
     *  TODO: WebWorkers (Cloudflare workers - evmaps@journeyworks.nl account) to support multiple users editing a config simulteanously
     */
    getActiveMunicipality() {
      this.loadConfig()
    },

    /**
     * Reload if anything changes in the central config store (e.g. config is succesfully saved)
     *  We want to show the actual stored changes. Should something have gone wrong it should be 
     *  noticeable right away.
     */
    config() {
      this.loadConfig()
    },
    noActiveLayers() {
      if (this.noActiveLayers) {
        this.prognose.enabled = false
      }
    }
  },
  created() {
    this.loadConfig()
  },
  methods: {
    ...mapMutations('config',[
      'updateOrAddConfig'
    ]),
    /**
     * Handle a change in tabs
     */
    handleTabClick({ id }) {
      this.activeTab = id
    },
    /**
     * Get configuration details from the config store
     */
    loadConfig() {
      this.prognose = {
        enabled: this.configPrognoseEnabled,
        years: this.configYears,
        limit: this.configLimit,
        models: this.configModels,
        breakpoint_one: this.configBreakpoints[0],
        breakpoint_two: this.configBreakpoints[1],
        breakpoint_three: this.configBreakpoints[2]
      }

      this.planmode = { 
        enabled: !! this.configPlanmode 
      }
      this.upload = {
        enabled: !! this.configUploadEnabled
      }
      this.factsheet = {
        enabled: !! this.configFactsheetEnabled
      }
      this.export = {
        enabled: !! this.configExportEnabled
      }
      this.monitoring = {
        enabled: !! this.configMonitoringEnabled
      }

      this.layers = this.configLayers

      // Ensure that all context layers are present as Observable properties. This allows the UI to function properly
      // TODO: Find a better way to get the same result
      layerDetails.filter(layer => ['context'].includes(layer.type)).forEach(layer => {
        if (this.layers[layer.id]) return
        Vue.set( 
          this.layers,
          layer.id,
          {
            source: '',
            status: 'hidden',
            url: ''
          }
        )
      })

      if (this.noActiveLayers) {
        this.prognose.enabled = false
      }

      /**
       * Normally re-loading the config disables the save button, because there is no dirty data.
       *  However, in case of a config that has not yet been stored, and has been provided with 
       *  layer data (automated or manually), the save button is enabled immediately.
       *  Addition: unless the upload portal mode is enabled
       */
      this.difference = ! this.isStored && (! this.noActiveLayers || this.upload.enabled)

      // Update the UI State
      this.busy = false
    },
    /**
     * Handle config changes from the panel
     */
    handleLayersChange(layers) {
      console.log("layer change")
      this.layers = layers

      this.difference = true
    },
    handlePrognoseChange(data) {
      console.log("prognose change")
      this.prognose = data
      this.difference = true
    },
    handleFeaturesChange(data) {
      console.log("feature change")
      this.planmode.enabled = data.planmode
      this.prognose.enabled = data.prognose
      this.upload.enabled = data.upload
      this.factsheet.enabled = data.factsheet
      this.export.enabled = data.export
      this.monitoring.enabled = data.monitoring
      this.difference = true
    },
    
    /**
     * Store the municipality data
     */
    handleSaveEvent: async function() {
      this.busy = true

      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/configsave', { // TODO: Add polyfill for fetch
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify(this.data)
      })
      
      const response = await api.json()
      console.log(response)
      if (response.config) {
        this.updateOrAddConfig({
          config: response.config
        })
      } else {
        // TODO: Show error feedback
      }
    }
  }
}
</script>

<style lang="scss">
.MunicipalityPanel {

  header {
    background: lighten(#081736, 0%); // darken(#E8EAF1, 25%);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
</style>