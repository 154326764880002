<template>
  <CollapsableSegment :label="label" class="LegendSegment mb-3">

    <template v-slot:header>
      <ToggleSwitch class="m-0" v-model="all" />
    </template>

    <ul class="m-0 p-0 list-unstyled">
      <LegendItem     
        v-for="layer in primaryLayers" 
        :key="layer.id"
        :layer="layer"
        v-model="layer.visible" />
    </ul>
    <CollapsableSubSegment :visible="false">
      <ul class="m-0 p-0 list-unstyled">
        <LegendItem     
          v-for="layer in secondaryLayers" 
          :key="layer.id"
          :layer="layer"
          v-model="layer.visible" />
      </ul>
    </CollapsableSubSegment>

  </CollapsableSegment>
</template>

<script>
import CollapsableSegment from '@/components/common/CollapsableSegment'
import CollapsableSubSegment from '@/components/common/CollapsableSubSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import LegendItem from '@/components/sidebar/LegendItem'

import { dataLayers } from '@/data/layerDetails'

import { mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'

export default {
  name: 'LegendSegment',
  components: {
    CollapsableSegment, CollapsableSubSegment, ToggleSwitch, LegendItem
  },
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      /**
       * This is the specification of the possible layers
       *  We make a copy of the dataset, 1 level deep
       *  TODO: Perhaps reactive state is not needed
       */
      layers: []
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentConfig'
    ]),
    ...mapGetters('config', {
      layerConfig: 'layers'
    }),
    ...mapGetters('prognose', [
      'ready'
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess'
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },

    /**
     * Watch the all layers on / off toggle.
     */
    all: {
      get() {
        return ! this.primaryLayers.some(layer => !layer.visible)
      },
      set(value) {
        this.primaryLayers.forEach(layer => layer.visible = value)
      }
    },
    /**
     * Not all layers will be available for each municipality
     */
    activeLayers() {
      return this.layers.filter(layer => layer.status !== 'hidden')
    },
    /**
     * Primary layers
     */
    primaryLayers() {
      return this.activeLayers.filter(layer => layer.status === 'primary')
    },
    /**
     * Secondary layers
     */
    secondaryLayers() {
      return this.activeLayers.filter(layer => layer.status === 'secondary')
    },
    /**
     * Layers that are _not_ active for this deployment
     */
    excludedLayers() {
      return Object.keys(this.DeploymentConfig.layers).filter(layer => ! this.DeploymentConfig.layers[layer])
    }
  },
  watch: {
    /**
     * Re-load the legend config when the municipality config changes
     *  And apply the toggle states to the newly added layers 
     *  (All layers have to be removed & re-added on munilipality change)
     */
    layerConfig() {
      this.activateLayers()
      this.setActiveLayersVisibility()
    },
    /**
     * When any layer changes state, trigger an update of the Mapbox layer visibility
     *  TODO: Only trigger toggle method for layers that changed
     */
    activeLayers: {
      handler: function() {
        this.setActiveLayersVisibility() 
      },
      deep: true
    },
    /**
     * Triggers when the MapBox instance is ready
     */
    ready() {
      if (this.ready) {
        this.setActiveLayersVisibility()
      }
    }
  },
  created() {
    console.log("created")
    this.layers = dataLayers.slice().filter(o => ! this.excludedLayers.includes(o.id)).map(o => Object.assign({}, o))

    this.activateLayers() 
    EventBus.$on('layers.national.loaded', this.handleNationalLayerLoaded)
  },
  beforeDestroy() {
    EventBus.$off('layers.national.loaded', this.handleNationalLayerLoaded)
  },
  methods: {
    hasLayerAccess() { // { id }
      
      return true
      // Note: this method provides a way to selectively make available legend toggles for municipalities, as seen below. It does not affect the layer itself.

      // if (! ['evmaps_nl_rws_beheergebied', 'evmaps_nl_verkeersstromen'].includes(id)) return true
      // return this.superuser || this.hasAdminAccess({ code: this.getActiveMunicipality }) || ['0335', '1783'].includes(this.getActiveMunicipality)
    },
    /**
     * Apply the layer config from the selected municipality
     */
    activateLayers() {
      this.layers.forEach(layer => {
        layer.status = 
          (this.layerConfig[layer.id] && this.layerConfig[layer.id].status) || 
          (layer.type === 'national' && this.hasLayerAccess({ id: layer.id }) && layer.status) || 
          ( 'hidden' )

        layer.visible = layer.status === 'primary'
      })
    },
    /**
     * Apply the visibility state to the Mapbox layers
     *  This is triggered on any change of the toggles
     *  as well as a switch to a different municipality
     */
    setActiveLayersVisibility() {
      this.activeLayers
        .forEach(layer => {
          // One toggle can be bound to multiple MapBox layers
          let ids = layer.layers || [ layer.id ]
          ids.forEach(id => {
            this.toggleLayer({ layer: id, visibility: layer.visible })
          })
        })
    },
    /**
     * Toggle the visibility state of a specific MapBox layer
     */
    toggleLayer({ layer, visibility }) {
      if (this.ready && this.$store.map.getLayer(layer)) {
        let state = visibility 
          ? 'visible'
          : 'none'
        this.$store.map.setLayoutProperty(layer, 'visibility', state) 
      }
    },
    /**
     * Update the layer visibility as soon as the layer has been loaded
     */
    handleNationalLayerLoaded({ name }) {
      let layer = this.activeLayers.find(layer => layer.id === name)
      if (layer) {
        this.toggleLayer({
          layer: name,
          visibility: layer.visible
        })
      }
    }
  }
}
</script>