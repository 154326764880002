<template>
  <div style="display:none"></div>
</template>

<script>
/* eslint-disable */

/**
 * TODO: Planned locations also get a time marker (year + Q1, Q2, Q3, Q4) to indicate priority
 * TODO: Move visibility logic from Legend to this component & connect with EventBus
 */

import { mapGetters, mapActions, mapMutations } from 'vuex'

import { initiate, subscribe, unsubscribe } from '@/services/pusher'
import { isProxyByCode } from '@/services/municipalities'
import { provincieCodeByMunicipalityCode } from '@/services/municipalities'

import chargingpointsLoadMixin from "@/mixins/chargingpoint/chargingpointsLoadMixin";
import {uniqueIntId} from "@/helpers/number";

export default {
  name: 'PlannedPointsLayer',
  mixins: [chargingpointsLoadMixin],
  props: {
    /**
     * Whether or not the mapbox instance is ready and available
     */
    loaded: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      /**
       * Icon counter. We're loading 5 icons (see requiredIcons)
       */
      iconCounter: 0,

      /**
       * Pusher channel
       */
      channel: null
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess'
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints',
      'getFilters',
      'getFilterById',
      'getLayers'
    ]),
    ...mapGetters('config', [
      'isPlanmodeEnabled'
    ]),
    ...mapGetters('deployment', [
      'getThresholdFastchargerInKw'
    ]),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },

    /**
     * The list of icons that need to be loaded
     *  While the planmode may be disabled for one municipality, it can be active for the next.
     *  We're loading all icons regardless of the feature state to keep things simple
     */
    requiredIcons() {
      return [
        'alert',
        'realized',
        'realized-private',
        'in-progress',
        'definitive',
        'suggestion',
        'rejected',
        'fastcharger-realized',
        'fastcharger-definitive',
        'fastcharger-suggestion',
        'fastcharger-rejected',
        'charging-hub-definitive',
        'charging-hub-suggestion',
        'charging-hub-rejected'
      ]
    },

    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    filters() {
      return this.getFilters
    }
  },
  watch: {
    /**
     * Load new data when the municipality changes
     */
    getActiveMunicipality: async function(code, previous) {

      // Update the Pusher connection
      unsubscribe({ channel: previous})
      let { channel } = await subscribe({ channel: code })
      this.channel = channel

      await this.$_chargingpointsLoadMixin_loadChargingPoints({ code })

      // Apply the priority filter
      this.updateFilters()
    },

    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      } 
      if (ready) {
        this.loadIcons()
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer()
      }
    },

    /**
     * When the charger locations list changes, we update the layer data
     */
    getChargingPoints(chargingpoints) {
      if (! this.loaded) return
      
      chargingpoints = this.applyAccessRestrictions({ chargingpoints })

      let source = this.$store.map.getSource('chargingpoints')
      if (source) {
        source.setData(this.generateGeoJson(chargingpoints))
      }
    },

    /**
     * Changes to the pusher channel
     */
    channel(channel) {
      if (channel === null) return

      channel.bind('client-chargingpoint-saved', this.handleChargingpointSavedEvent)
    },

    /**
     * 
     */
    filters: {
      handler: function() {
        this.setOpacity()
      },
      deep: true
    },
    getLayers: {
      handler: function() {
        this.setOpacity()
      }, 
      deep: true
    }
  },
  /**
   * Take prep actions as soon as possible
   *  By loading the charging point data
   *  and marker icons
   */
  created: async function() {
    const token = await this.$auth.getTokenSilently();
    initiate({ token })

    let { channel } = await subscribe({ channel: this.getActiveMunicipality })
    this.channel = channel

    await this.$_chargingpointsLoadMixin_loadChargingPoints({
      code: this.getActiveMunicipality
    })

    // wait until loaded animation of chargingpoint-icons is over
    setTimeout(() => this.setAppReady({ value: true }), 1000)

    this.updateFilters()
  },
  /**
   * Unsubscribe from pusher channel
   */
  beforeDestroy() {
    unsubscribe({ channel: this.getActiveMunicipality })
  },
  methods: {
    ...mapActions('planmode', [
      'addOrUpdateChargingPoint'
    ]),
    ...mapMutations('planmode', [
      'setFilter',
    ]),
    ...mapMutations('app', [
      'setAppReady'
    ]),

    /**
     * Automatically turn on priority filter for any municipalities in Limburg & Brabant provinces.
     * Automatically turn it off for the other municipalities
     */
    updateFilters() {
      let filter = this.filters[0]

      let activate = ([31, 30].includes(provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality })))
      let isBraLiProvincieCode = ['9006', '9007'].includes(this.getActiveMunicipality)

      filter.active = activate
      filter.to = activate ? (isBraLiProvincieCode ? 2 : 1) : 0
      filter.from = activate ? 1 : 0

      this.setFilter({
        id : filter.id,
        options: filter
      })
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.$store.map.loadImage(
          require(`@/assets/image/legend/chargingpoint-${name}.png`),
          (err, image) => {
            if (err) throw err;

            this.$store.map.addImage(`chargingpoint-${name}`, image);
            this.iconCounter++
        })
      })
    },
    /**
     * Apply filters to the chargingpoints dataset based on access rights
     */
    applyAccessRestrictions( { chargingpoints }) {

      // Only superusers and municipality admins get early access to planmode features
      if (
        ! this.isPlanmodeEnabled && 
        ! this.superuser && 
        ! this.hasAdminAccess({ code: this.getActiveMunicipality }) && 
        ! isProxyByCode({ code: this.getActiveMunicipality }) 
      ) {  
        chargingpoints = chargingpoints
          .filter(
            chargingpoint => ['realized', 'realized-private', 'in-progress', 'fastcharger-realized'].includes(chargingpoint.data.properties.status)
          )
      }

      // (for now) only super users get access to private locations
      // if (!this.superuser) {
      //   chargingpoints = chargingpoints.filter(
      //     chargingpoint => (! ['realized-private'].includes(chargingpoint.data.properties.status))
      //   )
      // }

      return chargingpoints
    },

    /**
     * Handle a Pusher event, triggered when another user saves (or deletes) a charging point.
     */
    handleChargingpointSavedEvent({ chargingpoint }) {
      this.addOrUpdateChargingPoint({ chargingpoint })
    },
    
    /**
     * Translate datapoints into GeoJSON
     */
    generateGeoJson(points) {
      let geoJson = {
        "type": "FeatureCollection",
        "features": []
      }
      
      points = Array.isArray(points) ? points : []


      points.forEach(point => {

        let status = point.data.properties.status
        let prio = {
          fase: 99999,
          order: 99999999999999999
        }

        if (point.data.prio) {
          if (point.data.prio.order) {
            prio.order = point.data.prio.order
          }
          if (point.data.prio.fase) {
            prio.fase = point.data.prio.fase
          }
        }

        // if power is above threshold and the status is not already fastcharger, update it accordingly
        if (
          point.data.properties.status.includes('fastcharger') === false &&
          point.data.monitoring?.power >= this.getThresholdFastchargerInKw
        ) {
          status = `fastcharger-${point.data.properties.status}`
        }

        /*
        * id must be castable to int, and there are other restrictions, see: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfeaturestate
        */
        geoJson.features.push({
          "id": uniqueIntId({chargingpoint: point}),
          "type": "Feature",
          "properties": Object.assign(
            point.data.properties,
          {
            refId: point.ref['@ref'].id,
            priority: prio.order,
            priofase: prio.fase,
            power: point.data.monitoring?.power,
            status
          }),
          "geometry": {
            "type": "Point",
            "coordinates": point.data.coordinates
          }
        })
      })

      return geoJson
    },

    /**
     * Update the opacity property
     */
    setOpacity() {
      // Context
      let filterPriority = this.filters[0]
      let filterValidation = this.filters[1]
      let filterValidationDone = this.filters[2]
      let filterToDecide = this.filters[3]

      let layers = this.getLayers || []

      if (! this.$store.map || ! this.$store.map.getLayer('chargingpoints') || layers.length === 0) return

      const isAnyFilterActive = this.filters.some((filter => parseInt(filter.to, 10) !== 0))
      let opacity = isAnyFilterActive ? 0.3 : 0.8

      /**
       * Legend on / off Toggle effect
       */
      let iconState = [
        "match", 
        ['get', 'status'],
      ]
      let textState = [
        "match", 
        ['get', 'status']
      ]

      layers.forEach(layer => {
        let status = layer.id.replace('chargingpoints-', '')
        iconState.push(status)
        iconState.push(layer.visible ? (status === 'alert' ? 0.8 : opacity) : 0)
        
        if (status === 'alert') return
        textState.push(status)
        textState.push(layer.visible ? opacity : 0)
      })

      iconState.push(0)
      textState.push(0)

      let textStyle = [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        0,
        14.2,
        textState
      ]

      // Default
      let style = [
        "interpolate",
        ["linear"],
        ["zoom"],
        10,
        0,
        10.2,
        iconState
      ]

      /**
       * 
       */
      if (filterPriority.active) {
        // todo:: this needs to be a function per filter
        style = [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0,
          10.2,
          ["case",
            // In case the charger is within the active priority range, show it like normal
            ['all', ['<=', ["get", "priofase"], parseInt(filterPriority.to, 10)], ['>=', ["get", "priofase"], parseInt(filterPriority.from, 10)]],
            0.9,
            // Otherwise reduce opacity to 0.3
            iconState
          ]
        ]

        textStyle = [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          14.2,
          ["case",
            // In case the charger is within the active priority range, show it like normal
            ['all', ['<=', ["get", "priofase"], parseInt(filterPriority.to, 10)], ['>=', ["get", "priofase"], parseInt(filterPriority.from, 10)]],
            0.9,
            // Otherwise reduce opacity to 0.3
            textState
          ]
        ]
      }

      if (filterValidation.active) {
        style = [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0,
          10.2,
          ["case",
            ['boolean', ["feature-state", "needsToBeVotedByValidator"], false],
            0.9,
            iconState
          ]
        ]

        textStyle = [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          14.2,
          ["case",
            ['boolean', ['feature-state', 'needsToBeVotedByValidator'], false],
            0.9,
            textState
          ]
        ]
      }

      if (filterValidationDone.active) {
        style = [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0,
          10.2,
          ["case",
            ['boolean', ["feature-state", "hasAlreadyValidated"], false],
            0.9,
            iconState
          ]
        ]

        textStyle = [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          14.2,
          ["case",
            ['boolean', ['feature-state', 'hasAlreadyValidated'], false],
            0.9,
            textState
          ]
        ]
      }

      if (filterToDecide.active) {
        style = [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0,
          10.2,
          ["case",
            ['boolean', ["feature-state", "needsToBeDecided"], false],
            0.9,
            iconState
          ]
        ]

        textStyle = [
          "interpolate",
          ["linear"],
          ["zoom"],
          14,
          0,
          14.2,
          ["case",
            ['boolean', ['feature-state', 'needsToBeDecided'], false],
            0.9,
            textState
          ]
        ]
      }

      this.$store.map.setPaintProperty('chargingpoints', 'icon-opacity', style);
      this.$store.map.setPaintProperty('chargingpoints-text', 'text-opacity', textStyle) 
    },

    /**
     * Load the chargingpoint layer
     */
    addLayer() {

      // Clean up first if we must
      let source = this.$store.map.getSource('chargingpoints')
      if (source) {
        if (this.$store.map.getLayer('chargingpoints')) {
          this.$store.map.removeLayer('chargingpoints')
        }
        if (this.$store.map.getLayer('chargingpoints-text')) {
          this.$store.map.removeLayer('chargingpoints-text')
        }
        
        this.$store.map.removeSource('chargingpoints')
      }
      

      // Add the generated source 
      this.$store.map.addSource('chargingpoints', {
        type: 'geojson',
        data: this.generateGeoJson(
          this.applyAccessRestrictions({ chargingpoints: this.getChargingPoints })
        ) 
      })

      /**
       * The marker icon layer
       */
      this.$store.map.addLayer({ 
        "id": "chargingpoints",
        "type": "symbol",
        "source": "chargingpoints",
        "minzoom": 10,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          "icon-allow-overlap": true,
          "icon-image": [
            "match", 
            ['get', 'status'],
            "rejected", "chargingpoint-rejected",
            "definitive", "chargingpoint-definitive",
            "in-progress", 'chargingpoint-in-progress',
            "realized", 'chargingpoint-realized',
            "realized-private", 'chargingpoint-realized-private',
            "fastcharger-realized", 'chargingpoint-fastcharger-realized',
            "fastcharger-definitive", 'chargingpoint-fastcharger-definitive',
            "fastcharger-suggestion", 'chargingpoint-fastcharger-suggestion',
            "fastcharger-rejected", "chargingpoint-fastcharger-rejected",
            "charging-hub-definitive", 'chargingpoint-charging-hub-definitive',
            "charging-hub-suggestion", 'chargingpoint-charging-hub-suggestion',
            "charging-hub-rejected", "chargingpoint-charging-hub-rejected",
            "suggestion", 'chargingpoint-suggestion',
            "alert", 'chargingpoint-alert', 
            "nodefaultimage", // Hide unkown points
          ],
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0.05,
            18,
            0.2
          ],
        },
        "paint": {
          // "icon-color": "",
          "icon-opacity": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            10.2,
            0.8
          ]
        }
      }) // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.$store.map.addLayer({
        "id": "chargingpoints-text",
        "type": "symbol",
        "source": "chargingpoints",
        "minzoom": 14,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          // 'text-allow-overlap': true,
          'text-field': [
            "match",
            ['get', 'status'],
            "definitive", ['get', 'id'],
            "rejected", ['get', 'id'],
            "in-progress", ['get', 'id'],
            "realized", ['get', 'id'],
            "realized-private", ['get', 'id'],
            "fastcharger-realized", ['get', 'id'],
            "fastcharger-definitive", ['get', 'id'],
            "fastcharger-suggestion", ['get', 'id'],
            "fastcharger-rejected", ['get', 'id'],
            "charging-hub-definitive", ['get', 'id'],
            "charging-hub-suggestion", ['get', 'id'],
            "charging-hub-rejected", ['get', 'id'],
            "suggestion", ['get', 'id'],
            // "alert", 'chargingpoint-alert',
            "", // Hide suggestions
          ],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          "text-size": 12,
          "text-radial-offset": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            1,
            18.2,
            2
          ],
          'text-variable-anchor': [ "top-right", "bottom-right", "bottom-left", "top-left", "right", "left", "top", "bottom" ],
          "text-justify": 'auto'
        },
        "paint": {
          "text-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.2,
            [
              'case',
              ['boolean', ['feature-state', 'vote'], false],
              1,
              0.5
            ]
          ]
        }
      })

      // Once the layers are added. Update the opacity based on the filters
      this.setOpacity()
    }
  }
}

</script>

