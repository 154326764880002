import {mapGetters} from "vuex";
import {statusSlugToLabel} from "@/services/statusTranslations";
import chargingpointCpoMixin from "@/mixins/chargingpoint/chargingpointCpoMixin";

export default {
  mixins: [chargingpointCpoMixin],
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    isBraLiProvincieCode() {
      return ['9006', '9007'].includes(this.getActiveMunicipality)
    },
    address() {
      return this.chargingpoint.data.address
    },
    addressSource() {
      if (this.chargingpoint.data.address?.source === 'Google') {
        return 'Google Geocoder resultaat'
      }
      if (this.chargingpoint.data.address?.source === 'EcoMovement') {
        return 'EcoMovement dataset'
      }
      if (this.chargingpoint.data.address?.source === 'Manual') {
        return 'Handmatige invoer'
      }
      if (this.chargingpoint.data.address?.source === 'import-mrae') {
        return 'Import MRA-E'
      }

      return 'Onbekend'
    },
    coordinates() {
      if (!this.chargingpoint.data.coordinates[0] || !this.chargingpoint.data.coordinates[1]) {
        return false
      }

      return {
        'lng': this.chargingpoint.data.coordinates[0],
        'lat': this.chargingpoint.data.coordinates[1],
      }
    },
    prio() {
      return this.chargingpoint.data.prio
    },
    plannerName() {
      return this.chargingpoint.data.properties.user
        ? this.chargingpoint.data.properties.user.name :
        (this.chargingpoint.data.properties.user_name || 'EV Consult')
    },
    remark() {
      return this.chargingpoint.data.properties.remark
    },
    stakeholders() {
      return this.chargingpoint.data.properties.stakeholders || []
    },
    status() {
      return this.chargingpoint.data.properties.status
    },
    beheerder() {
      return this.chargingpoint.data.properties.beheerder
    },
    validators() {
      return this.chargingpoint.data.properties.validators
    },
    hasValidators() {
      return this.validators?.length > 0
    },
    statusLabel() {
      return statusSlugToLabel({status: (this.status || 'definitive')})
    }
  },
}