
<script>
import { image } from '@/helpers/assets'
import { capitalizeFirstLetter } from '@/helpers/string'
import { combineClassLists } from '@/helpers/render'

export default {
  name: 'Logo',
  functional: true,
  props: {
    organisation: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: 'logo.png'
    },
    height: {
      type: Number,
      default: 50
    }
  },
  render(h, context) {
    return h('img', {
      attrs: {
        src: image({ name: context.props.image }),
        height: context.props.height + 'px'
      },
      class: combineClassLists({ 
        classList: { Logo: true },
        context
      }),
      domProps: {
        alt: capitalizeFirstLetter((context.props.organisation + ' logo').trim())
      }
    })
  }
}
</script>

<style scoped>
.Logo {
  max-width: 100%;
  max-height: 100%;
} 
</style>