import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Viewer from '../views/Viewer.vue'
import Upload from '../views/Upload.vue'
import Export from '../views/Export.vue'
import NotFound from '../views/NotFound.vue'

import Factsheet from '../views/Factsheet.vue'

// Beheer
import Dashboard from '../views/admin/Dashboard'
import UsersDashboard from '../views/admin/UsersDashboard'

// Password Enforcer
import EnforcePassword from '../views/EnforcePassword'

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wachtwoord',
    name: 'EnforcePassword',
    component: EnforcePassword
  },
  {
    path: '/beheer/gebruikers',
    name: 'UsersDashboard',
    component: UsersDashboard
  },
  {
    path: '/beheer/:municipality?',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/:municipality/factsheet',
    name: 'Factsheet',
    component: Factsheet
  },
  {
    path: '/:municipality/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/:municipality/export',
    name: 'Export',
    component: Export
  }, 
  {
    path: '/:municipality',
    name: 'Viewer',
    component: Viewer
  },
  {
    path: '/*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(authGuard)

export default router


