<template>
  <div class="ActiveTab ValidationTab">
    <ActiveTabHeader :chargingpoint="chargingpoint">
      <h4>Opmerkingen</h4>
    </ActiveTabHeader>

    <div class="ActiveTab__Content">
      <TheComments :chargingpoint="chargingpoint"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ActiveTabHeader from "@/components/map/sidebar/ActiveTabHeader";
import TheComments from "@/components/map/sidebar/TheComments";

export default {
  components: {TheComments, ActiveTabHeader},
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
  }
}
</script>

<style lang="scss">
.ActiveTab__Content {
  p {
    margin-bottom: .5rem
  }
}
</style>