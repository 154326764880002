<template>
  <span class="InlineToggle">
    <template v-for="(option, index) in options">
      <span 
        :class="{ 'InlineToggle__active': index === selected }"
        :key="option.value" 
        @click="handleClick({ index })">
        {{ option.label }}
      </span>
      <span class="mx-2" :key="`${option.value}_bar`">|</span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'InlineToggle',
  props: {
    options: {
      type: Array,
      default: null // Label, Value
    }
  },
  data() {
    return {
      selected: 0
    }
  },
  methods: {
    handleClick({ index }) {
      this.selected = index

      this.$emit('selected', { selected: this.options[this.selected].value, index })
    }
  }
}
</script>

<style lang="scss">
.InlineToggle {
  span:nth-child(2n+1) {
    cursor: pointer;
    &:hover {
      color: rgb(214, 82, 0);
    }
  }

  span:last-child{
    display: none;
  }

  &__active {
    color: rgb(214, 82, 0);
  }
}
</style>