/**
 * Import Dependency
 */
import { slugToCode, provincieCodeByMunicipalityCode } from '@/services/municipalities'
import { layers as layerDetails } from '@/data/layerDetails'
const contextLayers = layerDetails.filter(layer => layer.type === 'context')
// const nationalLayers = layerDetails.filter(layer => layer.type === 'national')

import { configGenerator } from '@/services/configGenerator'

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  /**
   * Configs of all municipalities that the user has access to
   */
  configs: [],

  active: null,

  loaded: false,

  // Generic config values
  accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
  mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
  mapStyleSat: process.env.VUE_APP_MAPBOX_STYLE_SAT
}

const getters = {
  loaded: state => state.loaded,
  accessToken: state => state.accessToken,
  mapStyle: state => state.mapStyle,
  mapStyleSat: state => state.mapStyleSat,

  /**************************************************************************************
   * GETTERS about all configs
   */

  /**
   * Get a list with codes of the loaded configs
   */
  getAvailableConfigCodes: state => state.configs.map(config => config.code),

  /**************************************************************************************
   * GETTERS for information by CODE
   */

  /**
   * Check the availability of a specific config by code or slug
   */
  hasConfig: (state, getters) => ({ code, slug }) => {
    if ( slug ) {
      code = slugToCode({ slug })
    }
    return !! getters.getConfigByCode({ code })
  },
  hasLayers: (state, getters) => ({ code, slug }) => {
    if ( slug ) {
      code = slugToCode({ slug })
    }

    let config = getters.getConfigByCode({ code })
    
    if (! config) return false
    let layers = Object.values(config.layers)

    return layers.length !== 0 && layers.some((layer => layer.status !== 'hidden'))
  },

  /**
   * Get the details of a specific config by code or slug
   */
  getConfigByCode: state => ({ code }) => {
    return state.configs.find((config) => config.code === code)
  },
  getConfigBySlug: (state, getters) => ({ slug }) => {
    return getters.getConfigByCode({ code: slugToCode({ slug }) })
  },

  /**
   * Check whether the `stored` flag is set. This indicates whether the record came from storage
   *  The flag is set by the lambda endpoints on all configs that originate from storage
   */
  isStoredByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config ? (!!config.stored) : false
  },

  /**
   * Get enabled states for specific municipalities by code
   */
  isEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config ? config.enabled : false
  },
  isPrognoseEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config && config.prognose ? config.prognose.enabled : false
  },
  isPlanmodeEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config ? config.planmode : false
  },
  isUploadEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config ? config.upload : false
  },
  isFactsheetEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config ? config.factsheet : false
  },
  isExportEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config && config.export ? config.export.enabled : false
  },
  isMonitoringEnabledByCode: (state, getters) => ({ code }) => {
    let config = getters.getConfigByCode({ code })
    return config && config.monitoring ? config.monitoring.enabled : false
  },

  /**************************************************************************************
   * GETTERS for Active Config
   */

  /**
   * The complete config of the active municipality
   */
  config: state => {
    return state.configs.find((config) => config.code === state.active) || {}
  },
  hasSelectedConfig: (state, getters) => getters.config !== {},

  /**
   * Check whether the `stored` flag is set. This indicates whether the record came from storage
   *  The flag is set by the lambda endpoints on all configs that originate from storage
   */
  isStored: (state, getters) => !! getters.config.stored,
  
  /**
   * Whether the municipality / ev maps features are enabled
   */
  isEnabled: (state, getters) => getters.config.enabled,
  isPrognoseEnabled: (state, getters) => !! getters.config.prognose.enabled,
  isPlanmodeEnabled: (state, getters) => !! getters.config.planmode,
  isUploadEnabled: (state, getters) => !! getters.config.upload,
  isFactsheetEnabled: (state, getters) => !! getters.config.factsheet,
  isExportEnabled: (state, getters) => !! getters.config.export,
  isMonitoringEnabled: (state, getters) => !! getters.config.monitoring,


  /**
   * Get the layer data
   */
  layers: (state, getters) => getters.config.layers,
  /**
   * Filter the layer configs. Only pass on the context type layers
   */
  contextLayers: (state, getters) => {
    const ids = contextLayers.map(layer => layer.id)
    let context = {}
    Object.entries(getters.layers).forEach(entry => {
      if (ids.includes(entry[0])) {
        context[entry[0]] = entry[1]
      }
    })
    return context
  },

  forcastOptions: (state, getters, rootState, rootGetters) => {
    let code = getters.config.code
    let provincieCode = provincieCodeByMunicipalityCode({ code })
    let country = rootGetters['deployment/DeploymentCode'] // be / nl / etc

    // A special mode for 'Vlaams Gewest'
    if (country === 'be' && code === '2000') {
      return [{
        value: 'gemeenteprognoses',
        text: 'Gemeenten (bewoners)'
      }]
    }

    let options = [
      {
        value: 'determinative',
        text: 'Bewoners / bezoekers / forenzen'
      },
    ]
    if (rootGetters['deployment/DeploymentHasLayer']({ layer: 'fastcharge' })) {
      options.push({
        value: 'fastcharge',
        text: 'Snelladers'
      })
    }

    if (rootGetters['deployment/DeploymentHasLayer']({ layer: 'gemeenteprognoses' })) {
      options.push({
        value: 'gemeenteprognoses',
        text: 'Gemeenten (bewoners)'
      })
    }

    options.push(
      {
        value: 'inhabitants',
        text: 'Bewoners'
      },
      {
        value: 'visitors',
        text: 'Bezoekers'
      },
      {
        value: 'commuters',
        text: 'Forenzen'
      }
    )

    if (provincieCode === 28) {
      options.push({
        value: 'carsharing',
        text: 'Deelautos'
      }, {
        value: 'logistics',
        text: 'Stadslogistiek'
      }, {
        value: 'taxis',
        text: 'Taxis'
      })
    }

    return options
  },

  /**
   * The map attributes
   */
  mapOptions: (state, getters) => getters.config.map,
  center: (state, getters) => getters.config.map.center.map(num => parseFloat(num, 10)),
  zoom: (state, getters) => parseFloat(getters.config.map.zoom, 10),

  /**
   * The planmode attributes
   */
  planmode: (state, getters) => getters.config.planmode,

  /**
   * Prognose attributes
   */
  models:  (state, getters) => getters.config.prognose.models,
  years: (state, getters) => getters.config.prognose.years.map(num => parseInt(num)),
  limit: (state, getters) => parseInt(getters.config.prognose.limit),
  breakpoints: (state, getters) => {
    // TODO: Feature: allow more breakpoints to be set (requires either adding color, or GeoChart gradient solution)
    let breakpoints = getters.config.prognose.breakpoints
    return breakpoints && breakpoints.length === 3 
        ? breakpoints.map(num => parseInt(num)) 
        : [6, 12, 24]
  }
}
const actions = {
  createConfig({ commit, getters, rootGetters }, { code }) {
    let config = configGenerator({
      deployment: rootGetters['deployment/DeploymentCode'],
      code,
      tilesets: rootGetters['tilesets/tilesets'],
      models: getters['models'],
    })

    commit('updateOrAddConfig', { config })
    commit('selectConfig', { code })
  }
}
const mutations = {
  selectConfig(state, { code, slug }) {
    if ( slug ) {
      code = slugToCode({ slug })
    }
    state.active = code
  },
  loadConfigs(state, { configs }) {
    state.configs = configs
    state.loaded = true
  },
  updateOrAddConfig(state, { config }) {
    let index = state.configs.findIndex(item => item.code === config.code)

    if (index !== -1) {
      state.configs.splice(index, 1, config)
    } else {
      state.configs.push(config)
    }
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
