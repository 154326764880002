/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  loaded: false,
  userMetaData: {},
  tours : {
    'structured-validation': {
      skippedCount: 0,
      skippedStep: {},
      finishedCount: 0,
      version: '1.0',
      deployedInVersion: '2.8.0',
    }
  }
}

const getters = {
  getUserMetaData: (state) => state.userMetaData,
  hasUserSeenThisTour: (state) => ({ tourName, version }) => {
    console.log('meta', state.userMetaData.tours?.[tourName])
    state.userMetaData.tours[tourName] && state.userMetaData.tours[tourName].include(version)
  }
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  skipTour({commit, getters}, { tourName, version, currentStep }) {
    const tours = getters['getUserMetaData'];

    tours[tourName] = { [version]: true }

    // todo:: upload tours to auth0-user-profile
    // todo:: add analytics something, could be a dumb counter in fauna
  },
}

const mutations = {
  setUserTourData(state, { userMetaData }) {
    state.userMetaData = userMetaData
    state.loaded = true
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
