<template>
  <div class="UploadDashboard d-flex flex-column w-100 align-items-center">
    <div class="ExportPanelWrapper mt-5 mb-4 flex-grow-1">

      <PanelHeader :enableSave="false" btnlabel="" />

      <Feedback v-if="!dimissed" :feedback="{ variant: 'logo'}" class="mx-4 mt-4 pt-4 pb-4" @dismissed="dimissed = true">
        <div style="max-width: 800px">
          Beste {{ username }},
          <br /><br />
          Via dit online portaal kunt u de laadpalen downloaden om te verwerken in uw eigen systemen.
          <br/>
          De dataset omvat alle laadpalen, ongeacht de status. Middels de beschikbare eigenschappen kan de gedownloade dataset naar wens gefilterd worden.
          <br/>
          <br/>
          Met vriendelijke groet,
          <br />
          EVTools
          <br/><br/>
          <span class="text-muted">
            Specifieke vragen over de EV Maps? Mail ons op  <MailtoSupport />.
          </span>
        </div>
      </Feedback>

      <Feedback class="mx-4 mt-4" :feedback="feedback" />

      <div class="d-flex flex-column p-4">

        <div class="ExportPanel d-flex mt-3 pb-3">
          <aside class="ExportPanel__Aside flex-shrink-0 mr-3">
            <h3 class="ExportPanel__Title">
              Laadpaal informatie
            </h3>
            <div class="w-75 mt-3">
              De laadpaal informatie is beschikbaar in 3 formats.
            </div>
          </aside>

          <div class="ExportPanel__Main flex-grow-1 mb-3">
            <div class="d-flex align-items-center">
              <span class="ExportPanel__Label mr-3">
                Excel
              </span>
              <b-button :disabled="busy" variant="cta" class="ml-3" @click="downloadExcel">
                Download
              </b-button>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span class="ExportPanel__Label mr-3">
                Shapefile
              </span>
              <b-button :disabled="busy" variant="cta" class="ml-3" @click="downloadShapefile">
                Download
              </b-button>
            </div>
            <div class="d-flex align-items-center mt-3 mb-3">
              <span class="ExportPanel__Label mr-3">
                GeoJSON
              </span>
              <b-button :disabled="busy" variant="cta" class="ml-3" @click="downloadGeoJSON">
                Download
              </b-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from '@/components/common/PanelHeader'
import Feedback from '@/components/form/Feedback'
import MailtoSupport from "@/components/common/MailtoSupport";

import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'

import { getDateStamp } from '@/helpers/date'
import { labelByCode } from '@/services/municipalities'
import { statusSlugToLabel, statusSlugToNumber } from "@/services/statusTranslations";

import chargingpointsLoadMixin from "@/mixins/chargingpoint/chargingpointsLoadMixin";

import shpwrite from 'shp-write'
const ExcelJS = require('exceljs');

export default {
  name: 'ExportPanel',
  components: {
    MailtoSupport,
    PanelHeader, Feedback
  },
  mixins: [chargingpointsLoadMixin],
  data() {
    return {
      busy: true,

      dimissed: false,
      feedback: {

      }
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints'
    ]),
    username() {
      return this.$auth.user.name
    },
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    }
  },
  watch: {
    /**
     * Load new data when the municipality changes
     */
    getActiveMunicipality: async function(code) {
      this.loadChargingPoints({
        code
      })
    },
  },
  created() {
    this.loadChargingPoints({
      code: this.getActiveMunicipality
    })
  },
  methods: {
    /***********************************************************************************
     * Obtain Charging points details
     **********************************************************************************/

    /**
     * Load the charging points for a municipality by code
     */
    loadChargingPoints: async function({ code }) {

      this.busy = true
      this.feedback = {
        message: 'De actuele laadpaal data wordt opgehaald...',
        variant: 'info'
      }

      await this.$_chargingpointsLoadMixin_loadChargingPoints({code})

      this.busy = false
      this.feedback = {
        message: `De actuale laadpaal informatie van ${this.municipalityLabel} in beschikbaar.`,
        variant: 'info'
      }
    },

    /***********************************************************************************
     * Generate download files
     **********************************************************************************/

    /**
     * GeoJSON
     *  Note:
     *  - The properties structure is input for the Excel
     *  - The GeoJSON is direct input for the shapefile
     */
    generateGeoJSON() {
      let chargingpoints = this.getChargingPoints
      let code = this.getActiveMunicipality

      let geojson = {
        type: 'FeatureCollection',
        features: []
      }

      /**
       * Add the entries to the GEOJSON
       */
      geojson.features = chargingpoints.map((entry) => {

        let number = null
        let suffix = null

        // Break up number into number + suffix
        if (entry.data.address && entry.data.address.number) {
          let segments = entry.data.address.number.split(/(\d+)/).filter(a => a !== '')
          number = segments.shift()
          suffix = segments.join('').trim()
          if (suffix === '') {
            suffix = null
          }
        }

        let properties = {
          id: `${code}-${entry.data.properties.id}`,
          lat: entry.data.coordinates[1]+''.replace('.',','),
          lng: entry.data.coordinates[0]+''.replace('.',','),
          street: entry.data.address ? entry.data.address.street : null,
          streetnumber: number,
          streetsuffix: suffix,
          postalcode: entry.data.address && entry.data.address.postalcode ? entry.data.address.postalcode.replace(/\s/g, '') : null,
          municipality: entry.data.address ? entry.data.address.municipality : null,
          city: entry.data.address ? entry.data.address.city : null,
          reference: entry.data.properties.vkb || '', 
          remark: entry.data.properties.remark,
          statusnumber: statusSlugToNumber({ status: entry.data.properties.status }),
          statusdescription: statusSlugToLabel({ status: entry.data.properties.status }),
          rolloutschedule: entry.data.prio ? entry.data.prio.order : null,
          gridconnection: ['definitive'].includes(entry.data.properties.status) ? '3*25A' : ''
        }

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: entry.data.coordinates
          },
          properties
        }
      })

      return geojson
    },

    generateExcelData() {
      return this.generateGeoJSON().features.map(feature => feature.properties)
    },

    downloadGeoJSON() {
      let content = new Blob([JSON.stringify(this.generateGeoJSON(), null, 4)], {type: "text/plain;charset=utf-8"})
      saveAs(content, `${this.municipalityLabel}-laadpalen-${getDateStamp()}.geojson`)
    },
    downloadExcel: async function() {

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Laadpalen');
      worksheet.columns = [
        { header: 'id', key: 'id', width: 10 },
        { header: 'Latitude', key: 'lat', width: 32 },
        { header: 'Longitude', key: 'lng', width: 32 },
        { header: 'Streetname', key: 'street', width: 32 },
        { header: 'Street number', key: 'streetnumber', width: 32 },
        { header: 'Street suffix', key: 'streetsuffix', width: 32 },
        { header: 'Postal code', key: 'postalcode', width: 32 },
        { header: 'Municipality', key: 'municipality', width: 32 },
        { header: 'City', key: 'city', width: 32 },
        { header: 'Reference "wegenVerkeersbesluit"', key: 'verzamelverkeersbesluit', width: 32 },
        { header: 'Opmerking', key: 'remark', width: 32 },
        { header: 'Status Number', key: 'statusnumber', width: 32 },
        { header: 'Status Description', key: 'statusdescription', width: 32 },
        { header: 'Rollout schedule', key: 'rolloutschedule', width: 32 },
        { header: 'Grid connection', key: 'gridconnection', width: 32 },
      ];

      worksheet.addRows(
        this.generateExcelData()
      )

      const buffer = await workbook.xlsx.writeBuffer({ base64: true })
      saveAs(new Blob([buffer],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), `${this.municipalityLabel}-laadpalen-${getDateStamp()}.xlsx`);
    },
    downloadShapefile() {
      // There are some issues with Safari one way or another...
      // See: https://github.com/eligrey/FileSaver.js/issues/12
      // Using SaveAs with a Blob & ArrayBuffer avoids an error, but generates a (zip) file that can't be processed
      // The zip resulting from the code below works, although the name is random. 
      // The contained shapefile was problematic for Mapshaper, but QGIS could read it without problems.
      shpwrite.download(this.generateGeoJSON(), {
        folder: `${this.municipalityLabel}-laadpalen-${getDateStamp()}`,
        types: {
          point: 'Laadpalen',
          polygon: 'polygons',
          line: 'lines'
        }
      })
    }
  }
}
</script>

<style lang="scss">
/**
 * This component combines the html & css of three layered admin components, 
 *  because it doesn't need any of the logic of those components
 * TODO: Fix html & CSS so that it makes sense as one component
 */
.UploadDashboard {
  &__Panel, &__Select {
    // width: calc(100% - 2rem);
    min-width: 1100px;
    max-width: 1100px;
  }
  &__Panel {
    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;
  }
  .vSelect {
    min-width: 300px;
  }
}
.ExportPanelWrapper {

  min-width: 1100px;
  max-width: 1100px;
  background: white;
  box-shadow: 0px 1px 3px 0px #dfe2e5;

  header {
    background: lighten(#081736, 0%); // darken(#E8EAF1, 25%);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
.ExportPanel {
  &__Aside {
    width: 275px;
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    width: 175px;
  }
  &__Input {
    position: relative;
    width: 360px;

    &:after {
      content: attr(data-intructions);
      position: absolute;
      top: 3.1rem;
      font-size: 1rem;
      left: 0;
      color: #7F8FA4;

    }
  }
  &__Download {
    text-decoration: underline;
    color: rgb(214, 82, 0);
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Selecteer';
  }
}
</style>