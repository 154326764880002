<template>
  <div class="ActiveTab__Header">
    <div class="ActiveTab__Eyebrow">
      Locatie ID: {{ `${chargingpoint.data.code}-${chargingpoint.data.properties.id}` }}
      <img :src="legendIcon" class="ActiveTab__Eyebrow__Icon" width="15" alt="">
    </div>

    <slot />
  </div>
</template>

<script>
import {image} from "@/helpers/assets";

export default {
  props: {
    chargingpoint: {
      type: Object,
      required: true
    }
  },
  computed: {
    legendIcon: function () {
      return image({
        name: `legend/chargingpoint-${this.chargingpoint.data.properties.status}.png`
      });
    }
  },
}
</script>