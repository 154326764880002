<template>
  <div v-if="! isLoadingFauna" class="Pages w-100 h-100 py-5 mh-100">
    <div class="Pages__Controls d-flex align-items-bottom">
      <div class="d-flex align-items-center m-3">
        <strong class="mr-2">Weergave:</strong>
        <InlineToggle 
          :options="[{label: 'Basis', value: 'basic'}, {label: 'Detail', value: 'detailed'}]" 
          @selected="handleSelected" />
      </div>
      <div class="d-flex align-items-center m-3">
        <strong class="mr-2">Model:</strong>
        <InlineToggle
          v-if="modelOptions.length > 0"
          :options="modelOptions"
          @selected="handleSelectedModel" />

        <span v-else> - </span>
      </div>
      <b-button variant="cta" class="PrintBtn m-3 ml-auto" @click="handlePrint" data-html2canvas-ignore>
        Opslaan als PDF
      </b-button>
    </div>

    <Page v-if="hasFactsheetData" :landscape="true" :marginless="true" class="PageOne" ref="factsheet">

      <h1 class="PageOne__Header">
        Factsheet {{ municipalityLabel }}
      </h1>

      <div class="PageOne__Main position-relative flex-grow-1">

        <!-- DEEL 1 -->
        <div class="PageOne__Bar">
          <div class="Bar__Icon">
            <img width="25" :src="image({ name: 'factsheet_auto.png' })" />
          </div>
          <div class="Bar__Title">
            Prognose elektrische auto's*
          </div>
          <div class="Bar__End">
            bewoners, forenzen &amp; bezoekers
          </div>
        </div>

        <div class="Main__Content d-flex justify-content-between">
          <div class="Circle pt-3 pb-3" style="width: 120px">
            <div class="Circle">
              <span>{{ registeredVehicles }}</span>
            </div>
            <div># geregistreerde</div>
            <div>personenauto's</div>
          </div>
          <div class="BarChart mb-1">
            <BarChart v-if="display === 'basic'" :data="barChart" />
            <DetailedBarChart v-else :data="barChartDetailed" />
          </div>
          <div class="Text flex-shrink-1 pt-3" style="width: 278px">
            <div>Verwachte aantal elektrische auto's die in</div>
            <div>uw gemeenten een (semi)publieke of private</div>
            <div>laadbehoefte kennen </div>
            <div>(bewoners, forenzen &amp; bezoekers)</div>
          </div>
        </div>


        <!-- DEEL 2 -->

        <div id="future-needs-charging-stations" class="PageOne__Bar">
          <div class="Bar__Icon">
            <img width="25" :src="image({ name: 'factsheet_laadpaal.png' })" />
          </div>
          <div class="Bar__Title">
            Toekomstige behoefte (semi) publieke laadpunten*
          </div>
        </div>

        <div class="Main__Content d-flex justify-content-between">
          <div class="Circle pt-5" style="width: 120px">
            <div class="Circle">
              <img width="25" :src="image({ name: 'factsheet_laadpaal.png' })" />
            </div>
            <div class="pt-3">
              <strong>Huidig aantal laadpunten**</strong>
            </div>
            <div>{{ currentPublicChargers }} publiek</div>
            <div>{{ currentSemiPublicChargers }} semi-publiek</div>
          </div>

          <div class="BarChart mb-1">
            <ColumnChart v-if="display === 'basic'" :data="columnChart" />
            <DetailedColumnChart v-else :data="columnChartDetailed" />
          </div>

          <div class="Text flex-shrink-1 pt-3" style="width: 278px">
            <div>Om uit te komen op de totale laadbehoefte is</div>
            <div class="pb-3">uitgegaan van het deelgebruik van het aantal elektrische voertuigen per <i>laadpunt</i>:</div>

            <table>
              <tr>
                <td></td>
                <td v-for="year in forecastYearNumbers" :key="`table-header-${year}`">
                  <strong> {{ year }} </strong>
                </td>
              </tr>
              <tr>
                <td><i>Bewoners</i></td>
                <td v-for="year in forecastYearNumbers" :key="`residents-${year}`">
                  1 op {{ chargingpointDualUsagePublicInhabitants[year] | roundedBase(display) }}
                </td>
              </tr>
              <tr>
                <td><i>Forenzen</i></td>
                <td v-for="year in forecastYearNumbers" :key="`commuters-${year}`">
                  1 op {{ chargingpointDualUsagePublicCommuters[year] | roundedBase(display) }}
                </td>
              </tr>
              <tr>
                <td><i>Bezoekers</i></td>
                <td v-for="year in forecastYearNumbers" :key="`visitors-${year}`">
                  1 op {{ chargingpointDualUsagePublicVisitors[year] | roundedBase(display) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        
        <!-- DEEL 3 -->

        <div class="PageOne__Bar">
          <div class="Bar__Icon">
            <img width="25" :src="image({ name: 'factsheet_parking.png' })" />
          </div>
          <div class="Bar__Title">
            Verdeling openbaar en privaat parkeren***
          </div>
        </div>

        <div class="Main__Content d-flex justify-content-between">
          <div class="pt-3 d-flex justify-content-center flex-column">
            <div>Parkeren in de</div>
            <div>openbare ruimte</div>
          </div>
          <div class="pt-3 d-flex justify-content-center flex-column">
            <div style="width: 14px; height: 14px; background: #3B547B"></div>
            <div class="pt-1">{{ donutChart[0][2] }}</div>
          </div>
          <DonutChart :data="donutChart" />
          <div class="pt-3 d-flex justify-content-center flex-column">
            <div style="width: 15px; height: 15px; background: #689AC5"></div>
            <div class="pt-1">{{ donutChart[1][2] }}</div>
          </div>
          <div class="pt-3 d-flex justify-content-center flex-column">
            <div>Eigen</div>
            <div>parkeer- </div>
            <div>voorziening</div>
          </div>
          <div class="Text flex-shrink-1 pt-3" style="width: 278px">
            <div>Dubbelgebruik op (semi)publieke laadpunten</div>
            <div>betekent dat deze verhouding zich niet</div>
            <div>direct vertaalt in de aantallen laadpunten </div>
            <div>hierboven</div>
          </div>
        </div>
      </div>

      <div class="PageOne__Footer mt-2 d-flex justify-content-between">
        <img class="Footer__Logo" :src="image({ name: 'evc-circle.png' })" />

        <div class="Footer__Text font-italic">
          <div>
            <div>* Op basis van NAL prognose van 1.9 miljoen EVs in 2030 (bron: {{bron}})</div>
            <div>** Huidig aantal laadpunten o.b.v meest recente data van Eco-Movement</div>
            <div>*** Op basis van EVTools parkeerareaal-analyse m.b.v. open databronnen zoals BGT en eigen algoritmes</div>
          </div>
        </div>

        <div class="Footer__Timestamp">
          Gegenereerd op: {{ timestamp }}
        </div>

        <img class="Footer__Background" :src="image({ name: 'factsheet_footer_background.png' })" />
      </div>
    </Page>
    <Page v-else :landscape="true">
      <h1 class="text-center pt-5">
        Er is voor {{ municipalityLabel }} nog geen factsheet beschikbaar
      </h1>
    </Page>
  </div>
  <div v-else class="w-100 d-flex align-items-center justify-content-center">
    <b-spinner class="mr-3" label="Loading..."></b-spinner>
    <span>Loading...</span>
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

import InlineToggle from '@/components/common/InlineToggle'
import Page from '@/components/factsheet/Page'
import BarChart from '@/components/factsheet/BarChart'
import DetailedBarChart from '@/components/factsheet/DetailedBarChart'
import ColumnChart from '@/components/factsheet/ColumnChart'
import DetailedColumnChart from '@/components/factsheet/DetailedColumnChart'
import DonutChart from '@/components/factsheet/DonutChart'

import {numberWithDots} from '@/helpers/number'

import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

import { labelByCode } from '@/services/municipalities'
import { mapMutations, mapGetters } from 'vuex'
import { checkStatus, returnJson } from "@/helpers/api";

export default {
  components: { InlineToggle, Page, BarChart, ColumnChart, DonutChart, DetailedBarChart, DetailedColumnChart },
  data() {
    return { 
      /**
       * The displayed factsheet layout. Either basic or detailed
       */
      display: 'basic',
      model: null,
      isLoadingFauna: false,
      data: null,
    }
  },
  filters: {
    roundedBase: function (value, display) {
      if (!value) return ''

      if (display === 'detailed') {
        return value.toFixed(1)
      }

      return Math.round(value)
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
      'getPrognoseModels'
    ]),
    modelOptions() {
      const availableModels = Object.keys(this.data?.factsheets || [])

      return this.getPrognoseModels
        .filter(model => availableModels.includes(model.toLowerCase()))
        .map(model => ({label: model, value: model.toLowerCase()}))
    },
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    forecastYearNumbers() {
      return [
        2022,
        2025,
        2030,
      ]
    },
    factsheetData() {
      return this.data?.factsheets?.[this.model] || false
    },
    timestamp() {
      return this.data.timestamp
    },
    chargingpointDualUsagePublicInhabitants() {
      return this.factsheetData.chargingpointDualUsage?.public.inhabitants
    },
    chargingpointDualUsagePublicVisitors() {
      return this.factsheetData.chargingpointDualUsage?.public.visitors
    },
    chargingpointDualUsagePublicCommuters() {
      return this.factsheetData.chargingpointDualUsage?.public.commuters
    },
    hasFactsheetData() {
      return !!this.factsheetData
    },
    registeredVehicles() {
      return numberWithDots(this.factsheetData.registeredVehicles)
    },
    currentPublicChargers() {
      return Math.ceil(this.factsheetData.currentChargingpoints?.public || 0)
    },
    currentSemiPublicChargers() {
      return Math.ceil(this.factsheetData.currentChargingpoints?.company || 0)
    },
    /**
     * # Of EV's per year (public & private combined)
     */
    barChart() {
      return this.factsheetData.evCountTotal
    },
    barChartDetailed() {
      return this.factsheetData.evCountPerGroup
    },
    columnChart() {
      return this.factsheetData.chargerCountTotal
    },
    columnChartDetailed() {
      return this.factsheetData.chargerCountPerGroup
    },
    donutChart() {
      let priv = this.factsheetData.parking.private
      let pub = this.factsheetData.parking.public

      return [ // % Parkingspace
        ['parkeren in de openbare ruimte', (pub/2), `${pub}%`],
        ['eigen perkaarvoorziening', (priv/2), `${priv}%`],
        [null, 50]
      ] 
    },
    bron() {
      const prognoses = {
        nal: 'NAL-prognoses',
        vl: 'VL-prognoses',
        sparkcity: 'SparkCity model van de TU Eindhoven',
      }

      return `RVO, ${prognoses[this.model.toLowerCase()]} en EVTools`
    }
  },
  watch: {
    getActiveMunicipality() {
      this.fetchFactsheetData()
    },
    modelOptions(next) {
      if (! next.length > 0) {
        return
      }

      this.model = next[0]?.value
    },
  },
  created() {
    this.fetchFactsheetData()
  },
  methods: {
    ...mapMutations('app', ['setAppReady']),
    image,
    /**
     * fetch the data
     */
    async fetchFactsheetData() {
      this.isLoadingFauna = true

      try {
        const token = await this.$auth.getTokenSilently()
        const response = await fetch('/api/factsheetload', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token
          },
          body: JSON.stringify({ code: this.getActiveMunicipality, deploymentCode: this.DeploymentCode })
        })
          .then(await checkStatus)
          .then(returnJson)

        this.data = response.data
      }
      catch (e) {
        console.log('Error: ', e)
      }

      this.setAppReady({ value: true })
      this.isLoadingFauna = false
    },
    /**
     * Handle the display selection
     */
    handleSelected({ selected }) {
      this.display = selected
    },
    /**
     * Handle the model selection
     */
    handleSelectedModel({ selected }) {
      this.model = selected
    },
    /**
     * Download the Factsheet as PDF
     */
    handlePrint() {
      html2canvas(this.$refs.factsheet.$el).then(canvas => {
        let context = canvas.getContext('2d')
        context['imageSmoothingEnabled'] = false; /* standard */
        context['mozImageSmoothingEnabled'] = false; /* Firefox */
        context['oImageSmoothingEnabled'] = false; /* Opera */
        context['webkitImageSmoothingEnabled'] = false; /* Safari */
        context['msImageSmoothingEnabled'] = false; /* IE */

        const doc = new jsPDF({
          orientation: 'landscape'
        })

        console.log(doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())

        doc.addImage(
          canvas, 
          'JPEG', 
          0, 
          0, 
          Math.floor(doc.internal.pageSize.getWidth()),  
          Math.floor(doc.internal.pageSize.getHeight())
        )
        let layout = this.display === 'basic' ? ' ' : '(detail) '
        doc.save(`Factsheet ${layout}- ${ this.municipalityLabel } - EVTools.pdf`)
      })
    }
  }
}
</script>

<style lang="scss">

/**
 * Measurements are taken from a powerpoint "design", which has a different aspect ratio.
 * The factors below are used to help convert pp measurements into pixels
 */
$height: 1.96;
$width: 1.56;
$aspect: (($width + $height) /2);

.PrintBtn {
  top: 1rem;
  right: 1rem;
}

.Pages {
  position: relative;
  overflow-y: scroll;

  &__Controls {
    width: 29.7cm;
    margin: auto;
  }
}

.PageOne {
  &__Header {
    text-align: center;
    margin-top: 14px * $height;
    line-height: 41px * $height;
    font-size: 28px * $aspect;
    color: #3B547B;
  }
  &__Main {
    width: 600px * $width;
    margin: 0 auto;
    color: #7F7F7F;

    .Main {
      &__Content {
        padding: 0 7px * $width;
      }
    }

    table {
      width: 100%;

      td {
        padding: 6px 0
      }
    }

    .Circle {
      text-align: center;

      .Circle {
        background: #689AC5;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        margin: 0 auto 3px;
      }

    }
  }
  &__Bar {
    height: 22px * $height;
    background: #3B547B;
    color: white;
    padding: 0 10px;

    display: flex;
    align-items: center;

    .Bar__Icon {
      width: 35px * $width;
      margin-left: 4px * $width;
      display: flex;
      align-items: center;
      font-size: 14px * $aspect;

      .SvgIcon {
        fill: white;
      }
    }
    .Bar__Title {
      flex-grow: 1;
    }
    .Bar__Title, .Bar__End {
      margin: 0;
      font-size: 11px * $aspect;
    }
  }
  &__Footer {
    position: relative;

    .Footer {

      &__Logo {
        position: absolute;
        left: 28px * $width;
        bottom: 16px * $height;
        width: 34px * $aspect; // Aspect ratio...
        height: 31px * $aspect; // Aspect ratio...
      }

      &__Background {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 357px; // * $aspect; // Aspect ratio... 
        height: 74px; // * $aspect; // Aspect ratio...
      }

      &__Text {
        position: absolute;
        z-index: 99999;
        bottom: 8px * $height;
        width: 100%;
        height: 25px * $height;
        font-size: 7px * $aspect;

        > div {
          width: 526px * $width;
          margin: 0 auto;
        }
      }

      &__Timestamp {
        position: absolute;
        z-index: 99999;
        right: 28px;
        bottom: 8px;
      }
    }
  }
}
</style>