<template>
  <div class="d-flex">

    <ScenarioInput 
      :disabled="isLimitedForcast"
      class="mr-3"
      scenario="model"
      :options="models" />
    <ScenarioInput 
      class="mr-3"
      scenario="year"
      :options="availableYears" />
    <ScenarioInput
      :disabled="isLimitedForcast"
      class="mr-3"
      scenario="access"
      :options="access"/>
    <ScenarioInput 
      class="mr-3"
      scenario="forecast"
      :options="availableForcastOptions" />

  </div>
</template>

<script>
import ScenarioInput from '@/components/navbar/ScenarioInput'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ScenarioInputs',
  components: { 
    ScenarioInput
  },
  computed: {
    /**
     * The scenario options for years & models are part of the municipality configuration 
     */
    ...mapGetters('config', [
      'years', 'models', 'forcastOptions'
    ]),
    ...mapGetters('scenarios', [
      'year', 'model', 'forecast'
    ]),
    /**
     * The scenario options for access & forecast are fixed
     */
    access() {
      return [
        {
          value: 'public',
          text: 'Publiek'
        },
        {
          value: 'private',
          text: 'Privaat'
        }
      ]
    },
    
    // For these forecast variations have no distinction between public / private or data models
    // TODO: Centralise into a config.
    isLimitedForcast() {
      return ['carsharing', 'logistics', 'taxis', 'fastcharge', 'gemeenteprognoses'].includes(this.forecast)
    },
    
    // TODO: Centralise into a config.
    availableYears() { 

      if (this.forecast === 'gemeenteprognoses') {
        return [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030].filter(year => this.years.includes(year))
      }
      // Fast charging forecase is available for these 3 years
      else if (this.forecast === 'fastcharge') {
        return [2025, 2030, 2035].filter(year => this.years.includes(year))
      }
      // Other limited forecast only get these 3 years, if made available in the config.
      else if (this.isLimitedForcast) { // 2022, 
        return [2022, 2025, 2030].filter(year => this.years.includes(year))
      }
      return this.years
    },

    /**
     * Only show the 'gemeenteprognoses' option to superusers
     */
    availableForcastOptions() {
      if (this.superuser) {
        return this.forcastOptions
      }
      return this.forcastOptions.filter(e => e !== 'gemeenteprognoses')
    },

    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  watch: {
    /**
     * Track changes to the scenario options that can change per municipality
     *  If the active option is no longer available, select the first available option
     */
    availableYears() {
      this.checkYearOptions()
    },
    models() {
      this.checkModelOptions()
    },
    availableForcastOptions() {
      this.checkForecastOptions()
    }
  },
  created() {
    this.checkYearOptions()
    this.checkModelOptions()
    this.checkForecastOptions()
  },
  methods: {
    ...mapMutations('scenarios', [
      'setYear', 'setModel', 'setForecast'
    ]),
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkYearOptions() {
      if (! this.availableYears.includes(this.year)) {
        this.setYear({
          scenario: this.availableYears[0] || null
        })
      }
    },
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkModelOptions() {
      if (! this.models.includes(this.model)) {
        this.setModel({
          scenario: this.models[0] || null
        })
      }
    },
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkForecastOptions() {
      if (! this.availableForcastOptions.includes(this.forecast)) {
        this.setForecast({
          scenario: this.availableForcastOptions[0].value || null
        })
      }
    }
  }
}
</script>