import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


// Bugfender
import { Bugfender } from '@bugfender/sdk';
try {
  Bugfender.init({
    appKey: process.env.VUE_APP_BUGFENDER_API_KEY,
    build: process.env.VUE_APP_BUGFENDER_BUILD || 'none'
  })
} catch(e) {
  console.log(e)
}

// Rollbar
// import Rollbar from 'rollbar';
// Vue.prototype.$rollbar = new Rollbar({
//   accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
//   captureUncaught: true,
//   captureUnhandledRejections: true,
// });

// Vue.config.errorHandler = (err, vm, info) => {
  
//   if (process.env.VUE_APP_ROLLBAR_ENV !== 'local') {
//     vm.$rollbar.error(err);
//   } else {
//     console.log(info)
//   }
//   throw err; // rethrow
// };


// Fonts (Gibson)
import "@/assets/sass/fonts.scss";

// Bootstrap config
import "@/assets/sass/bootstrap.scss";

// Polyfill Fetch
import 'whatwg-fetch'

// Bootstrap - TODO: Use Bootstrap treeshaking plugins 
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// Auth0 - TODO: Move plugin code to npm module
import { Auth0Plugin } from "./auth";
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

const v1customers = ['']
const subdomain = window.location.host.split('.')[2] ? window.location.host.split('.')[0] : false;
if (subdomain && v1customers.includes(subdomain)) {
  let hostWithoutSubdomain = window.location.host.split('.').slice(1).join('.')
  window.location.href = `${window.location.protocol}//${hostWithoutSubdomain}${window.location.pathname}`
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
