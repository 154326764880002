<template>
  <nav class="NavBar d-flex flex-row">
    <div class="NavBar__logo">
      <router-link :to="{ name: 'Home'}">
        <Logo organisation="EVTools" image="evtools.png" />
      </router-link>
    </div>
    <p class="NavBar__description align-self-center my-0 flex-grow-1">
      <span class="ml-2 d-flex">
        {{ pageTitle }}
      </span>
    </p>
    <div class="d-flex flex-row justify-content-end flex-grow-1 align-self-center align-items-center">

      <slot />

      <span 
        class="NavBar__Profile d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Profile--visible': showingProfile }"
        @click="showProfile">
        <SvgIcon icon="user-regular" :hasFill="true" />
      </span>

      <span 
        class="NavBar__Logout d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Menu--visible': showingMenu }"
        @click="showMenu">
        <SvgIcon icon="bars-regular" :hasFill="true" />
      </span>

    </div>
    <ProfileCard v-if="showingProfile" :style="{ right: profileCardOffset }" />
    <NavBarMenu v-if="showingMenu" :style="{ right: profileCardOffset }" />
  </nav>
</template>

<script>
import Logo from '@/components/navbar/Logo'
import SvgIcon from '@/components/common/SvgIcon'

import ProfileCard from '@/components/profile/ProfileCard'
import NavBarMenu from '@/components/common/NavBarMenu'

import { mapState } from 'vuex'

// import { getScrollbarWidth } from '@/helpers/dom'
import { EventBus } from '@/services/eventbus'

export default {
  name: 'NavBar',
  components: {
    Logo, SvgIcon, ProfileCard, NavBarMenu
  },
  data() {
    return {
      showingProfile: false,
      showingMenu: false
    }
  },
  computed: {
    ...mapState(['pageTitle']),
    /**
     * We need to avoid the profile card covering up the scrollbar of the main page content
     */
    profileCardOffset() {
      return '1.25em' // `${getScrollbarWidth()}px`
    }
  },
  watch: {
    showingProfile() {
      if (this.showingProfile) {
        // The busy state in the profile box decides whether hiding is allowed... 
        EventBus.$emit('overlay-show', {
          autohide: false
        })
      }
    },
    showingMenu() {
      if (this.showingMenu) {
        // The busy state in the profile box decides whether hiding is allowed... 
        EventBus.$emit('overlay-show', {
          autohide: false
        })
      }
    }
  },
  created() {
    EventBus.$on('overlay-hidden', this.closeProfile)
    EventBus.$on('overlay-hidden', this.closeMenu)
  },
  beforeDestroy() {
    EventBus.$off('overlay-hidden', this.closeProfile)
    EventBus.$off('overlay-hidden', this.closeMenu)
  },
  methods:{
    closeProfile() {
      this.showingProfile = false
    },
    showProfile() {
      this.showingProfile = true
    },
    closeMenu() {
      this.showingMenu = false
    },
    showMenu() {
      this.showingMenu = true
    }
  }
}
</script>

<style lang="scss">
.NavBar {
  position: relative;
  height: 80px;
  min-height: 80px;
  background: #081736; // white;
  box-shadow: 0px -1px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;

  &__logo {
    width: 240px;
    height: 100%;
    padding: 7px;

    @media (max-width: 1050px) {
      width: 200px;
    }
  }
  &__description {
    color: white;
    text-transform: uppercase;

    @media (max-width: 1200px) {
      span {
        display: none;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }
  }
  .form-group {
    margin-bottom: 0
  }
  .SvgIcon {
    font-size: 1.75rem;
    fill: white;
    cursor: pointer;

    &:hover {
      fill: darken(white, 20%);
    }
  }

  &__Profile--visible .SvgIcon {
    fill: rgb(214, 82, 0);
  }

  .ProfileCard, .NavBarMenu {
    position: absolute;
    top: calc(80px - 0.75rem);
    box-sizing: 0px 1px 3px 0px #dfe2e5;
  }
}
</style>
