<template>
  <div style="display:none"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MunicipalityPrognoseLayer',
  props: {
    loaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {
        name: 'gemeenteprognoses',
        prop: 'laadpunt_',
        idProp: 'gemeentecode'
      }
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentHasLayer',
      'DeploymentLayerName'
    ]),
    ...mapGetters('config', [
      'breakpoints'
    ]),
    ...mapGetters('scenarios', [
      'year', 'forecast'
    ]),
    ...mapGetters('prognose', [
      'getHighlighted', 'labelLayer'
    ]),
    relevantForcast() {
      return this.config.name === this.forecast
    },
    hasLayerData() {
      return this.DeploymentHasLayer({ layer: this.config.name })
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.config.name })
    },
    url() {
      return `mapbox://${this.MapboxAccount}.${this.sourceName}`
    }
  },
  watch: {
    loaded() {
      if (! this.$store.map.getLayer(this.config.name)) {
        this.addLayer()
      }
    },
    year() {
      this.updatePrognose()
    },
    getHighlighted() {
      this.updatePrognose()
    },
    forecast() {
      if (! this.relevantForcast) {
        if (this.$store.map.getLayer(this.config.name)) {
          this.removeLayer()
        }
      } else {
        if (! this.$store.map.getLayer(this.config.name)) {
          this.addLayer()
        } else {
          this.updatePrognose()
        }
      }
    },
    relevantForcast(relevant) {
      this.updateLabelStyle({ relevant })
    }
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  beforeDestroy() {
    if (this.loaded) {
      this.removeLayer()
    }
  },
  methods: {
    addLayer() {

      // Don't go any further if the layer details are missing
      // Or if the layer is not relevant to the selected forecast
      if (! this.hasLayerData) return
      if (! this.relevantForcast) return

      this.$store.map.addSource(this.config.name, {
        type: 'vector',
        url: this.url
      })
      this.$store.map.addLayer({ 
        "id": this.config.name,
        "type": "fill",
        "source": this.config.name,
        "source-layer": this.sourceName,
        "paint": {
          "fill-color": this.fillHexagons(),
          // Note: The legend opacity is linked
          "fill-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            5,
            0,
            5.2,
            0.7,
            13,
            0.4
          ],
          "fill-outline-color": "hsla(232, 67%, 60%, 1)"
        }
      }, this.labelLayer)

      this.updateLabelStyle({ relevant: true })
    },
    removeLayer() {
      if (this.$store.map.getLayer(this.config.name)) {
        this.$store.map.removeLayer(this.config.name)
        this.$store.map.removeSource(this.config.name)
      }
    },
    updatePrognose() {
      if (this.$store.map.getLayer(this.config.name)) {
        this.$store.map.setPaintProperty(this.config.name, 'fill-color', this.fillHexagons())
      }
    },
    updateLabelStyle({ relevant }) {
      let specs = [{
        type: 'paint',
        attr: 'text-color',
        active: "hsl(0, 0%, 95%)",
        inactive: [
          "step",
          ["get", "symbolrank"],
          "hsl(0, 0%, 35%)",
          18,
          "hsl(0, 0%, 46%)"
        ],
        layers: ['settlement-minor-label']
      }, {
        type: 'paint',
        attr: 'text-color',
        active: "hsl(0, 0%, 95%)",
        inactive: [
          "step",
          ["get", "symbolrank"],
          "hsl(185, 1%, 42%)",
          18,
          "hsl(185, 3%, 58%)"
        ],
        layers: [ 'settlement-major-label']
      }, {
        type: 'paint',
        attr: 'text-color',
        active: "hsl(0, 0%, 100%)",
        inactive: "hsl(0, 0%, 35%)",
        layers: ['settlement-subdivision-label']
      }, {
        type: 'layout',
        attr: 'text-font',
        active: [
          "DIN Pro Medium",
          "Arial Unicode MS Regular"
        ],
        inactive: [
          "DIN Pro Regular",
          "Arial Unicode MS Regular"
        ],
        layers: ['settlement-minor-label', 'settlement-major-label', 'settlement-subdivision-label']
      }, {
        type: 'paint',
        attr: 'text-halo-color',
        active: "hsl(0, 5%, 0%)",
        inactive: "hsl(185, 1%, 100%)", 
        layers: ['settlement-minor-label', 'settlement-major-label', 'settlement-subdivision-label']
      }, {
        type: 'paint',
        attr: 'text-halo-width',
        active: 1,
        inactive: 0.5,
        layers: ['settlement-minor-label', 'settlement-major-label', 'settlement-subdivision-label']
      }, {
        type: 'paint',
        attr: 'text-halo-blur',
        active: 1,
        inactive: 0.5,
        layers: ['settlement-minor-label', 'settlement-major-label']
      }]
      
      specs.forEach(spec => {
        let value = relevant ? spec.active : spec.inactive

        if (spec.type === 'paint') {
          spec.layers.forEach(name => {
            this.$store.map.setPaintProperty(name, spec.attr, value)
          })
        } else {
          spec.layers.forEach(name => {
            this.$store.map.setLayoutProperty(name, spec.attr, value)
          })
        }
      })
    },

    fillHexagons() {
      let value = ['get', `${this.config.prop}${this.year}`]

      let styleCondition = [
        "case",
      ]

      // The selected tiles
      this.getHighlighted.forEach(buurtcode => {
        styleCondition.push([
          "==",
          [
            "get",
            this.config.idProp
          ],
          buurtcode
        ])
        styleCondition.push("hsl(23, 100%, 42%)")
      })

      return styleCondition.concat([
        // The layer we want to ignore
        [
          "<",
          value,
          0.05
        ],
        "hsla(0, 0%, 0%, 0)",
        // 0 - 1
        [
          "<=",
          value,
          this.breakpoints[0]
        ],
        "hsl(213, 50%, 80%)",
        // 1+ - 2
        [
          "<=",
          value,
          this.breakpoints[1]
        ],
        "hsl(213, 65%, 55%)",
        // 2+ - 4
        [
          "<=",
          value,
          this.breakpoints[2]
        ],
        "hsl(213, 80%, 35%)",
        // 4+
        [
          ">",
          value,
          this.breakpoints[2]
        ],
        "hsl(213, 95%, 20%)",
        // Default (required by MapBox)
        "hsla(0, 0%, 0%, 0)"
      ])
    },
  }
}
</script>

<style>

</style>