<template>
  <div class="PasswordBox d-flex flex-column pt-3">
    <div class="px-4">
      <Feedback v-if="feedback.show" :feedback="feedback" />
      <form @submit="handleSubmit">
        <label class="PasswordBox__Label" for="new-password">
          Nieuw wachtwoord
        </label>
        
        <b-input 
          :disabled="busy" 
          class="PasswordBox__Input" 
          id="new-password" 
          v-model="password" />
      </form>
      <p class="PasswordBox__Instruction text-muted mt-2">
        Minimaal 8 tekens: letters, hoofdletters, cijfers en optioneel speciale karakters (e.g. !@#$%^&amp;*). Na het aanpassen moet u opnieuw inloggen.
      </p>
    </div>
    <b-button squared :disabled="busy" variant="cta" class="PasswordBox__Button mt-3"  @click="handleSubmit">
      Wachtwoord wijzigen
    </b-button>
  </div>
</template>

<script>

import Feedback from '@/components/form/Feedback'

export default {
  name: 'PasswordBox',
  components: { Feedback },
  data() {
    return {
      busy: false,

      password: '',
      feedback: {
        show: false,
        message: "",
        variant: 'danger'
      }
    }
  },
  watch: {
    busy(busy) {
      this.$emit('processing', { busy })
    }
  },
  methods: {
    handleSubmit: async function(e) {
      e.preventDefault()

      if (this.password === '') {
        this.feedback = { 
          show: true,
          message: 'Vul een wachtwoord in',
          variant: 'danger'
        }
        return
      }

      this.busy = true

      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/userpassword', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          password: this.password
        })
      });

      let response = await api.json()

      if (response.message === 'Success') {
        this.feedback = { 
          show: true,
          message: 'Geslaagd. U wordt doorverwezen in 3 seconden.',
          variant: 'success'
        }
      
        setTimeout(this.logout, 3000)
      }
      else {
        let message = 'Er ging iets mis. Neem a.u.b. contact op.'

        if (response.message === 'PasswordStrengthError: Password is too weak') {
          message = 'Dit wachtwoord is niet veilig genoeg'
        } else if (response.message === 'Blacklisted') {
          message = 'Dit wachtwoord is niet toegestaan'
        } else if (response.message === 'Password is required') {
          message = 'Vul een wachtwoord in'
        }

        this.feedback = { 
          show: true,
          message,
          variant: 'danger'
        }
        this.busy = false
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>

<style lang="scss">
.PasswordBox {
  color: #365167;
  background: #e1ebf4;

  .PasswordBox__Button {
    padding: 0.75rem;
  }
  .PasswordBox__Instruction {
    font-size: 0.9rem;
  }
}

</style>