<template>
  <div class="TC-Container__validation">
    <b-button
      variant="outline-secondary"
      size="sm"
      class="mr-1 no-outlines TC-Container__validation-approved"
      :class="{'checked': validationChoice === APPROVED}"
      @click="$emit('choice', APPROVED)"
    >
      <b-icon-check />
    </b-button>
    <b-button
      variant="outline-secondary"
      size="sm"
      class="mr-1 no-outlines TC-Container__validation-rejected"
      :class="{'checked': validationChoice === REJECTED}"
      @click="$emit('choice', REJECTED)"
    >
      <b-icon-x />
    </b-button>
  </div>
</template>

<script>
import {VALIDATION_VOTE} from "@/valueholders/enums";
import {BIconCheck, BIconX} from 'bootstrap-vue'

export default {
  components: {BIconCheck, BIconX},
  props: {
    validationChoice: {
      type: String,
      required: false,
      default: () => null,
    }
  },
  created() {
    this.APPROVED = VALIDATION_VOTE.APPROVED
    this.REJECTED = VALIDATION_VOTE.REJECTED
  }
}
</script>