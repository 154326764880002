import Vue from 'vue'
import Vuex from 'vuex'

import version from './version'
import config from './config'
import app from './app'
import layers from './layers'
import scenarios from './scenarios'
import access from './access'
import planmode from './planmode'
import monitoring from './monitoring'
import prognose from './prognose'
import tilesets from './tilesets'
import users from './users'
import deployment from './deployment'
import tours from './tours'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // The page title (page meta data)
    pageTitle: 'EV Maps',

    dropbox: null
  },
  mutations: {
    /**
     * Set the page title (page meta data)
     */
    setPageTitle(state, { title }) {
      state.pageTitle = title
    },
    /**
     * TODO: This is a temporary solution!
     */
    setDropbox(state, { token }) {
      state.dropbox = token
    }
  },
  actions: {
  },
  modules: {
    version,
    config,
    app,
    layers,
    scenarios,
    access,
    planmode,
    monitoring,
    prognose,
    tilesets,
    users,
    deployment,
    tours,
  }
})
