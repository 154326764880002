import {mapActions, mapGetters, mapMutations} from "vuex";

import {initiate, subscribe} from "@/services/pusher";
import {EventBus} from "@/services/eventbus";

import privilegesMixin from "@/mixins/common/privilegesMixin";
import {checkStatus, returnJson} from "@/helpers/api";

export default {
  mixins: [privilegesMixin],
  data() {
     return {
       channel: null,
       isLoadingFauna: false,
     }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('users', [
      'getUsersByCode',
    ]),
    statusOptions() {
      let options = [{
        value: 'realized',
        text: 'Gerealiseerd'
      }, {
        value: 'in-progress',
        text: 'In voorbereiding'
      }, {
        value: 'definitive',
        text: 'Gevalideerd'
      }, {
        value: 'suggestion',
        text: 'Voorgesteld'
      }, {
        value: 'rejected',
        text: 'Afgekeurd'
      }, {
        value: 'alert',
        text: 'Aandachtspunt'
      },
      // FASTCHARGER
      {
        value: 'fastcharger-realized',
        text: 'Gerealiseerde snellader'
      }, {
        value: 'fastcharger-definitive',
        text: 'Gevalideerde snellader'
      }, {
        value: 'fastcharger-suggestion',
        text: 'Voorgestelde snellader'
      }, {
        value: 'fastcharger-rejected',
        text: 'Afgekeurde snellader'
      },
      // CHARGING HUB
      {
        value: 'charging-hub-definitive',
        text: 'Gevalideerd laadplein'
      }, {
        value: 'charging-hub-suggestion',
        text: 'Voorgesteld laadplein'
      }, {
        value: 'charging-hub-rejected',
        text: 'Afgekeurd laadplein'
      }]

      // if (this.superuser) {
      options.push({
        value: 'realized-private',
        text: 'Gerealiseerd (semi-publiek)'
      })
      // }

      return options
    },
    usersByCode() {
      const usersByCode = this.getUsersByCode({code: this.getActiveMunicipality})

      if (! usersByCode) {
        return []
      }

      return usersByCode.sort((a, b) => a.name.localeCompare(b.name))
    },
    usersByCodeAsOptions() {
      return this.usersByCode.map((user) => ({value: user.user_id, text: user.email}))
    },
    beheerderOptions() {
      const deleteBeheerder = { value: null, text: 'geen beheerder' }
      return [deleteBeheerder, ...this.usersByCodeAsOptions]
    },
  },
  created: async function() {
    /* if not already done, fetch municipality users for validation / beheer options */
    if (this.usersByCode.length === 0) {
      console.log('no users fetched')
      await this.$_chargingpointEditMixin_getMunicipalityUsers()
    }
  },
  methods: {
    ...mapActions('planmode', ['addOrUpdateChargingPoint', 'deselectChargingPoint']),
    ...mapMutations('users', ['setUsersDataByCode']),
    $_chargingpointEditMixin_getMunicipalityUsers: async function() {
      console.log("getting users")

      if (! this.superuser) {
        console.log("no super user - can't get users")
        return
      }

      this.isLoadingFauna = true

      const token = await this.$auth.getTokenSilently()
      const result = await fetch('/api/userlist', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          code: this.getActiveMunicipality
        })
      })

      const { users } = await result.json()

      this.setUsersDataByCode({
        code: this.getActiveMunicipality, users
      })

      this.isLoadingFauna = false
    },
    $_chargingpointEditMixin_connectToPusher: async function() {
      const token = await this.$auth.getTokenSilently();
      initiate({ token })
      let { channel } = await subscribe({ channel: this.getActiveMunicipality })
      this.channel = channel
    },
    $_chargingpointEditMixin_exists: async function({ code, refId }) {
      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/chargingpointexists', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ code, refId }) // '1930'
      });

      return await api.json()
    },
    $_chargingpointEditMixin_save: async function(data) {
      this.isLoadingFauna = true
      this.error = false

      const token = await this.$auth.getTokenSilently();
      const response = await fetch('/api/chargingpointssave', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify(data) // '1930'
      })
        .then(await checkStatus)
        .then(returnJson)

      if (response.chargingpoint) {
        this.addOrUpdateChargingPoint({
          chargingpoint: response.chargingpoint
        })

        // TODO: Handle situation where channel is not (yet) available: show error?
        if (this.channel) {
          this.channel.trigger('client-chargingpoint-saved', {
            chargingpoint: response.chargingpoint
          })
        }
      }

      this.isLoadingFauna = false
    },

    $_chargingpointEditMixin_delete: async function({ code, ref }) {
      this.isLoadingFauna = true

      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/chargingpointsdelete', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ code, ref }) // '1930'
      });

      const response = await api.json()

      // TODO: Handle errors
      if (response.chargingpoint) {
        this.addOrUpdateChargingPoint({
          chargingpoint: response.chargingpoint
        })

        // TODO: Handle situation where channel is not (yet) available: show error?
        if (this.channel) {
          this.channel.trigger('client-chargingpoint-saved', {
            chargingpoint: response.chargingpoint
          })
        }
      }

      this.isLoadingFauna = false
      EventBus.$emit('deselect-chargingpoint')
    },
  }
}