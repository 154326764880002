<template>
  <transition name="sidebar" mode="out-in" v-on:after-enter="checkForScrollbar">
    <div
      v-if="isChargingpointSelected"
      class="ActionSidebar"
      :class="{ open: open }"
    >
      <div class="Tabs">
        <div
          id="info-tab-button"
          class="Tab"
          :class="activeTab === ACTION_SIDEBAR_TAB.INFO ? 'active' : null"
          @click="setActiveTab(ACTION_SIDEBAR_TAB.INFO)"
        >
          <SvgIcon icon="info-circle-regular" />
        </div>
        <div
          id="comments-tab-button"
          class="Tab"
          :class="activeTab === ACTION_SIDEBAR_TAB.OPMERKINGEN ? 'active' : null"
          @click="setActiveTab(ACTION_SIDEBAR_TAB.OPMERKINGEN)"
        >
          <SvgIcon icon="comments-solid" />
        </div>
        <div
          id="monitoring-tab-button"
          class="Tab"
          :class="activeTab === ACTION_SIDEBAR_TAB.MONITORING ? 'active' : null"
          @click="setActiveTab(ACTION_SIDEBAR_TAB.MONITORING)"
        >
          <SvgIcon icon="chart-bar-regular" />
        </div>

        <div
          class="Tab ActionSidebar__Collapse"
          v-if="isChargingpointSelected"
          @click="toggle"
        >
          <div
          class="ActionSidebar__Collapse__Icon"
          :class="{ rotated: open }"
          >
            <SvgIcon icon="chevron-left-solid"/>
          </div>
        </div>
      </div>

      <div
        class="ActiveTab__Close"
        :class="{hasScrollbar: this.hasScrollbar}"
        @click="close"
      >
        <SvgIcon icon="times-regular" />
      </div>

      <InfoTab v-if="activeTab === ACTION_SIDEBAR_TAB.INFO" />
      <OpmerkingenTab v-if="activeTab === ACTION_SIDEBAR_TAB.OPMERKINGEN" />
      <MonitoringTab v-if="activeTab === ACTION_SIDEBAR_TAB.MONITORING" />
    </div>
  </transition>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
import InfoTab from "@/components/map/sidebar/Tabs/InfoTab";
import MonitoringTab from "@/components/map/sidebar/Tabs/MonitoringTab";
import OpmerkingenTab from "@/components/map/sidebar/Tabs/OpmerkingenTab";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from '@/services/eventbus'
import { ACTION_SIDEBAR_TAB } from "@/valueholders/enums";

export default {
  name: "ActionSidebar",
  components: {OpmerkingenTab, MonitoringTab, InfoTab, SvgIcon},
  data() {
    return {
      open: false,
      hasScrollbar: false
    }
  },
  computed: {
    ...mapGetters('planmode', ['isChargingpointSelected', 'getSelectedChargingpoint']),
    ...mapGetters('app', {
      activeTab: 'getSelectedActionSidebarTab'
    }),
  },
  watch: {
    isChargingpointSelected() {
      this.toggle()
    },
    getSelectedChargingpoint() {
      this.checkForScrollbar()
    },
    activeTab() {
      this.checkForScrollbar()
    },
  },
  created() {
    // bind enum to be available in template
    this.ACTION_SIDEBAR_TAB = ACTION_SIDEBAR_TAB;
  },
  beforeDestroy() {
    // Disconnect the event handler from Mapbox
    this.$store.map.off('click', 'chargingpoints', this.handleClickMarkerEvent)
  },
  methods: {
    ...mapActions('app', ['selectTab']),
    toggle() {
      this.open = !this.open;
    },
    close() {
      this.setActiveTab(null);
      /* send signal to close popup and also deselect charging point, which closes the sidebar;
         by that closing is handled in one place (ChargerDetailsPopup & ChargerEditorPopup) */
      EventBus.$emit('deselect-chargingpoint')
    },
    setActiveTab(tabName) {
      this.selectTab({tabName})

      if (tabName !== null && this.open === false) {
        this.toggle()
      }
    },
    handleClickMarkerEvent() {
      this.setActiveTab(this.activeTab)
    },
    checkForScrollbar() {
      this.waitForPropagation(() => {
        const div = document.querySelector('.ActiveTab');

        if (div === null) {
          return
        }

        this.hasScrollbar = div.scrollHeight > div.clientHeight
      })
    },
    waitForPropagation(CallbackFn) {
      setTimeout(CallbackFn)
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

$padding: 12px;
$icon-size: 15px;

$bar-width: 40px;
$container-width: 330px;
$hidden-position: $container-width;
$bar-position: $container-width - $bar-width; // only the tabs are visible
$open-position: 0;

.ActionSidebar {
  position: relative;
  flex: 1 1 auto;
  width: $container-width;
  margin-bottom: 10px;

  background: white;
  display: flex;
  min-height: 0; // fix so that sidebar content doesn't push TileData out of flexbox container
  transition: margin-right .25s ease-out;
  margin-right: - $bar-position; // needs to be margin-right, since transform creates a new bounding box which encapsulates the lightbox

  &.open {
    margin-right: $open-position;
  }

  &__Collapse {
    margin-top: auto;

    &__Icon {
      display: inline-block;
      width: 10px;
      height: 25px;
      transform: rotate(0);
      transition: .25s transform ease-out;
      cursor: pointer;
      margin-left: 2px;
      color: map_get($theme-colors, "primary");

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .Tabs {
    width: $bar-width;
    display: flex;
    flex-direction: column;
    padding-top: ($padding / 2);
    border-right: 1px solid $border-color;
    z-index: 1;

    .svg-container {
      display: inline-block;
    }

    .Tab {
      cursor: pointer;
      font-size: $icon-size;
      padding-top: ($padding / 1.5);
      padding-bottom: ($padding / 1.5);
      padding-left: $padding;
      color: $text-muted;

      &:hover {
        background-color: $body-bg;
      }

      &.active {
        color: map_get($theme-colors, "primary");
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          right: -3px;
          top: (($icon-size + $padding) / 8);
          width: 3px;
          height: ($icon-size + $padding * 1.2);
          border-radius: 2px;
          border: 2px solid map_get($theme-colors, "primary");
        }
      }
    }
  }

  .ActiveTab {
    flex: 1 1 auto;
    z-index: 2;
    background: white;
    overflow-y: auto;

    &__Eyebrow {
      font-size: .95rem;
      color: $text-muted;

      &__Icon {
        margin-top: -3px;
      }
    }

    &__Header {
      padding: ($padding/1.2) $padding;
      position: relative;
      border-bottom: 1px solid $border-color;

      h4 {
        margin-bottom: 0;
        font-size: 1.3rem;
        margin-top: 5px;
        font-weight: 400;
      }
    }

    &__Close {
      position: absolute;
      z-index: 3;
      top: $padding;
      right: $padding;
      cursor: pointer;

      &.hasScrollbar {
        right: 28px;
      }
    }

    &__Content {
      padding: ($padding/1.2) $padding;
      overflow-x: hidden;
    }
  }
}

.sidebar-enter {
  margin-right: - $hidden-position !important;
}
.sidebar-enter-to {
  margin-right: $bar-position;
}
.sidebar-enter-active,
.sidebar-leave-active {
  transition: margin-right .25s ease-out;
}
.sidebar-leave-to {
  margin-right: - $hidden-position !important;
}
</style>