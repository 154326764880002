<template>
  <div class="Page--Home h-100 d-flex flex-column align-items-center justify-content-center">
    <b-card
      v-if="loaded"
      tag="article"
      :img-src="image"
      class="mt-5"
    >
      <b-card-text v-if="!loaded">
        <p class="mt-3 text-center">
          Loading...
        </p>
      </b-card-text>
      <b-card-text v-else-if="municipalities.length === 0" class="px-4 pb-4">
        <p class="mt-3">
          Beste {{ nickname }},
        </p>
        <p>
          Er zijn nog geen gebruikersrechten verbonden aan uw account.
        </p>
        <p>
          Neem contact op via <MailtoSupport /> om toegang te krijgen tot uw gemeente.
        </p>
      </b-card-text>
      <b-card-text v-else class="px-4 pb-4">
        <p class="mt-3 text-center">
          Kies een gemeente om verder te gaan
        </p>
        <div class="d-flex flex-column mt-4 align-items-center justify-content-center">
          <router-link
            v-for="municipality in municipalities"
            :key="municipality.slug"
            :to="municipalityRoute({ code: municipality.code, slug: municipality.slug })"
            class="mt-3">
            {{ municipality.label }}
          </router-link>
        </div>
      </b-card-text>

    </b-card>
    <a
      v-if="loaded"
      class="text-muted mt-3"
      style="cursor: pointer"
      @click="logout">
      Log out
    </a>
    <div v-else class="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
      <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
      <div class="d-flex align-items-center">
        <b-spinner class="mr-3" label="Loading..."></b-spinner>
        <span>Loading...</span>
      </div>
      <a
        class="text-muted mt-5"
        style="cursor: pointer"
        @click="logout">
        Log out
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MailtoSupport from "@/components/common/MailtoSupport";

import { municipalityByCode } from '@/services/municipalities'

import { image } from '@/helpers/assets'
import { capitalizeFirstLetter } from '@/helpers/string'

/**
 * TODO: Apply layout, styling, etc. In other words: make it pretty.
 */
export default {
  name: 'Home',
  components: {MailtoSupport},
  data() {
    return {
      image: image({ name: 'evtools-dark.png' }),
    }
  },
  computed: {
    ...mapGetters('access', [
      'municipalityCodeList',
      'hasAdminAccess'
    ]),
    ...mapGetters('config', [
      'loaded', 
      'isEnabledByCode',
      'isPrognoseEnabledByCode',
      'isUploadEnabledByCode',
      'hasLayers'
    ]),
    municipalities() {
      // Go through all municipalities the user has access to
      return this.municipalityCodeList
        // Remove municipalities from the list if the are entirely unavailable
        .filter(code => this.isEnabledByCode({ code }) || ( this.hasAdminAccess({ code }) && this.hasLayers({ code }) ))
        // Load the municipality meta data by code
        .map(code => {
          return municipalityByCode({ code })
        })
    },
    nickname() {
      return capitalizeFirstLetter(
        this.$auth.user.nickname
      )
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    }
  },
  watch: {
    loaded() {
      if (this.superuser || this.municipalities.length === 1) this.redirect()
    },
    municipalities() {
      if (this.superuser || this.municipalities.length === 1) this.redirect()
    }
  },
  created() {
    if (this.superuser || this.municipalities.length === 1) this.redirect()
  },
  methods: {
    redirect() {
      if (this.superuser) {
        this.$router.push({
          name: 'Dashboard'
        })
      }
      else {
        let code = this.municipalities[0].code
        if (this.isEnabledByCode({ code }) || this.hasAdminAccess({ code })) {
          this.$router.push(this.municipalityRoute({ 
            code: this.municipalities[0].code,
            slug: this.municipalities[0].slug
          }))
        }
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    municipalityRoute({ code, slug }) {
      if (this.isPrognoseEnabledByCode({ code }) || (this.hasAdminAccess({ code }) && this.hasLayers({ code })) ) { 
        return {name: 'Viewer', params: { municipality: slug } }
      } else {
        return {name: 'Upload', params: { municipality: slug } }
      }
    }
  }
}
</script>
