
/**
 * Filter an array down to unique values by accepting only the first iteration of it's values
 */
export const unique = ({ arr }) => arr.filter((item, index) => arr.indexOf(item) === index)

/**
 * Remove a value from the array
 */
export const removeValue = ({ arr, value }) => { 
  let index = arr.indexOf(value); if (index !== -1) arr.splice(index, 1); return arr
}

export const chunk = ({ arr, size }) => (
  arr.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(arr.slice(i, i + size))
    return acc
  }, [])
)
