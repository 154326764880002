<template>
  <div class="TileData__Content">
    <div class="TileData__title d-flex">
      <span class="pt-3 pb-2">
        PROGNOSE -
        <span class="TileData__highlighted">{{ year }}</span> -
        <span class="TileData__highlighted">{{ forecastLabel }}</span>
      </span>
    </div>
    <div v-if="fids.length !== 0">
      <table v-if="forecast !== 'gemeenteprognoses'">
        <tbody>
          <tr>
            <td class="d-block mr-4"></td>
            <td class="mr-4" v-if="forecast !== 'fastcharge'">
              EV's
            </td>
            <td class="mr-4">
              Laadpunten
            </td>
          </tr>
          <tr v-for="set in dataset" :key="set">
            <td class="d-block mr-4">
              <strong style="text-transform: capitalize">
                {{ set.replace('_', ' ') }}
              </strong>
            </td>
            <td v-if="forecast !== 'fastcharge'" class="pr-4">
              {{ showData({ field: `${set}_ev_${year}` }) }}
            </td>
            <td class="pr-4">
              {{ showData({ field: ['fastcharge'].includes(forecast) ? `laadpunt_${year}` : `${set}_laadpunt_${year}` }) }}
            </td>
          </tr>
        </tbody>
      </table>

      <template v-if="forecast === 'gemeenteprognoses'">
        <table>
          <tbody>
            <tr>
              <td class="d-block mr-4">
                Gemeente
              </td>
              <td>
                {{ showStringData({ field: `gemeentenaam` }) }}
              </td>
            </tr>

            <!-- <tr>
              <td class="d-block mr-4">
                Gemeente code
              </td>
              <td>
                {{ showStringData({ field: `gemeentecode` }) }}
              </td>
            </tr> -->

            <tr>
              <td class="d-block mr-4">
                EV's
              </td>
              <td>
                {{ showData({ field: `ev_${year}`, decimals: 0 }) }}
              </td>
            </tr>
<!-- 
            <tr>
              <td class="d-block mr-4">
                Auto's
              </td>
              <td>
                {{ showData({ field: `aantal_wagens` }) }}
              </td>
            </tr> -->

            <tr>
              <td class="d-block mr-4">
                CPE
              </td>
              <td>
                {{ showData({ field: `laadpunt_${year}`, decimals: 0 }) }}
              </td>
            </tr>

            <!-- <tr>
              <td class="d-block mr-4">
                EV's
              </td>
              <td>
                {{ showData({ field: `ev_${year}` }) }}
              </td>
            </tr> -->
          </tbody>
        </table>    
      </template>

      <div v-if="fids.length === 1" class="mt-3 text-muted">
        ID: {{ fids[0] }}
      </div>
      <div v-else class="mt-2 text-muted">
        Meerdere vlakken
      </div>
    </div>
    <div v-else>
      Klik op een vlak om de prognose data te bekijken. Houd de CTRL toets in om meerdere vlakken te selecteren.
    </div>
  </div>
</template>

<script>

import { provincieCodeByMunicipalityCode } from '@/services/municipalities'

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NeighborhoodData',
  props: {
    loaded: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      // This data is obtained from the prognose layer on click
      
      Bezoekers: null,
      Forenzen: null,
      Pubparkeer: null,

      fids: [],

      dataById: {},

      layerNames: ['fastcharge', 'carsharing', 'logistics', 'taxis', 'gemeenteprognoses']
    }
  },
  computed: {
    ...mapGetters('prognose', [
      'getHighlighted'
    ]),
    ...mapGetters('config', {
      configLimit: 'limit'
    }),
    ...mapGetters('scenarios', [
      'year', 'forecast'
    ]),
    ...mapGetters('planmode', {
      isPlanModeActive: 'isActive'
    }),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess'
    ]),
    limit() {
      return parseInt(this.configLimit, 10) * this.fids.length
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.superuser || 
        this.hasAdminAccess({ code: this.getActiveMunicipality }) || 
        provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality }) === 28
    },
    // Access label
    forecastLabel() {
      switch(this.forecast) {
        case 'fastcharge': 
          return 'Snelladers'
        case 'carsharing':
          return "Deelauto's"
        case 'logistics': 
          return 'Logistiek'
        case 'taxis':
          return "Taxi's"
        case 'gemeenteprognoses':
          return 'Gemeente'
      }
      return ''
    },

    dataset() {
      switch(this.forecast) {
        case 'fastcharge': 
          return ["laadpunt"]
        case 'carsharing':
          return ["deelauto"]
        case 'logistics': 
          return ['logistiek_totaal', 'bouw', 'detailhandel', 'post']
        case 'taxis':
          return ['taxi']
        case 'gemeenteprognoses':
          return ['']
      }
      return []
    },
    idProp() {
      switch(this.forecast) {
        case 'gemeenteprognoses':
          return 'gemeentecode'
      }

      return 'buurtcode'
    },

    layerName() {
      return this.forecast
    }
  },
  watch: {
    loaded() {
      if (this.layerName) {
        this.activate({ layerName: this.layerName })
      }
    },
    hasAccess() {
      if (this.hasAccess) {
        if (this.layerName) {
          this.activate({ layerName: this.layerName })
        }
      } else {
        this.disable()
      }
    },
    /**
     * Clear the selected tile when the municipality changes
     */
    getActiveMunicipality() {
      this.fids = []
    },
    layerName() {
      this.fids = []

      if (this.layerName) {
        this.activate({ layerName: this.layerName })
      }
    },
    fids() {
      this.setHighlighted({ fids: this.fids })
    }
  },
  created() {
    if (this.layerName) {
      this.activate({ layerName: this.layerName })
    }
  },
  beforeDestroy() {
    this.disable()
  },
  methods: {
    ...mapMutations('prognose', [
      'setHighlighted'
    ]),
    disable() {
      if (! this.$store.map) return

      this.layerNames.forEach(layerName => {
        this.$store.map.off('click', layerName, this.handleClick)
        this.$store.map.off('contextmenu', layerName, this.handleContext)
        this.$store.map.off('mouseenter', layerName, this.mouseEnter);
        this.$store.map.off('mouseleave', layerName, this.mouseLeave);
      })
    },
    activate({ layerName }) {
      this.disable()
      
      if (!this.loaded) return 

      this.$store.map.on('click', layerName, this.handleClick)
      // TODO: Figure out a fix to avoid this
      if (navigator.appVersion.indexOf("Win") === -1) { // OSX
        this.$store.map.on('contextmenu', layerName, this.handleContext)
      }
      
      // Cursor on hover
      this.$store.map.on('mouseenter', layerName, this.mouseEnter);
      this.$store.map.on('mouseleave', layerName, this.mouseLeave);
    },
    showData({ field, decimals }) {
      decimals = ! decimals && decimals !== 0 ? 2 : decimals
      
      return this.fids.reduce((total, fid) => {
        return total + parseFloat(this.dataById[fid][field] || 0, 10)
      }, 0).toFixed( decimals )
    },
    showStringData({ field, max }) {
      if (Object.keys(this.fids).length > (max || 3)) return 'Meerdere'

      return this.fids.reduce((combined, fid) => {
        combined.push(this.dataById[fid][field])
        return combined
      }, []).join(', ')
    },
    oneDecimal(num) {
      return (Math.round((num * 10)) / 10).toFixed(1)
    },
    atLimit(num) {
      return num === this.limit
    },
    applyLimit(num) {
      return num > this.limit ? this.limit : num
    },
    handleClick(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return 
      }

      if (
        (e.originalEvent.ctrlKey || e.originalEvent.button == 2) && 
        navigator.appVersion.indexOf("Win") === -1 // Continue on Windows OS
      ) {
        return 
      }

      this.selectTile(e)
    },
    handleContext(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return 
      }

      if ( ! (e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        return 
      }

      this.selectTile(e)
    },
    selectTile(e) {
      let props = e.features[0].properties

      let idProp = this.idProp

      if (! props[idProp]) return

      // Holding CTRL
      if ((e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
      
        // Obtain the data from the prognose layer
        if (! this.fids.includes(props[idProp])) {
          this.dataById[props[idProp]] = props
          this.fids.push(props[idProp])

        } else {
          let index = this.fids.indexOf(props[idProp])
          this.fids.splice(index, 1);
          delete this.dataById[props[idProp]]
        }
      } else {
        if (! this.fids.includes(props[idProp]) || this.fids.length > 1) {
          // Not emptying data object, as that would lead to computation errors
          this.dataById[props[idProp]] = props
          this.fids = [ props[idProp] ]
        } else {
          this.fids = []
          this.dataById = {}
        }
      }
    },
    mouseEnter() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeave() {
      this.$store.map.getCanvas().style.cursor = ''
    }
  }
}
</script>