<template>
  <div class="UsersImportPanel d-flex flex-column">
    <div>
      <Feedback :feedback="{ show: true }" :dismissible="false">
        Upload een excel met per regel de volgende informatie: <br/> 
        <span class="ml-3">Gemeente naam, Gebruikersnaam, Email, Wachtwoord, Gebruikersrol</span>
        <br/><br/>
        De eerste regel wordt genegeerd. De mogelijke gebruikersrollen zijn: Medewerker, Beheerder of Gast
      </Feedback>
      <b-form-file
        v-model="excelFile"
        :disabled="busy"
        accept=".xlsx"
        placeholder="Selecteer of sleep een bestand..."
        drop-placeholder="Sleep hier heen..."
      ></b-form-file>
      <Feedback v-if="feedback" :feedback="feedback" class="mt-3" />
    </div>
    <div class="mt-5">

      <div 
        v-for="(user, index) in users" :key="index" 
        class="UsersImportPanel__User mt-3 d-flex">
        <div class="UsersImportPanel__Status mr-4" :class="{
          'Status__Busy': user.status === 'busy',
          'Status__Hold': user.status === 'waiting',
          'Status__Error': user.status === 'error',
          'Status__Done': user.status === 'done'
        }">
          <SvgIcon :id="`icon-${index}`" :icon="statusIcon({ status: user.status })" /> 
          <b-tooltip v-if="user.error" :target="`icon-${index}`" triggers="hover">
            {{ user.error }}
          </b-tooltip>
        </div>
        <div class="UsersImportPanel__Municipality mr-3">
          {{ user.municipalityLabel }}
        </div>
        <div class="UsersImportPanel__Name mr-3">
          {{ user.name }}
        </div>
        <div class="UsersImportPanel__Email mr-3">
          {{ user.email }}
        </div>
        <div class="UsersImportPanel__Password mr-3">
          {{ user.password }}
        </div>
        <div class="UsersImportPanel__Role">
          {{ user.roleLabel }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Excel from 'exceljs'
import slugify from 'slugify'

import SvgIcon from '@/components/common/SvgIcon'
import Feedback from '@/components/form/Feedback'

import { codeBySlug, labelByCode } from '@/services/municipalities'
import { mapGetters, mapMutations } from 'vuex'
import { configGenerator } from '@/services/configGenerator'

export default {
  name: 'UsersImportPanel',
  components: { SvgIcon, Feedback },
  data() {
    return {
      feedback: false,
      busy: false,
      excelFile: null,
      users: []
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentCode'
    ]),
    ...mapGetters('config', [
      'getConfigByCode',
      'models'
    ]),
    ...mapGetters('tilesets', [
      'tilesets'
    ])
  },
  watch: {
    excelFile() {
      this.readExcelFile()
    }
  },
  methods: {
    ...mapMutations('config',[
      'updateOrAddConfig'
    ]),
    statusIcon({ status }) {
      switch(status.toLowerCase()) {
        case 'busy': 
          return 'spinner-third-regular'
        case 'error':
          return 'exclamation-circle-regular'
        case 'waiting':
          return 'pause-circle-regular'
        case 'done':
          return 'check-circle-regular'
      }
      return 'question-circle-regular'
    },
    readExcelFile: async function() {
      
      this.busy = true
      
      /**
       * Process uploaded file
       */
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(this.excelFile);
      const worksheet = workbook.worksheets[0]

      /**
       * prep result
       */
      const users = []

      /**
       * possible roles
       */
      const roles = {
        gast: 'guest',
        medewerker: 'editor',
        beheerder: 'admin'
      }

      /**
       * Go through the excel row by row
       */
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return // skip the header

        // {1: gemeente, 2: naam, 3: email, 4: (email2), 5: wachtwoord, 6: rol}
        let values = row.values

        let slug = slugify(values[1] || '', { lower: true, strict: true })
        let code = codeBySlug({ slug })

        let municipalityLabel = code ? labelByCode({ code }) : (values[1] || '')

        /**
         * (Try to) Process excel cell value to plain text
         */
        const getValueText = ({ value }) => {
          if (value.text) {
            if (value.text.richText) {
              return value.text.richText[0].text
            }
            return value.text
          }
          if (value.richText) {
            return value.richText[0].text
          }
          return value
        }

        // Map data, with various fallbacks for missing data
        // TODO: Validate input properly
        users.push({
          id: rowNumber,
          status: code === false ? 'error' : 'waiting',
          error: code === false ? 'Er was geen match op de gemeente naam' : '',
          municipalityLabel,
          name: (values[2] || `Gemeente ${municipalityLabel}`).trim(),
          email: (values[3] ? getValueText({ value: values[3] }) : '').trim(),
          password: (values[4] ? getValueText({ value: values[4] }) : '').trim(),
          code: code,
          slug: slug,
          role: roles[(values[5]||'nope').toLowerCase()] || 'guest',
          roleLabel: roles[(values[5]||'nope').toLowerCase()] ? values[5] : 'Gast'
        })
      })
      
      this.users = users

      let errors = users.filter(user => user.status === 'error').length

      this.feedback = {
        show: true,
        message: `${errors} van de ${users.length} regels uit de excel worden genegeerd.`,
        variant: errors ? 'danger' : 'info'
      }

      // Automatically start creating users
      this.createUsers()
    },
    /**
     * Go through all available users, and start creating them in our user pool
     */
    createUsers() {
      // Ignore entries with errors
      let users = this.users.filter(user => user.status !== 'error')

      // Prep the user data
      users = users.map((user, index) => this.generateUserPayload({ 
        user, 
        next: index + 1 
      }))

      let halt = false
      let counter = users.length
      
      const createUser = async function({ vm, user, code }) {

        if (halt) return
        
        const token = await vm.$auth.getTokenSilently()

        // Update the current & next icon to show progress
        user.user.status = 'busy'
        if (users[user.next]) {
          users[user.next].status = 'busy'
        } 

        /**
         * Add the User to Auth0
         */
        const result = await fetch('/api/usercreate', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token
          },
          body: JSON.stringify({
            code,
            user: user.payload
          })
        })

        let response = await result.json()

        // TODO: Implement additional error handling
        if (response.error_description) {
          user.user.status = 'error'

          // Auth0 has a password check.
          if (response.error_description === 'PasswordStrengthError: Password is too weak') {
            user.user.error = 'Het wachtwoord is te zwak en daarom afgekeurd.'
          } else {
            user.user.error = response.error_description
          }
        } else {
           /**
           * Make sure the municipality config is enabled
           */
          if (! vm.getConfigByCode({ code })) {
            let config = configGenerator({ deployment: vm.DeploymentCode, code, tilesets: vm.tilesets, models: vm.models })
            
            delete config['stored'] // stored status flag should not be stored

            const configSaveResult = await fetch('/api/configsave', {
              method: 'POST',
              headers: {
                authorization: 'Bearer ' + token
              },
              body: JSON.stringify(config)
            })

            // Update the local configuration store
            let response = await configSaveResult.json()
            if (response.config) {
              vm.updateOrAddConfig({
                config: response.config
              })
              user.user.status = 'done'
            } else {
              user.user.status = 'error'
              user.user.error = 'De gebruiker is geregistreerd, maar het aanmaken van de gemeente configuratie is mislukt'
            }

          } else {
            user.user.status = 'done'
          }
        }

        counter--
        console.log(counter)
        if (counter <= 1) {
          
          let successCount = vm.users.filter(user => user.status === 'done').length
          vm.feedback = {
            show: true,
            message: `${successCount || '0'} van de ${vm.users.length} regels uit de excel zijn omgezet in geregistreerde gebruikers. Beweeg de muis over de foutmelding iconen om per geval de oorzaak van het probleem te zien.`,
            variant: successCount !== vm.users.length ? 'danger' : 'success'
          }
          vm.busy = false
        }
      }

      /**
       * Go through the user list. For each user, add a 250ms delay to the next
       */
      let vm = this
      users.forEach((user, index) => {
        setTimeout(function(){ 
          createUser({
            vm: vm,
            user: users[index], 
            code: users[index].user.code
          })
        }, index * 2000)
      })
    },
    generateUserPayload({ user, next }) {
      let roles = ['guest', 'editor', 'admin'].map(role => {
        return {
          role,
          municipalities: user.role === role ? [ user.code ] : []
        }
      })

      return {
        id: user.id, // rowNumber
        next, // index of next user
        user: user,
        payload: {
          name: user.name,
          email: user.email,
          password: user.password,
          app_metadata: {
            evmaps: {
              logins: 0,
              superuser: false,
              roles
            }
          }
        }
      }
    }
  }
}
</script>


<style lang="scss">
.UsersImportPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) { 
      width: 275px;
    }
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 175px;
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  // column widths
  &__Status {
    width: 70px;
  }
  &__Municipality {
    width: 150px;
  }
  &__Name {
    flex-grow: 1;
  }
  &__Email {
    width: 275px;
  }
  &__Password {
    width: 150px;
    overflow: hidden;
  }
  &__Role {
    width: 100px;
  }

  .Status {
    &__Error {
      color: #FF4E4E;
    }
    &__Done {
      color: #29CC8B;
    }
    &__Busy svg {
      animation: rotate360 linear 1.0s infinite ;
    }
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>