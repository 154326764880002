<template>
  <CollapsableSegment :label="label">

    <template v-slot:header>
      <ToggleSwitch class="m-0" v-model="all" />
    </template>

    <ul class="m-0 p-0 mb-3 list-unstyled">
      <LegendItem     
        v-for="layer in activeLayers" 
        :key="layer.id"
        :layer="layer"
        v-model="layer.visible" />
    </ul>

  </CollapsableSegment>
</template>

<script>

import CollapsableSegment from '@/components/common/CollapsableSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import LegendItem from '@/components/sidebar/LegendItem'

import { planmodeLayers } from '@/data/layerDetails'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LegendPlanmode',
  components: {
    CollapsableSegment, ToggleSwitch, LegendItem
  },
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // Make a copy of the dataset, 1 level deep
      layers: planmodeLayers.slice().map(o => Object.assign({}, o)) 
    }
  },
  computed: {
    ...mapGetters('config', [
      'isPlanmodeEnabled'
    ]),
    ...mapGetters('prognose', [
      'ready'
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'canPreAccessPlanMode'
    ]),
    
    /**
     * Watch the all layers on / off toggle.
     */
    all: {
      get() {
        return ! this.activeLayers.some(layer => !layer.visible)
      },
      set(value) {
        this.activeLayers.forEach(layer => layer.visible = value)
      }
    },
    /**
     * Not all layers will be available for each municipality
     */
    activeLayers() {
      return this.layers.filter(layer => layer.status !== 'hidden')
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  watch: {
    isPlanmodeEnabled() {
      this.activateLayers()
      this.setActiveLayersVisibility()
    },
    // Handle toggling of layers
    // TODO: Only trigger toggle method for layers that changed
    activeLayers: {
      handler: function() {
        this.setActiveLayersVisibility() 
      },
      deep: true
    },
    ready() {
      if (this.ready) {
        this.setActiveLayersVisibility()
      }
    }
  },
  created() {
    this.activateLayers()  
    // this.setActiveLayersVisibility()
  },
  methods: {
    ...mapMutations('planmode', [
      'storeActiveLayerVisibility'
    ]),
    activateLayers() {
      this.layers.forEach(layer => {
        // Only show realized locations if the planmode feature is disabled
        if (! this.isPlanmodeEnabled && ! this.superuser && ! this.canPreAccessPlanMode) {
          layer.status = [
            'chargingpoints-realized', 
            'chargingpoints-in-progress', 
            'chargingpoints-realized-private',
            'chargingpoints-fastcharger-realized'
          ].includes(layer.id) ? layer.status : 'hidden'
        }

        // if (! this.superuser && ! ['0335', '1783'].includes(this.getActiveMunicipality)) {
          // layer.status = [
          //   'chargingpoints-fastcharger-realized',
          //   'chargingpoints-fastcharger-planned'
          // ].includes(layer.id) ? 'hidden' : layer.status
        // }
        // // (for now) only superusers have access to private chargers
        // if (!this.superuser && layer.id === 'chargingpoints-realized-private') {
        //   layer.status = 'hidden'
        // }
        // layer.visible = layer.status === 'primary'
      })
    },
    setActiveLayersVisibility() {
      this.storeActiveLayerVisibility({
        layers: this.activeLayers
      })
    },
  }
}
</script>