<template>
  <div v-if="show">
    <b-modal
      :id="tourName"
      title="Nieuwe mogelijkheden"
      :hide-footer="true"
      :centered="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      modal-class="modal-release-notes"
      @close="handleSkipTour"
    >
      <p>Graag informeren we je over een aantal nieuwe functionaliteiten die zijn toegevoegd aan Maps:</p>

      <ul>
        <li>Nieuwe sidepanel aan de rechterzijde</li>
        <li v-if="DeploymentCode === 'nl'">Verbeterde luchtfoto laag</li>
        <li>Gestructureerd valideren</li>
      </ul>

      <p>
        Feedback, suggesties en vragen zijn welkom op  <MailtoSupport />.
      </p>
      <b-button variant="logo" @click="takeTheTour">Ontdek wat er nieuw is</b-button>
    </b-modal>

    <Tour :begin="begin" :tour-name='tourName' :steps="steps" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tour from "@/components/tour/Tour";
import MailtoSupport from "@/components/common/MailtoSupport";

import {image} from '@/helpers/assets'

import {ACTION_SIDEBAR_TAB, CHARGINGPOINT_STATUS} from "@/valueholders/enums";
import {hasSeenTour} from "@/valueholders/constants";

const TIME_TO_OPEN_CLOSE_SIDEBAR = 500;
const TIME_TO_CLOSE_MODAL = 250;

export default {
  name: 'StructuredValidation',
  components: {MailtoSupport, Tour },
  computed: {
    ...mapGetters('planmode', [
      'getChargingPoints',
      'isChargingpointSelected',
    ]),
    ...mapGetters('app', [
      'isAppReady',
      'getSelectedActionSidebarTab',
    ]),
    ...mapGetters('tours', [
      'hasUserSeenThisTour',
    ]),
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),
  },
  data () {
    return {
      iconChargingpointRejected: null,
      show: false,
      begin: false,
      version: '1.0',
      tourName: 'tour-structured-validation',
      steps: []
    }
  },
  watch: {
    isAppReady(next) {
      if (next) {
        this.init()
      }
    },
  },
  methods: {
    ...mapActions('tours', ['hideTour']),
    ...mapActions('planmode', ['selectChargingPoint', 'deselectChargingPoint']),
    ...mapActions('app', ['selectTab']),
    init() {
      {
        if (this.$router.currentRoute.name !== 'Viewer') { return }

        // until we set the data in auth0, we use cookies
        const localStoreValue = localStorage.getItem(this.tourName)

        if (localStoreValue === hasSeenTour) {
          return
        }

        /*
        if (this.hasUserSeenThisTour({tourName: this.tourName, version: this.version})) {
          return
        }
        */

        // preparations, we first have to load stuff into vue, i.e. images etc. that we later want to use
        this.iconChargingpointRejected = image({ name: 'legend/chargingpoint-rejected.png' });

        // after that we can load the steps, which now will be able to use loaded images etc.
        this.loadSteps();

        this.show = true
        this.$nextTick(()=>this.$bvModal.show(this.tourName))
      }
    },
    takeTheTour() {
      this.$bvModal.hide(this.tourName)

      // wait until modal is closed
      setTimeout(() => {
        this.selectAnChargingpointAndWaitForAnimationToEnd({ CallbackFn: this.startTour })
      }, TIME_TO_CLOSE_MODAL)
    },
    startTour() {
      if (! (this.steps.length > 0)) {
        return
      }

      this.begin = true
    },
    selectAnChargingpointAndWaitForAnimationToEnd({CallbackFn}){
      let firstChargingpoint = this.getChargingPoints.length > 0 ? this.getChargingPoints[0] : null
      let realizedChargingpoints = this.getChargingPoints.filter(chargingpoint => chargingpoint.data.properties.status === CHARGINGPOINT_STATUS.REALIZED)

      // todo: make fake chargingpoint data and insert if no chargingpoints?
      // choose realized over other statuses since we can show off demo monitoring data in here
      if (realizedChargingpoints.length > 0) {
        firstChargingpoint = realizedChargingpoints[0]
      }

      if (firstChargingpoint === null) {
        console.log('GEEN LAADPALEN')
        return
      }

      this.selectChargingPoint({ refId: firstChargingpoint.ref['@ref'].id })

      // wait until sidebar is open
      setTimeout(() => {
        CallbackFn()
      }, TIME_TO_OPEN_CLOSE_SIDEBAR)
    },
    loadSteps() {
      let steps = [
        {
          target: '.ActionSidebar',
          header: {
            title: 'Nieuw sidepanel',
          },
          content: `
              <p>Bij het aanklikken van een locatie op de kaart kun je in de rechter side-panel terecht voor informatie, reacties en monitoring.</p>
            `,
          customOptions: {
            controls: true,
          },
          params: {
            placement: 'right-start',
            highlight: true,
          }
        },
        {
          target: '#info-tab-button',
          header: {
            title: 'Nieuw sidepanel',
          },
          content: `
              <p>Hier vind je informatie zoals het adres en de coördinaten, deze kan je ook aanpassen in planmodus.</p>
            `,
          customOptions: {
            controls: true,
          },
          params: {
            placement: 'left-start',
          },
          before: () => new Promise((resolve, reject) => {
            this.selectTab({ tabName: ACTION_SIDEBAR_TAB.INFO })

            if (this.isChargingpointSelected === false) {
              this.selectAnChargingpointAndWaitForAnimationToEnd({ CallbackFn: this.$tours[this.tourName].nextStep })
              // TODO:: here could come an notification
              reject('sidebar not opened')
            }

            resolve()
          }),
        },
        {
          target: '#comments-tab-button',
          header: {
            title: 'Nieuw sidepanel',
          },
          content: `
              <p>Hier kun je opmerkingen achterlaten. Indien je gevraagd bent om voorgestelde locaties te valideren, kun je hier per locatie een beoordeling achterlaten door een vinkje of kruisje te zetten.</p>
            `,
          customOptions: {
            controls: true,
          },
          params: {
            placement: 'left-start',
          },
          before: () => new Promise((resolve, reject) => {
            this.selectTab({ tabName: ACTION_SIDEBAR_TAB.OPMERKINGEN })

            if (this.isChargingpointSelected === false) {
              this.selectAnChargingpointAndWaitForAnimationToEnd({ CallbackFn: this.$tours[this.tourName].nextStep })
              reject('sidebar not opened')
            }

            resolve()
          }),
        },
        {
          target: '#monitoring-tab-button',
          header: {
            title: 'Nieuw sidepanel',
          },
          content: `
              <p>Hier kun je, indien je ook onze Monitoring module hebt afgenomen, bij gerealiseerde laadpalen bekijken hoe het verbruik is.</p>
            `,
          customOptions: {
            controls: true,
          },
          params: {
            placement: 'left-start',
          },
          before: () => new Promise((resolve, reject) => {
            this.selectTab({ tabName: ACTION_SIDEBAR_TAB.MONITORING })

            if (this.isChargingpointSelected === false) {
              this.selectAnChargingpointAndWaitForAnimationToEnd({ CallbackFn: this.$tours[this.tourName].previousStep })
              reject('sidebar not opened')
            }

            resolve()
          }),
        },
        {
          target: '.StyleToggle.StyleToggle--Satellite',
          header: {
            title: 'Nieuwe verbeterde luchtfoto',
          },
          content: `
              <p>Een verbeterde luchtfoto laag is nu beschikbaar met resolutie van 7,5cm. Dit maakt het bijvoorbeeld makkelijker om individuele parkeervakken te onderscheiden.</p>
            `,
          customOptions: {
            controls: true,
            onlyForDeployments: ['nl'],
          },
          params: {
            placement: 'left-start',
          },
          before: () => new Promise((resolve, reject) => {
            this.deselectChargingPoint()

            setTimeout(() => {
              if (this.isChargingpointSelected) {
                reject('sidebar still opened')
              }

              resolve()
            }, TIME_TO_OPEN_CLOSE_SIDEBAR)
          }),
        },
        {
          target: '.Legend',
          header: {
            title: 'Gestructureerd valideren',
          },
          content: `
            <p>Er is nu een verbeterde manier om voorgestelde locaties gestructureerd te laten valideren door meerdere partijen zoals de CPO, netbeheerder en gemeente. Je kan:</p>
              <ul>
                <li>Per locatie in laten stellen wie deze moet beoordelen</li>
                <li>Zien wie er reeds beoordeeld heeft en op wie er nog wordt gewacht</li>
                <li>Filteren op welke locaties je zelf nog moet valideren</li>
              </ul>
            <p>Locaties veranderen vanzelf van voorgesteld naar gevalideerd of afgekeurd (hier is een nieuw icoon voor geïntroduceerd <img width="15" src="${this.iconChargingpointRejected}"/>) als alle beoordelingen binnen zijn.</p>
          `,
          customOptions: {
            controls: true,
            wide: true,
          },
          params: {
            placement: 'left-start',
          },
        }
      ];

      steps = steps.filter((step) => step.customOptions.onlyForDeployments ? step.customOptions.onlyForDeployments.includes(this.DeploymentCode) : true)

      this.setSteps({steps});
    },
    setSteps({steps}) {
      this.steps = steps
    },
    handleSkipTour() {
      this.hideTour()
      // this.skipTour({tourName: this.tourName, version: this.version})
    },
    hideTour() {
      localStorage.setItem(this.tourName, hasSeenTour)
    }
  }
}
</script>
