import {reduceUserInformation} from "@/helpers/user";

export default {
  data() {
    return {
      newValidators: []
    }
  },
  methods: {
    /* add validator if not already in array */
    $_chargingpointValidatorsMixin_addValidator() {
      if (this.fields.validatie.value === null) {
        return
      }

      if (this.newValidators.findIndex(option => option.user_id === this.fields.validatie.value) !== -1) {
        return
      }

      const user = this.usersByCode.find(user => user.user_id === this.fields.validatie.value)

      if (user === undefined) {
        return
      }

      this.newValidators.push(reduceUserInformation(user))
    },
    $_chargingpointValidatorsMixin_removeValidator(index) {
      this.newValidators.splice(index, 1)
    },
  }
}