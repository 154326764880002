import {isProxyByCode, proxyCodesByCode} from "@/services/municipalities";
import {chunk} from "@/helpers/array";
import {mapMutations} from "vuex";

export default {
  data() {
    return {
      isLoadingFauna: false
    }
  },
  methods: {
    ...mapMutations('planmode', [
      'setChargingPoints'
    ]),
    $_chargingpointsLoadMixin_getChargingPointsByCodes: async function({ code, codes, token }) {
      const api = await fetch('/api/chargingpointsload', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ codes, code })
      });
      return await api.json()
    },

    /**
     * Load the charging points for a municipality by code
     */
    $_chargingpointsLoadMixin_loadChargingPoints: async function({ code }) {
      this.isLoadingFauna = true

      // Quick & Dirty solution to avoid rate limits
      function sleep({ ms }) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      let codes = [ code ]
      if (isProxyByCode({ code })) {
        codes = proxyCodesByCode({ code })
      }

      const token = await this.$auth.getTokenSilently();
      const chunkes = chunk({ arr: codes, size: 5 }); // At more than 5 we frequently get timeouts

      let chargingpoints = []
      await chunkes.reduce( async (result, chunk) => {
        await sleep({ ms: 500 })
        let response = await this.$_chargingpointsLoadMixin_getChargingPointsByCodes({ code, codes: chunk, token })
        chargingpoints = chargingpoints.concat(response.chargingpoints)
        return result
      }, [])

      this.setChargingPoints({ chargingpoints })

      this.isLoadingFauna = false
    },
  }
}