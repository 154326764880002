export const statusSlugToLabel = ({ status }) => {
  let options = {
    rejected: 'Afgekeurd',
    suggestion: 'Voorgesteld',
    definitive: 'Gevalideerd',
    'in-progress': 'In voorbereiding',
    realized: 'Gerealiseerd',
    alert: 'Aandachtspunt',
    'realized-private': 'Gerealiseerd semi-publiek',
    'fastcharger-realized': 'Gerealiseerde snellader',
    'fastcharger-definitive': 'Gevalideerde snellader',
    'fastcharger-suggestion': 'Voorgestelde snellader',
    'fastcharger-rejected': 'Afgekeurde snellader',
    'charging-hub-definitive': 'Gevalideerd laadplein',
    'charging-hub-suggestion': 'Voorgesteld laadplein',
    'charging-hub-rejected': 'Afgekeurd laadplein',
  }
  return options[status] || 'Onbekend'
}

export const statusSlugToNumber = ({ status }) => {
  let options = {
    rejected: 6,
    suggestion: 1,
    definitive: 2,
    'in-progress': 3,
    realized: 4,
    alert: 0,
    'realized-private': 5
  }
  return options[status] || null
}

export const isLocatievoorstel = ({ status }) => {
  return status === "definitive"
    || status === "fastcharger-definitive"
    || status === "charging-hub-definitive"
    || status === "suggestion"
    || status === "fastcharger-suggestion"
    || status === "charging-hub-suggestion"
    || status === "rejected"
    || status === "fastcharger-rejected"
    || status === "charging-hub-rejected"
}