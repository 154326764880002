import {checkStatus, returnJson} from "@/helpers/api";
import {validAttachmentIDs} from "@/valueholders/attachments";
import {ATTACHMENT_ID} from "@/valueholders/enums";

export default {
  data() {
    return {
      loading: {
        down: false,
        up: false,
        remove: false
      },
      attachments: {
        [ATTACHMENT_ID.TOP]: {
          id: ATTACHMENT_ID.TOP,
          file: null,
          filename: null,
          type: null,
          url: null,
          removed: false,
          changed: false,
          edited: false,
        },
        [ATTACHMENT_ID.SIDE]: {
          id: ATTACHMENT_ID.SIDE,
          file: null,
          filename: null,
          type: null,
          url: null,
          removed: false,
          changed: false,
          edited: false,
        },
      },
    }
  },
  computed: {
    isLoadingAws() {
      return this.loading.down || this.loading.up || this.loading.remove
    }
  },
  methods: {
    /**
     * PUBLIC METHODS
     */
    $_chargingpointAttachmentsMixin_handleDrop(event, id) {
      this.isDragging = false;

      if (! event.dataTransfer.files.length > 0) {
        return
      }

      this.processFiles(event.dataTransfer.files, id);
    },

    $_chargingpointAttachmentsMixin_handleFileChange(event, id) {
      if (! event.target.files.length > 0) {
        return
      }

      this.processFiles(event.target.files, id)
    },

    $_chargingpointAttachmentsMixin_handleFileRemove(id) {
      if (! validAttachmentIDs.includes(id)) {
        return
      }

      this.resetAttachment({ id })

      this.attachments[id].removed = true
    },

    async $_chargingpointAttachmentsMixin_cleanFetchAttachments() {
      Object.values(this.attachments)
        .forEach(attachment => this.resetAttachment({ id: attachment.id }))

      await this.fetchAttachments()
    },

    $_chargingpointAttachmentsMixin_uploadAttachments() {
      Object.values(this.attachments).forEach(async attachment => {
        if (attachment.changed) {
          await this.uploadAttachment({ id: attachment.id })
        }
      })
    },

    $_chargingpointAttachmentsMixin_deleteAttachments() {
      Object.values(this.attachments).forEach(async attachment => {
        if (attachment.removed) {
          await this.removeAttachment({ id: attachment.id })
        }
      })
    },

    /**
     * HELPER
     */
    resetAttachment({ id }) {
      // RESET THUMBNAIL
      this.attachments[id].file = null
      this.attachments[id].filename = null
      this.attachments[id].type = null
      this.attachments[id].url = null
      this.attachments[id].changed = false
      this.attachments[id].edited = false
      this.attachments[id].removed = false
    },

    processFiles(files, id) {
      if (! validAttachmentIDs.includes(id)) {
        return
      }

      const file = files[0]

      this.attachments[id].file = file
      this.attachments[id].url = URL.createObjectURL(file)
      this.attachments[id].changed = true
    },

    /*
    * S3 API
    */
    async fetchAttachments() {
      this.loading.down = true

      const token = await this.$auth.getTokenSilently()

      for (const id of validAttachmentIDs) {
        try {
          const response = await fetch(`${process.env.VUE_APP_AWS_BASE_URL}/download`, {
            method: 'POST',
            headers: {
              authorization: 'Bearer ' + token
            },
            body: JSON.stringify({
              code: this.getActiveMunicipality,
              id: this.chargingpoint.data.properties.id,
              uuid: this.chargingpoint.data.uuid,
              view: id
            })
          })
            .then(await checkStatus)
            .then(returnJson)

          if (! response.downloadURL) {
            continue;
          }

          this.attachments[id].filename = response.filename
          this.attachments[id].type = response.filetype

          fetch(response.downloadURL)
            .then(await checkStatus)
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => this.attachments[id].url = url)

        } catch (e) {
          if (e.message === "File not found") {
            continue
          }

          console.log('error in file download')
        }
      }

      this.loading.down = false
    },

    async uploadAttachment({ id }) {
      this.loading.up = true

      if (! validAttachmentIDs.includes(id)) {
        return
      }

      if (! this.attachments[id].file) {
        return
      }

      const token = await this.$auth.getTokenSilently()

      try {
        let response = await fetch(`${process.env.VUE_APP_AWS_BASE_URL}/upload`, {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token
          },
          body: JSON.stringify({
            code: this.getActiveMunicipality,
            id: this.chargingpoint.data.properties.id,
            uuid: this.chargingpoint.data.uuid,
            view: id,
            edited: this.attachments[id].edited,
            type: this.attachments[id].file.type,
            size: this.attachments[id].file.size,
          })
        })
          .then(await checkStatus)
          .then(returnJson)

        if (! response.uploadURL) {
          return
        }

        const buffer = await this.attachments[id].file.arrayBuffer()

        await fetch(response.uploadURL, {
          method: 'PUT',
          body: buffer
        })
          .then(await checkStatus)

        this.attachments[id].file = null

      } catch (e) {
        console.log('error in file upload')
      }

      this.loading.up = false
    },

    async removeAttachment({ id }) {
      this.loading.remove = true

      if (! validAttachmentIDs.includes(id)) {
        return
      }

      const token = await this.$auth.getTokenSilently()

      try {
        await fetch(`${process.env.VUE_APP_AWS_BASE_URL}/delete`, {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token
          },
          body: JSON.stringify({
            code: this.getActiveMunicipality,
            id: this.chargingpoint.data.properties.id,
            uuid: this.chargingpoint.data.uuid,
            view: id,
            type: this.attachments[id].type,
          })
        })
          .then(await checkStatus)

      } catch (e) {
        console.log('error removing image')
      }

      this.loading.remove = false
    },
  }
}