<template>
  <div style="display:none"></div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'MapDataLayers',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      activeSources: [],
      activeLayers: [],
      active: ''
    }
  },
  computed: {
    ...mapGetters('config', {
      layers: 'contextLayers'
    }),
    ...mapGetters('prognose', [
      'labelLayer'
    ]),
    ...mapGetters('deployment', [
      'DeploymentLayerName'
    ]),
    layerConfig() {
      return this.layers
    },
    expectedActiveLayers() {
      return Object.values(this.layerConfig)
        .filter(layer => ['primary', 'secondary'].includes(layer.status) && ! layer.ignore)
    }
  },
  watch: {
    loaded() {
      this.removeLayers()
      this.addLayers()
    },
    /**
     * If the config changed we'll need to update the layer.
     *  For a vector layer this requires removing the current layer first.
     */
    layers() {
      this.removeLayers()
      this.addLayers()
    },
    /**
     * Let the application know when all active layers have been loaded
     */
    activeLayers(value) {
      if (this.expectedActiveLayers.length <= value.length) {
        this.setLoaded({
          loaded: true
        })
      }
    }
  },
  methods: {
    ...mapMutations('prognose', [
      'setLoaded'
    ]),
    addLayers() {
      // If there are no layers to add, the activelayer watcher won't trigger. So we do it here.
      if (this.expectedActiveLayers.length === 0) {
        this.setLoaded({
          loaded: true
        })
        return
      }

      // grondeigendom
      if (this.isLayerActive({ name: 'grondeigendom' })) {
        this.$store.map.addSource('grondeigendom', {
          type: 'vector',
          url: this.layerConfig.grondeigendom.url
        })
        this.activeSources.push('grondeigendom')

        this.$store.map.addLayer({ 
          "id": "grondeigendom",
          "type": "fill",
          "source": "grondeigendom",
          "source-layer": this.layerConfig.grondeigendom.source,
          "minzoom": 12,
          "paint": {
            "fill-color": "hsla(339, 100%, 37%, 0.2)", // "hsl(52, 73%, 73%)",
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0,
              13,
              0.90
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('grondeigendom')
      }

      // deelgebied vlakken with text and hover state
      if (this.isLayerActive({ name: "deelgebied_vlak" })) {
        this.$store.map.addSource("deelgebied_vlak", {
          type: "vector",
          url: this.layerConfig.deelgebied_vlak.url
        })
        this.activeSources.push("deelgebied_vlak")

        this.$store.map.addLayer({
          "id": "deelgebied_vlak",
          "type": "fill",
          "source": "deelgebied_vlak",
          "source-layer": this.layerConfig.deelgebied_vlak.source,
          "minzoom": 0,
          "paint": {
            "fill-color": "#EDCF98",
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              0.7,
              0
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push("deelgebied_vlak")

        this.$store.map.addLayer({
          "id": "deelgebied_vlak_text",
          "type": "symbol",
          "source": "deelgebied_vlak",
          "source-layer": this.layerConfig.deelgebied_vlak.source,
          "paint": {
            "text-color": "#6E5425",
            "text-opacity": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              0.9,
              0
            ]
          },
          "layout": {
            "text-field": ["get", "label"],
            "symbol-placement": "point",
            "text-allow-overlap": true,
          },
        }, this.labelLayer)
        this.activeLayers.push("deelgebied_vlak_text")

        /* When the user hovers over a subarea plane, highlight it and display the label */
        let hoveredStateId = null
        let sourceLayer = null

        this.$store.map.on("mousemove", "deelgebied_vlak", (e) => {
          if (! e.features.length) return;

          sourceLayer = e.features[0].layer["source-layer"]

          /* clear previously hovered states */
          if (hoveredStateId !== null) {
            this.$store.map.setFeatureState(
                { source: "deelgebied_vlak", sourceLayer: sourceLayer, id: hoveredStateId },
                { test: "string", hover: false }
            );
          }

          hoveredStateId = e.features[0].id;

          this.$store.map.setFeatureState(
              { source: "deelgebied_vlak", sourceLayer: sourceLayer, id: hoveredStateId },
              { hover: true }
          );
        });

        this.$store.map.on("mouseleave", "deelgebied_vlak", () => {
          if (hoveredStateId !== null) {
            this.$store.map.setFeatureState(
                { source: "deelgebied_vlak", sourceLayer: sourceLayer, id: hoveredStateId },
                { hover: false }
            );
          }
          hoveredStateId = null;
        });
      }

      // deelgebied lijnen
      if (this.isLayerActive({ name: "deelgebied_lijn" })) {
        this.$store.map.addSource("deelgebied_lijn", {
          type: "vector",
          url: this.layerConfig.deelgebied_lijn.url
        })
        this.activeSources.push("deelgebied_lijn")

        this.$store.map.addLayer({
          "id": "deelgebied_lijn",
          "type": "line",
          "source": "deelgebied_lijn",
          "source-layer": this.layerConfig.deelgebied_lijn.source,
          "paint": {
            "line-color": "#6E6046",
            "line-width": 1,
            "line-dasharray": [3, 1.5]
          }
        }, this.labelLayer)
        this.activeLayers.push("deelgebied_lijn")
      }

      // Nieuwbouw locaties
      if (this.isLayerActive({ name: 'nieuwbouw' })) {
        this.$store.map.addSource('nieuwbouw', {
          type: 'vector',
          url: this.layerConfig.nieuwbouw.url
        })
        this.activeSources.push('nieuwbouw')

        this.$store.map.addLayer({ 
          "id": "nieuwbouw",
          "type": "fill",
          "source": "nieuwbouw",
          "source-layer": this.layerConfig.nieuwbouw.source,
          "minzoom": 12,
          "paint": {
            "fill-color": "#4264fb", // "hsl(52, 73%, 73%)",
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0,
              13,
              0.70
            ],
            "fill-outline-color": "#314ccd"
          }
        }, this.labelLayer)
        this.activeLayers.push('nieuwbouw')
      }

      // monuments locaties
      if (this.isLayerActive({ name: 'monuments' })) {
        this.$store.map.addSource('monuments', {
          type: 'vector',
          url: this.layerConfig.monuments.url
        })
        this.activeSources.push('monuments')

        this.$store.map.addLayer({ 
          "id": "monuments",
          "type": "fill",
          "source": "monuments",
          "source-layer": this.layerConfig.monuments.source,
          "minzoom": 12,
          "paint": {
            "fill-color": "#c41ee1", // "hsl(52, 73%, 73%)",
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0,
              13,
              0.70
            ],
            "fill-outline-color": "#ac48b7"
          }
        }, this.labelLayer)
        this.activeLayers.push('monuments')
      }

      // Lichtnet
      if (
        ! this.DeploymentLayerName({ layer: 'ls_kabels' }) &&
        this.isLayerActive({ name: 'ls' })
      ) {
        this.$store.map.addSource('ls', {
          type: 'vector',
          url: this.layerConfig.ls.url
        })
        this.activeSources.push('ls')

        this.$store.map.addLayer({ 
          "id": "ls",
          "type": "line",
          "source": "ls",
          "source-layer": this.layerConfig.ls.source,
          "minzoom": 13,
          "paint": {
            "line-color": "hsl(144, 79%, 57%)", //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            "line-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.90
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('ls')
      }

      // Lichtnet - Middenspanning
      if (this.isLayerActive({ name: 'ms' })) {
        this.$store.map.addSource('ms', {
          type: 'vector',
          url: this.layerConfig.ms.url
        })
        this.activeSources.push('ms')

        this.$store.map.addLayer({ 
          "id": "ms",
          "type": "line",
          "source": "ms",
          "source-layer": this.layerConfig.ms.source,
          "minzoom": 13,
          "paint": {
            "line-color": "hsl(144, 66%, 37%)", //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            "line-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.90
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('ms')
      }


      // N Wegen
      if (this.isLayerActive({ name: 'nwegen' })) {
        this.$store.map.addSource('nwegen', {
          type: 'vector',
          url: this.layerConfig.nwegen.url
        })
        this.activeSources.push('nwegen')

        this.$store.map.addLayer({ 
          "id": "nwegen",
          "type": "line",
          "source": "nwegen",
          "source-layer": this.layerConfig.nwegen.source,
          "minzoom": 10,
          "paint": {
            "line-color": "hsl(360, 92%, 77%)", //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            "line-width": 5,
            "line-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              0,
              10.3,
              0.90
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('nwegen')
      }


      // Parkeer druk
      if (this.isLayerActive({ name: 'parkeerdruk' })) {
        this.$store.map.addSource('parkeerdruk', {
          type: 'vector',
          url: this.layerConfig.parkeerdruk.url
        })
        this.activeSources.push('parkeerdruk')

        this.$store.map.addLayer({ 
          "id": "parkeerdruk",
          "type": "line",
          "source": "parkeerdruk",
          "source-layer": this.layerConfig.parkeerdruk.source,
          "minzoom": 13,
          "paint": {
            "line-color": "#FF6D6D", //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            "line-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.90
            ],
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              4,
              18,
              12
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('parkeerdruk')
      }

      // Openbare Verlichting
      if (this.isLayerActive({ name: 'ovkasten' })) {
        this.$store.map.addSource('ovkasten', {
          type: 'vector',
          url: this.layerConfig.ovkasten.url
        })
        this.activeSources.push('ovkasten')

        this.$store.map.addLayer({ 
          "id": "ovkasten",
          "type": "circle",
          "source": "ovkasten",
          "source-layer": this.layerConfig.ovkasten.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              16
            ],
            "circle-color": "hsl(93, 28%, 43%)",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.72
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('ovkasten')

        this.$store.map.addLayer({
          "id": "ovkasten-labels",
          "type": "symbol",
          "source": "ovkasten",
          "source-layer": this.layerConfig.ovkasten.source,
          "layout": {
            "text-field": ["get", "Text"],
            "text-anchor": "top",
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "text-size": 12
          },
          "paint": {
            "text-color": "#6b6b6b",
            "text-translate": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              ["literal", [0, 4]],
              22,
              ["literal", [0, 16]],
            ],
            "text-halo-color": "rgb(255,255,255)",
            "text-halo-width": 1,
            "text-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              16,
              0.72
            ],
          }
        });
        this.activeLayers.push('ovkasten-labels')
      }

      // Parking Spaces
      if (this.isLayerActive({ name: 'parking' })) {
        this.$store.map.addSource('parking', {
          type: 'vector',
          url: this.layerConfig.parking.url
        })
        this.activeSources.push('parking')

        this.$store.map.addLayer({ 
          "id": "parking",
          "type": "fill",
          "source": "parking",
          "source-layer": this.layerConfig.parking.source,
          "minzoom": 13,
          "paint": {
            "fill-color": "hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.90
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('parking')
      }

      // Trees on top
      if (this.isLayerActive({ name: 'trees_zone' })) {
        this.$store.map.addSource('trees_z', {
          type: 'vector',
          url: this.layerConfig.trees_zone_z.url
        })
        this.activeSources.push('trees_z')

        this.$store.map.addLayer({ 
          "id": "trees_z",
          "type": "fill",
          "source": "trees_z",
          "source-layer": this.layerConfig.trees_zone_z.source,
          "minzoom": 12,
          "paint": {
            "fill-color": "hsla(93, 28%, 43%, 0.5)", // "hsl(52, 73%, 73%)",
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0,
              13,
              0.90
            ]
          }
        }, this.labelLayer)
        this.activeLayers.push('trees_z')

        this.$store.map.addSource('trees_p', {
          "type": "vector",
          "url": this.layerConfig.trees_zone.url
        })
        this.activeSources.push('trees_p')

        this.$store.map.addLayer({ 
          "id": "trees_p",
          "type": "circle",
          "source": "trees_p",
          "source-layer": this.layerConfig.trees_zone.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              10
            ],
            "circle-color": "hsl(93, 28%, 43%)",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.40
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('trees_p')

        // To avoid breaking the activation process of the legend toggles in case `trees` has also been configured as layer
        if (this.isLayerActive({ name: 'trees' })) {
          this.activeLayers.push('trees')
        }
      } 
      else if (this.isLayerActive({ name: 'trees' })) {
        this.$store.map.addSource('trees', {
          "type": "vector",
          "url": this.layerConfig.trees.url
        })
        this.activeSources.push('trees')

        this.$store.map.addLayer({ 
          "id": "trees",
          "type": "circle",
          "source": "trees",
          "source-layer": this.layerConfig.trees.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              10
            ],
            "circle-color": "hsl(93, 28%, 43%)",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.40
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('trees')
      }

      if (this.isLayerActive({ name: 'lichtmasten' })) {
        this.$store.map.addSource('lichtmasten', {
          "type": "vector",
          "url": this.layerConfig.lichtmasten.url
        })
        this.activeSources.push('lichtmasten')

        this.$store.map.addLayer({ 
          "id": "lichtmasten",
          "type": "circle",
          "source": "lichtmasten",
          "source-layer": this.layerConfig.lichtmasten.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              10
            ],
            "circle-color": "hsl(57, 100%, 52%)",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.85
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('lichtmasten')
      }

      if (this.isLayerActive({ name: 'straatmeubilair' })) {
        this.$store.map.addSource('straatmeubilair', {
          "type": "vector",
          "url": this.layerConfig.straatmeubilair.url
        })
        this.activeSources.push('straatmeubilair')

        this.$store.map.addLayer({ 
          "id": "straatmeubilair",
          "type": "circle",
          "source": "straatmeubilair",
          "source-layer": this.layerConfig.straatmeubilair.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              10
            ],
            "circle-color": "hsl(224, 72%, 28%)",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.85
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('straatmeubilair')
      }

      // visitorlocations on top - there are a lot
      if (this.isLayerActive({ name: 'visitorlocations' })) {
        this.$store.map.addSource('visitorlocations', {
          "type": "vector",
          "url": this.layerConfig.visitorlocations.url
        })
        this.activeSources.push('visitorlocations')

        this.$store.map.addLayer({ 
          "id": "visitorlocations",
          "type": "circle",
          "source": "visitorlocations",
          "source-layer": this.layerConfig.visitorlocations.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              10
            ],
            "circle-color": "hsl(0, 98%, 69%)",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.85
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('visitorlocations')
      }

      // worklocations on top - there are a lot
      if (this.isLayerActive({ name: 'worklocations' })) {
        this.$store.map.addSource('worklocations', {
          "type": "vector",
          "url": this.layerConfig.worklocations.url
        })
        this.activeSources.push('worklocations')

        this.$store.map.addLayer({ 
          "id": "worklocations",
          "type": "circle",
          "source": "worklocations",
          "source-layer": this.layerConfig.worklocations.source,
          "minzoom": 13,
          "paint": {
            'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              1,
              22,
              10
            ],
            "circle-color": "#ff00f4",
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              0,
              14,
              0.85
            ],
            "circle-stroke-width": 0,
          }
        }, this.labelLayer)
        this.activeLayers.push('worklocations')
      }

      // Park&Ride
      if (
        ! this.DeploymentLayerName({ layer: 'park_n_ride' }) &&
        this.isLayerActive({ name: 'pplusr' })
      ) {
        this.$store.map.addSource('pplusr', {
          "type": "vector",
          "url": this.layerConfig.pplusr.url
        })
        this.activeSources.push('pplusr')

        const addPplusrLayer = () => {
          if (this.$store.map.getLayer('pplusr')) return

          this.$store.map.addLayer({ 
            "id": "pplusr",
            "type": "symbol",
            "source": "pplusr",
            "source-layer": this.layerConfig.pplusr.source,
            "minzoom": 11,
            "layout": {
              "symbol-placement": "point",
              "symbol-z-order": "source",
              "icon-allow-overlap": true,
              "icon-image": "parkandride",  // "charging-station-15",
              "icon-size": [ // transition based on zoom
                "interpolate",
                ["linear"],
                ["zoom"],
                11,
                0.05,
                18,
                0.2
              ] 
            },
            "paint": {
              // "icon-color": "",
              "icon-opacity": [ // transition based on zoom
                "interpolate",
                ["linear"],
                ["zoom"],
                10,
                0,
                10.2,
                0.8
              ] 
            }
          }, this.labelLayer)
          this.activeLayers.push('pplusr')
        }
        if (!this.$store.map.hasImage('parkandride')) {
          try {
            this.$store.map.loadImage(
              require('@/assets/image/legend/parkandride.png'),
              (err, image) => {
                if (err) return

                if (! this.$store.map.hasImage('parkandride')) {
                  this.$store.map.addImage('parkandride', image);
                  addPplusrLayer()
                }
            })
          } catch(e) {
            //
          }
        } else {
          addPplusrLayer()
        }
      }

      // Fuel on top
      if (
        ! this.DeploymentLayerName({ layer: 'fossilfuel_stations' }) &&
        this.isLayerActive({ name: 'fuelstations' })
      ) {

        this.$store.map.addSource('fuelstations', {
          "type": "vector",
          "url": this.layerConfig.fuelstations.url
        })
        this.activeSources.push('fuelstations')

        const addFuelStationLayer = () => {
          if (this.$store.map.getLayer('fuelstations')) return

          this.$store.map.addLayer({ 
            "id": "fuelstations",
            "type": "symbol",
            "source": "fuelstations",
            "source-layer": this.layerConfig.fuelstations.source,
            "minzoom": 11,
            "layout": {
              "symbol-placement": "point",
              "symbol-z-order": "source",
              "icon-allow-overlap": true,
              "icon-image": "fuelpump",
              "icon-size": [ // transition based on zoom
                "interpolate",
                ["linear"],
                ["zoom"],
                11,
                0.05,
                18,
                0.2
              ] 
            },
            "paint": {
              // "icon-color": "",
              "icon-opacity": [ // transition based on zoom
                "interpolate",
                ["linear"],
                ["zoom"],
                11,
                0,
                11.2,
                0.8
              ] 
            }
          }, this.labelLayer)
          this.activeLayers.push('fuelstations')
        }

        if (!this.$store.map.hasImage('fuelpump')) {
          try {
            this.$store.map.loadImage(
              require('@/assets/image/legend/fuelpump.png'),
              (err, image) => {
                if (err) return

                if (! this.$store.map.hasImage('fuelpump')) {
                  this.$store.map.addImage('fuelpump', image);
                  addFuelStationLayer()
                }
            })
          } catch (e) {
            //
          }
        } else {
          addFuelStationLayer()
        }
      }

      // CNG / LNG on top
      if (this.isLayerActive({ name: 'lngcng' })) {

        this.$store.map.addSource('lngcng', {
          "type": "vector",
          "url": this.layerConfig.lngcng.url
        })
        this.activeSources.push('lngcng')

        const addLNGCNGStationLayer = () => {
          if (this.$store.map.getLayer('lngcng')) return

          this.$store.map.addLayer({ 
            "id": "lngcng",
            "type": "symbol",
            "source": "lngcng",
            "source-layer": this.layerConfig.lngcng.source,
            "minzoom": 11,
            "layout": {
              "symbol-placement": "point",
              "symbol-z-order": "source",
              "icon-allow-overlap": true,
              "icon-image": "lngcng",
              "icon-size": [ // transition based on zoom
                "interpolate",
                ["linear"],
                ["zoom"],
                11,
                0.05,
                18,
                0.2
              ] 
            },
            "paint": {
              // "icon-color": "",
              "icon-opacity": [ // transition based on zoom
                "interpolate",
                ["linear"],
                ["zoom"],
                11,
                0,
                11.2,
                0.8
              ] 
            }
          }, this.labelLayer)
          this.activeLayers.push('lngcng')
        }

        if (!this.$store.map.hasImage('lngcng')) {
          try {
            this.$store.map.loadImage(
              require('@/assets/image/legend/lngcng.png'),
              (err, image) => {
                if (err) return

                if (! this.$store.map.hasImage('lngcng')) {
                  this.$store.map.addImage('lngcng', image);
                  addLNGCNGStationLayer()
                }
            })
          } catch (e) {
            //
          }
        } else {
          addLNGCNGStationLayer()
        }
      }
    },
    isLayerActive({ name }) {
      return this.layerConfig[name] && ["primary", "secondary"].includes(this.layerConfig[name].status)
    },

    /**
     * Go through all registered layers and source to clean them out
     */
    removeLayers() {
      this.setLoaded({
        loaded: false
      })

      // Remove all registered layers
      this.activeLayers.forEach(layer => {
        if (this.$store.map.getLayer(layer)) {
          this.$store.map.removeLayer(layer)
        }
      })
      this.activeLayers = []

      // Remove all registered sources
      this.activeSources.forEach(source => {
        if (this.$store.map.getSource(source)) {
          this.$store.map.removeSource(source)
        }
      })
      this.activeSources = []
    }
  },
}
</script>
