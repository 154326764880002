<template>
  <AdminApp>
    <div class="Dashboard d-flex flex-column w-100 align-items-center">
      <MunicipalityPanel class="Dashboard__Panel mx-3" />
    </div>
  </AdminApp>
</template>

<script>
import AdminApp from '@/views/admin/AdminApp'
import MunicipalityPanel from '@/components/admin/MunicipalityPanel'

import { slugToCode, codeToSlug, labelByCode } from '@/services/municipalities'

import { mapActions, mapGetters, mapMutations } from 'vuex'

/**
 * 
 */
export default {
  components: {
    AdminApp, MunicipalityPanel
  },
  computed: {
    ...mapGetters('access', [
      'municipalityCodeList',
      'getActiveMunicipality'
    ]),
    ...mapGetters('config', [
      'hasConfig', 
      'loaded',
      'getAvailableConfigCodes'
    ])
  },
  watch: {
    loaded() {
      this.loadOrRedirect({ slug: this.$route.params.municipality })
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loadOrRedirect({ slug: to.params.municipality })
    next()
  },
  created() {
    this.loadOrRedirect({ slug: this.$route.params.municipality })
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    ...mapMutations('access', [
      'setActiveMunicipality' 
    ]),
    ...mapMutations('config', [
      'selectConfig'
    ]),
    ...mapActions('config', [
      'createConfig'
    ]),
    loadOrRedirect({ slug }) {

      if (slug) {
        this.setActiveMunicipality({ slug })
        this.selectOrCreate({ slug }) // TODO: Not necessary ?

        this.setPageTitle({
          title: `EV Maps Beheer - ${ labelByCode({ code: this.getActiveMunicipality }) }`
        })
      }
      else if (this.getActiveMunicipality) {
        this.$router.push({
          name: 'Dashboard',
          params: {
            municipality: codeToSlug({ code: this.getActiveMunicipality })
          }
        })
      } else if ( this.getAvailableConfigCodes.length ) {
        this.$router.push({
          name: 'Dashboard',
          params: {
            municipality: codeToSlug({ code: this.getAvailableConfigCodes[0] })
          }
        })
      } else {
        // Not enough info yet... wait for loaded signal
      }
    },
    selectOrCreate({ code, slug }) {
      if (slug) {
        code = slugToCode({ slug })
      }
      if (this.hasConfig({ code })) {
        this.selectConfig({ code })
      } else if (this.loaded) {
        this.createConfig({ code })
      }
    }
  }
}
</script>

<style lang="scss">
.Dashboard {
  &__Panel {
    // width: calc(100% - 2rem);
    min-width: 900px;
    max-width: 900px;

    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;

    margin-top: 1rem;

    @media (min-width: 1280px) { 
      margin-top: 3rem;
      max-width: 1000px;
    }
    @media (min-width: 1440px) { 
      min-width: 1100px;
      max-width: 1100px;
    }
  }
  .vSelect {
    min-width: 300px;
  }
}
</style>