<template>
  <div class="w-100">
    <MapBox 
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :options="mapOptions"
      @load="onMapLoaded"
    >

      <ChargerEditorPopup v-if="isPlanmodeActive" />
      <ChargerDetailsPopup v-else />

      <ConstructionPopup />
      <TopLocationPopup />

    </MapBox>

    <div class="DataSideBar DataSideBar__expanded">
      <ActionSidebar v-if="loaded" />

      <TileDataContainer v-show="!isNeighborhoodForcast">
        <TileData :loaded="loaded" />
      </TileDataContainer>
      <TileDataContainer v-show="isNeighborhoodForcast">
        <NeighborhoodData :loaded="loaded" />
      </TileDataContainer>
    </div>

    <BorderLayer :loaded="loaded" />
    <FossilFuelStationsLayer :loaded="loaded" />
    <ParkNRideLayer :loaded="loaded" />
    <HoppinLayer :loaded="loaded" />
    <MiddenSpanningLayer :loaded="loaded" />
    <RWSBeheerLayer :loaded="loaded" />
    <TrafficFlowLayer :loaded="loaded" />
    <HighwayRestAreasLayer :loaded="loaded" />
    <CarPoolParkingAreasLayer :loaded="loaded" />

    <LSLayer :loaded="loaded" />
    <LSKastenLayer :loaded="loaded" />
    <MSKastenLayer :loaded="loaded" />
    <LSMSKastenLayer :loaded="loaded" />

    <RegularPrognoseLayer :loaded="loaded" />
    <TaxiPrognoseLayer :loaded="loaded" />
    <LogisticsPrognoseLayer :loaded="loaded" />
    <CarSharingPrognoseLayer :loaded="loaded" />
    <FastchargePrognoseLayer :loaded="loaded" />
    <MunicipalityPrognoseLayer :loaded="loaded" />

    <ConstructionSiteLayer :loaded="loaded" />
    <TopLocationsLayer :loaded="loaded" />

    <MraePlankaartLayer :loaded="loaded" />

    <MapDataLayers :loaded="loaded" />
    <ChargerPointsLayer :loaded="loaded" />
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'

// Mapbox GeoCoder
require('es6-promise').polyfill() // IE 11 polyfill
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'


import MapBox from '@/components/common/MapBox'
import ActionSidebar from "@/components/map/sidebar/ActionSidebar";
import TileData from '@/components/map/TileData'
import NeighborhoodData from '@/components/map/NeighborhoodData'

import BorderLayer from '@/components/map/national/BorderLayer'
import MiddenSpanningLayer from '@/components/map/national/MiddenSpanningLayer'
import RWSBeheerLayer from '@/components/map/national/RWSBeheerLayer'
import TrafficFlowLayer from '@/components/map/national/TrafficFlowLayer'

import FossilFuelStationsLayer from '@/components/map/national/FossilFuelStationsLayer'
import ParkNRideLayer from '@/components/map/national/ParkNRideLayer'
import HoppinLayer from '@/components/map/regional/HoppinLayer'
import HighwayRestAreasLayer from '@/components/map/national/HighwayRestAreasLayer'
import CarPoolParkingAreasLayer from '@/components/map/national/CarPoolParkingAreasLayer'

import LSLayer from '@/components/map/national/LSLayer'
import LSKastenLayer from '@/components/map/national/LSKastenLayer'
import MSKastenLayer from '@/components/map/national/MSKastenLayer'
import LSMSKastenLayer from '@/components/map/national/LSMSKastenLayer'

import RegularPrognoseLayer from '@/components/map/prognoses/RegularPrognoseLayer'
import TaxiPrognoseLayer from '@/components/map/prognoses/TaxiPrognoseLayer'
import LogisticsPrognoseLayer from '@/components/map/prognoses/LogisticsPrognoseLayer'
import CarSharingPrognoseLayer from '@/components/map/prognoses/CarSharingPrognoseLayer'
import FastchargePrognoseLayer from '@/components/map/prognoses/FastchargePrognoseLayer'
import MunicipalityPrognoseLayer from '@/components/map/prognoses/MunicipalityPrognoseLayer'

// ZH
import TopLocationsLayer from '@/components/map/regional/TopLocationsLayer'
import ConstructionSiteLayer from '@/components/map/regional/ConstructionSiteLayer'

import MraePlankaartLayer from "@/components/map/regional/MraePlankaartLayer";

import MapDataLayers from '@/components/map/MapDataLayers'
import ChargerPointsLayer from '@/components/map/ChargerPointsLayer'

import ChargerDetailsPopup from '@/components/map/modals/ChargerDetailsPopup'
import ChargerEditorPopup from '@/components/map/modals/ChargerEditorPopup'
import ConstructionPopup from '@/components/map/modals/ConstructionPopup'
import TopLocationPopup from '@/components/map/modals/TopLocationPopup'

import { mapGetters, mapMutations } from 'vuex'
import { StyleControl, ButtonControl } from '@/services/mapbox'
import { labelByCode, countryByMunicipalityCode } from '@/services/municipalities'
import { generateExport } from '@/services/pdfexport.js'
import TileDataContainer from "@/components/map/TileDataContainer";

export default {
  components: {
    TileDataContainer,
    MapBox, TileData, NeighborhoodData,
    BorderLayer, MiddenSpanningLayer, RWSBeheerLayer, TrafficFlowLayer,
    FossilFuelStationsLayer, ParkNRideLayer,
    LSLayer, LSKastenLayer, MSKastenLayer, LSMSKastenLayer,
    RegularPrognoseLayer, TaxiPrognoseLayer, LogisticsPrognoseLayer,
    CarSharingPrognoseLayer, FastchargePrognoseLayer, MunicipalityPrognoseLayer,
    MapDataLayers, ChargerPointsLayer, 
    ChargerDetailsPopup, ChargerEditorPopup, ConstructionPopup, TopLocationPopup,
    TopLocationsLayer, ConstructionSiteLayer, MraePlankaartLayer,
    HoppinLayer, HighwayRestAreasLayer, CarPoolParkingAreasLayer,
    ActionSidebar,
  },
  data() {
    return {
      loaded: false,
      geocoder: null
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentCode',
      'DeploymentConfig'
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality'
    ]),
    ...mapGetters('config', [
      'accessToken',
      'mapStyle',
      'mapStyleSat',
      'mapOptions',
      'layers'
    ]),
    ...mapGetters('planmode', {
      isPlanmodeActive: 'isActive'
    }),
    ...mapGetters('scenarios', [
      'forecast'
    ]),

    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
    isNeighborhoodForcast() {
      return ['fastcharge', 'carsharing', 'logistics', 'taxis', 'gemeenteprognoses'].includes(this.forecast)
    },
    satUrl() {
      return this.DeploymentConfig.sat || false
    },
    country() {
      return this.DeploymentConfig.country
    },
  },
  watch: {
    /**
     * Update the geocoder country bounds
     */
    getActiveMunicipality(code) {
      let country = countryByMunicipalityCode({ code })
      if (country && this.geocoder !== null) {
        this.geocoder.setCountries(country)
      }
    },
    mapOptions() {
      if (!this.$store.map) return
      
      if (this.mapOptions.bounds) {
        this.$store.map.fitBounds(this.mapOptions.bounds, { padding: 20 })

        // Make the geocoder more accurate
        if (this.geocoder !== null) {
          this.geocoder.setBbox(this.mapOptions.bounds)
        }
      } else {
        this.$store.map.flyTo(this.mapOptions)
      }
    }
  },
  beforeDestroy() {
    this.setLoaded({
      loaded: false
    })
    this.$nextTick(() => {
      this.$store.map = null
    })
  },
  methods: {
    ...mapMutations('prognose', [
      'setLabelLayer', 
      'setLoaded'
    ]),
    onMapLoaded({ map }) {

      // Store the map in a global, non-reactive manner
      this.$store.map = map

      this.addMapControls()

      // TODO: Avoid hardcoding. The previously used label detection function no longer gives the correct result.
      this.setLabelLayer({
        id: 'waterway-label' // 'road-label'
      })

      this.loaded = true
    },
    addMapControls() {
      // Create the GeoCoder plugin
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: mapboxgl,
        countries: this.country,
        placeholder: 'Zoek op adres'
      })

      // add geocoder "search bar" to the map
      this.$store.map.addControl(
        this.geocoder,
        'top-left'
      )

      // adds zoom control to the map
      this.$store.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true
        }),
        'top-left'
      )

      // Add geolocate control to the map.
      this.$store.map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true
          }),
          'top-left'
      )
      
      this.$store.map.addControl(
        new ButtonControl({
          label: 'Download Map',
          callback: this.downloadMap
        }), 'bottom-left'
      )
      
      this.$store.map.addControl(
        new mapboxgl.ScaleControl(), 
        'bottom-left'
      )

      if (this.satUrl) {
        this.$store.map.addControl(
          new StyleControl({
            styles: [{
              class: 'Light',
              url: this.mapStyle
            }, {
              class: 'Satellite',
              url: this.mapStyleSat
            }]
          }), 
          'bottom-left'
        )

        // When the style changes, trigger a reload of all layers
        this.$store.map.on('style.load', this.reloadLayers)
      }


      // Add geolocate control to the map.
      this.$store.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      )

    },
    /**
     * When the map style changes, all layers need to be reloaded
     */
    reloadLayers() {
      this.loaded = false;

      this.$nextTick(() => {
        this.loaded = true

        this.addAerialPhotography()
      })
    },
    /**
     * Add aerial photography
     *  WMTS layers cannot be included in mapbox styles. They need to be added with js
     */
    addAerialPhotography() {
      if (this.loaded) {
        let { sprite } = this.$store.map.getStyle()

        // If it's the SAT style that is active
        if (sprite.replace('sprites', 'styles').startsWith(process.env.VUE_APP_MAPBOX_STYLE_SAT)) {
          this.$store.map.addSource('aerial-photography', {
            'type': 'raster',
            'tiles': [
              this.satUrl
            ],
            'tileSize': 256
          });
          this.$store.map.addLayer({
              'id': 'aerial-photography-layer',
              'type': 'raster',
              'source': 'aerial-photography',
              'minzoom': 0,
              'maxzoom': 22
            }, 'tunnel-street-minor-low'
          );
        }
      }
    },
    /**
     * TODO: Implement Await and hint progress + add legend to output + update styling + add background to logo
     * TODO: Refactor code such that the PDF dependencies can be a seperate chunk of code (PDF modules take up about 4MB, or 3/4 of the application code... )
     */
    downloadMap(e, control) {
      
      control.disable({ label: 'De pdf wordt samengesteld. Dit duurt even...' })

      setTimeout(() => {
        let title = `${this.municipalityLabel} - Locatieplan openbare laadpalen`
        let map = this.$store.map.getCanvas().toDataURL('image/png')

        generateExport({
          data: {
            title,
            map
          },
          callback: () => {
            control.enable()
          }
        })
      }, 1)
    }
  }
}
</script>

<style lang="scss">
.mapboxgl-canvas-container, .mapboxgl-canvas {
  &:active, &:focus {
    outline: none;
  }
}

.mapboxgl-marker {
  position: absolute;
  cursor: pointer;
}

.StyleToggle {
  display: block;
  width: 64px;
  height: 64px;
  background: transparent;
  cursor: pointer;
  border: 1px solid black;
  background-size: 200%;

  &--Light {
    background-image: url('~@/assets/image/style-light.png')
  }
  &--Satellite {
    background-image: url('~@/assets/image/style-satellite.png')
  }
}

.DataSideBar {
  z-index: 3;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 97%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  > * {
    pointer-events: all;
  }
}

</style>
