<template>
  <CollapsableSegment :label="label">

    <template v-slot:header>
      <ToggleSwitch class="m-0" v-model="visibility" />
    </template>

    <ul class="m-0 p-0 mb-3 list-unstyled">
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon style="position: absolute" color="#f6f6f4" />
          <Hexagon color="hsl(213, 55%, 75%)" :opacity="opacity" />
        </span> 
        <span class="mx-2 flex-grow-1">0 t/m {{ breakpoints[0] }} EV's</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon style="position: absolute" color="#f6f6f4" />
          <Hexagon color="hsl(213, 65%, 60%)" :opacity="opacity" />
        </span> 
        <span class="mx-2 flex-grow-1">> {{ breakpoints[0] }} t/m {{ breakpoints[1] }} EV's</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon style="position: absolute" color="#f6f6f4" />
          <Hexagon color="hsl(213, 80%, 50%)" :opacity="opacity" />
        </span> 
        <span class="mx-2 flex-grow-1">> {{ breakpoints[1] }} t/m {{ breakpoints[2] }} EV's</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon style="position: absolute" color="#f6f6f4" />
          <Hexagon color="hsl(213, 95%, 20%)" :opacity="opacity" />
        </span> 
        <span class="mx-2 flex-grow-1">> {{ breakpoints[2] }} EV's</span>
      </li>
      <li class="d-flex my-2 mr-3 align-items-center">
        <span class="position-relative">
          <Hexagon style="position: absolute" color="#f6f6f4" />
          <Hexagon color="hsl(23, 100%, 42%)" :opacity="opacity" />
        </span> 
        <span class="mx-2 flex-grow-1">Geselecteerd</span>
      </li>
    </ul>

  </CollapsableSegment>
</template>

<script>
import CollapsableSegment from '@/components/common/CollapsableSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import Hexagon from '@/components/common/Hexagon'
import { mapGetters } from 'vuex'

export default {
  name: 'LegendPrognose',
  components: {
    CollapsableSegment, ToggleSwitch, Hexagon
  },
  props: {
    label: {
      type: String,
      default: 'Prognose'
    }
  },
  data() {
    return {
      visibility: true,
      opacity: 0.7
    }
  },
  computed: {
    ...mapGetters('config', [
      'breakpoints'
    ]),
    ...mapGetters('prognose', [
      'ready'
    ])
  },
  watch: {
    visibility(visibility) {
      if (this.ready && this.hasPrognoseLayer()) {
        this.$store.map.setLayoutProperty('hexagons', 'visibility', visibility ? 'visible' : 'none')
      }
    }
  },
  mounted() {
    if (this.ready) {
      this.initTransparencyLink()
    } else {
      this.$watch('ready', this.initTransparencyLink)
    }
  },
  beforeDestroy() {
    if (this.ready) {
      this.$store.map.off('zoom', this.onZoom)
    }
  },
  methods: {
    hasPrognoseLayer() {
      return !! this.$store.map.getLayer('hexagons')
    },
    /**
     * The Hexagon transparency changes depending on the zoom level.
     *  The legend should change along with it
     */
    initTransparencyLink() {
      this.setHexoganOpacityFromZoom({ zoom : this.$store.map.getZoom() })
      this.$store.map.on('zoom', this.onZoom)
    },
    onZoom(event) {
      this.setHexoganOpacityFromZoom({ zoom: event.target.getZoom() })
    },
    /**
     * The opacity of the hexagons in the legend is matched with the 
     * opacity of the layer, which is based on the zoom level of the map
     */
    setHexoganOpacityFromZoom({ zoom }) {
      if (zoom < 10) {
        this.opacity = 0.7
      } else if (zoom > 13) {
        this.opacity = 0.4
      } else {
        this.opacity = 0.7 - (((zoom - 10) / 3) * 0.3)
      }
    }
  }
}
</script>

<style>

</style>