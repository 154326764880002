<template>
  <div>
    <StructuredValidation />
    <PrognoseLaadpunt />
    <FactsheetLaadpunt />
  </div>
</template>

<script>
import StructuredValidation from "@/components/tour/tours/StructuredValidation";
import PrognoseLaadpunt from "@/components/tour/action-driven-tooltips/PrognoseLaadpunt";
import FactsheetLaadpunt from "@/components/tour/action-driven-tooltips/FactsheetLaadpunt";

export default {
  components: { StructuredValidation, PrognoseLaadpunt, FactsheetLaadpunt },
}
</script>