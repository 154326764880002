<template>
  <Tour :begin="begin" :tour-name='tourName' :steps="steps" />
</template>

<script>
import {mapGetters} from "vuex";
import Tour from "@/components/tour/Tour";
import {hasSeenTour} from "@/valueholders/constants";

export default {
  name: 'PrognoseLaadpunt',
  components: { Tour },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),
    ...mapGetters('app', [
      'isAppReady',
    ]),
  },
  data () {
    return {
      begin: false,
      tourName: 'tooltip-factsheet-laadpunt',
      steps: []
    }
  },
  watch: {
    isAppReady(next) {
      if (next) {
        this.init()
      }
    },
  },
  methods: {
    init() {
      if (this.$router.currentRoute.name !== 'Factsheet') { return }

      // until we set the data in auth0, we use cookies
      const localStoreValue = localStorage.getItem(this.tourName)
      if (localStoreValue === hasSeenTour) { return }

      this.loadSteps();

      setTimeout(() => {
        this.startTour()
      }, 500)
    },
    startTour() {
      if (! (this.steps.length > 0)) {
        return
      }

      this.begin = true
    },
    loadSteps() {
      let steps = [
        {
          target: '#future-needs-charging-stations',
          header: {
            title: 'Laadpunten ipv palen',
          },
          content: `
            <p>Vanaf heden toont de factsheet het aantal laadpunten. Eerder spraken we over het aantal laadpalen, maar dit is aangepast naar het aantal laadpunten.</p>
          `,
          customOptions: {
            controls: true,
          },
          params: {
            placement: 'top',
            highlight: true,
          }
        }
      ];

      steps = steps.filter((step) => step.customOptions.onlyForDeployments ? step.customOptions.onlyForDeployments.includes(this.DeploymentCode) : true)

      this.setSteps({steps});
    },
    setSteps({steps}) {
      this.steps = steps
    },
    handleSkipTour() {
      this.hideTour()
    },
    hideTour() {
      console.log('hide tour')
      localStorage.setItem(this.tourName, hasSeenTour)
    }
  }
}
</script>
