/**
 * Import Dependency
 */


/**
 * Import API
 */
import { getChargingLocations } from '@/services/faunadb.js'

/**
 * Declare Variable
 */
const state = {
  layers: {},

  // Whether the map is done loading
  loaded: false,

  // The first map layer, from the bottom, containing labels. 
  // All additional layers are to be placed below this layer.
  symbolLayerId: null,

  // Charging locations
  locations: []
}

const getters = {
  getLoadedStatus: state => state.loaded,
  getLayers: state => state.layers,
  getSymbolLayerId: state => state.symbolLayerId,

  // 
  getLocations: state => state.locations
}
const actions = {
  async getChargingLocations({ commit }) {
    let response = await getChargingLocations()
    if (response && response.length !== 0) {
      commit('setChargingLocations', {
        locations: response
      })
    } else {
      commit('setChargingLocations', {
        locations: []
      })
    }
  }
}
const mutations = {
  setChargingLocations(state, { locations }) {
    state.locations = locations
      .filter(location => 
        ( ! location.data.deleted_at || location.data.deleted_at === null)
      )
      .map(location => 
        Object.assign(location.data, { ref: location.ref })
      )
  },
  setLoadedStatus(state, { status }) {
    state.loaded = status
  },
  setLayerAvailability(state, { layer, status }) {
    state.layers[layer] = !!status
  },
  setSymbolLayerId(state, { id }) {
    state.symbolLayerId = id
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
