

const faunadb = require('faunadb')
const q = faunadb.query


/**********************
 * Clients
 */
let readOnlyClient = null

export const activateReadOnlyClient = ({ key }) => {
  if (readOnlyClient === null) {
    readOnlyClient = new faunadb.Client({
      secret: key
    })
  }
}

export const isActive = () => {
  return readOnlyClient !== null
}

export const clearClients = () => {
  readOnlyClient = null
}

/**********************
 * ChargingLocations
 */

export const getChargingLocations = () => {
  return getAll({ indexRef: 'indexes/all_charginglocations', client: readOnlyClient })
}


/**********************
 * GENERIC FUNCTIONS
 * TODO: Replace with FaunaDB functions
 */

export const getOne = ({ ref, client }) => {
  return client.query(q.Get(q.Ref(ref)))
}

export const getAll = ({ indexRef, client }) => {
  return client
    .query(q.Paginate(q.Match(q.Ref(indexRef)), { size: 1000000 }))
    .then((response) => {
      // create new query out of refs. http://bit.ly/2LG3MLg
      const refs = response.data.map((ref) => {
        return q.Get(ref)
      })
      // then query the refs
      return client.query(refs)
        .then((ret) => {
          return ret
        })
    })
}

