<template>
  <div 
    v-if="loaded && available && accessible"
    class="Page--Viewer Viewer h-100 d-flex flex-column flex-grow-1">
    <ViewerNavBar />
    <div class="Viewer__Main d-flex">
      <ViewerSideBar class="Viewer__SideBar flex-shrink-1" />
      <div class="Viewer__Content d-flex position-relative flex-grow-1 flex-shrink-0">
        <Map />
      </div>
    </div>
    <Overlay />
  </div>
  <div v-else class="h-100 w-100 d-flex justify-content-center align-items-center">
    <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
    <div class="d-flex align-items-center">
      <b-spinner class="mr-3" label="Loading..."></b-spinner> 
      <span>Loading...</span>
    </div>
  </div>
</template>

<script>
/**
 * This component mainly takes care of the (responsive) layout
 */
import ViewerSideBar from '@/components/sidebar/ViewerSideBar'
import ViewerNavBar from '@/components/navbar/ViewerNavBar'
import Map from '@/components/map/Map'
import Overlay from '@/components/common/Overlay'

import { mapGetters, mapMutations } from 'vuex'

import { municipalityBySlug, slugByCode } from '@/services/municipalities'
// labelBySlug

export default {
  name: 'Viewer',
  components: {
    ViewerNavBar, 
    ViewerSideBar, 
    Map,
    Overlay
  },
  data() {
    return {
      slug: ''
    }
  },
  computed: {
    ...mapGetters('access', [
      'hasAccess', 
      'getActiveMunicipality',
      'canPreAccessPlanModeBy'
    ]),
    ...mapGetters('config', [
      'loaded', 
      'hasConfig', 
      'hasLayers',
      'getConfigBySlug',
    ]),
    /**
     * Whether the requested municipality config is available at all
     *  Exit early if the configs have not yet been loaded
     */
    available() {
      return this.loaded && this.hasConfig({ 
        slug: this.slug
      }) && this.hasLayers({
        slug: this.slug
      })
    },
    /**
     * Whether the prognose of this municipality is accessible to the current user 
     *  Note: If not, layer data is removed while loading the configuration through the lambda function
     */
    accessible() {
      if (! this.available) return false
      if (this.superuser) return true 
      if (this.admin) return true

      let config = this.getConfigBySlug({ 
        slug: this.slug
      })
      return (!!config.enabled) && (!!config.prognose.enabled)
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    admin() {
      return this.canPreAccessPlanModeBy({ slug: this.slug })
    }
  },

  /**
   * Navigate to the appropriate url upon a change in the active municipality
   *  The actual component & config changes are handled in the 
   *  router guard upon successful navigation
   */
  watch: {
    getActiveMunicipality(code) {
      let municipality = slugByCode({ code })
      // Avoid duplicate navigation
      if (this.slug !== municipality) {
        this.$router.push({
          name: 'Viewer',
          params: {
            municipality
          }
        })
        .catch(() => {
          // The router nags if we redirect the user in the beforeRouteUpdate navigation guard
        })
      }
    },
    /**
     * Verify after the configs have loaded that the municipality config is available & accessible
     *  It is possible that a user has rights to a municipality that has been disabled / removed.
     *  It is also possible that a municipality is available, but the prognose mode has been disabled.
     */
    loaded() {
      if ( ! this.available || ! this.accessible) {
        this.$router.push({ name: 'Home' })
      }
    },
    // slug() {
    //   this.setPageTitle({
    //     title: `EV Maps - ${ labelBySlug({ slug: this.slug }) }`
    //   })
    // }
  },

  /**
   * Handle a navigation between municipalities
   *  TODO: Figure out if there is anything else to do here
   */
  beforeRouteUpdate (to, from, next) {

    let slug = this.slug = to.params.municipality // slug

    // Redirect users without appropriate rights
    if (! this.superuser && ! this.hasAccess({ slug })) {
      next({ name: 'Home' })
      return
    }

    // Redirect users in case the municipality config is not loaded
    // Also redirect users if the prognose mode of the activated municipality is unavailable to them
    if (this.loaded && (! this.available || ! this.accessible)) {
      next({ name: 'Home' })
      return
    }

    // NOTE: We're not setting the municipality code, because that was what triggered the navigation
    this.selectConfig({ slug })
    
    next()
  },

  /**
   * Load the municipality information
   */
  created() {
    let slug = this.slug = this.$route.params.municipality

    // Redirect users without appropriate rights
    if (! this.superuser && ! this.hasAccess({ slug })) {
      this.$router.push({ name: 'Home' })
      return 
    }

    // Redirect users in case the municipality config is not loaded
    // Also redirect users if the prognose mode of the activated municipality is unavailable to them
    if (this.loaded && (! this.available || ! this.accessible)) {
      this.$router.push({ name: 'Home' })
      return
    }

    // Limit the title to just `EV Maps` to fit small screen sizes
    this.setPageTitle({
      title: `EV Maps`
    })

    // Get all public municipality details
    let municipality = municipalityBySlug({ slug })

    this.setActiveMunicipality({
      code: municipality.code
    })

    this.selectConfig({ slug })
  },

  methods: {
    ...mapMutations('config', [
      'selectConfig'
    ]),
    ...mapMutations('access', [
      'setActiveMunicipality'
    ]),
    ...mapMutations(['setPageTitle']),
  }
}
</script>

<style lang="scss">
.Viewer {
  &__SideBar {
    width: 300px;
  }
  &__Content {
    width: calc(100% - 300px);
  }
  &__Main {
    max-height: calc(100% - 80px); // 100% - height of nav bar
    height: 100%;
    overflow: hidden;
  }

  @media (max-height: 600px) {
    &__SideBar, &__Content {
      width: 50%;
    }
  }

  @media (max-width: 600px) {
    &__Main {
      flex-direction: column-reverse;
    }
    &__SideBar, &__Content {
      width: 100%;
    }
    &__Content {
      height: 60vh;
      min-height: 60vh;
      box-shadow: 0px 1px 0px 0px rgba(223,226,229,1);
    }
  }
}

</style>