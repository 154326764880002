<template>
  <div
    v-if="getValidatiorsCount > 0"
    class="ValidationCounter d-flex align-items-center"
  >
    <div class="ValidationCounter__Count ValidationCounter__Count-approved mr-1">
      <b-icon-check class="ValidationCounter__Count__Icon"/>
      {{ getApprovedCountBySelectedChargingpoint }}
    </div>
    <div class="ValidationCounter__Count ValidationCounter__Count-rejected mr-1">
      <b-icon-x class="ValidationCounter__Count__Icon" />
      {{ getRejectedCountBySelectedChargingpoint }}
    </div>

    van  {{ getValidatiorsCount }}
  </div>
</template>

<script>
import {BIconCheck, BIconX} from "bootstrap-vue";
import {mapGetters} from "vuex";
import {isLocatievoorstel} from "@/services/statusTranslations";

export default {
  components: {BIconCheck,BIconX},
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint'
    }),
    ...mapGetters('planmode', [
      'getCommentsBySelectedChargingpoint',
      'getApprovedCountBySelectedChargingpoint',
      'getRejectedCountBySelectedChargingpoint',
      'getValidatiorsCount',
    ]),
    isLocatievoorstel() {
      if (this.chargingpoint !== undefined) {
        return isLocatievoorstel({status: this.chargingpoint.data.properties.status})
      }

      return false
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.ValidationCounter {
  &__Count {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding-right: 4px;

    &-approved {
      .b-icon.bi {
        color: #5d8c30 !important;
      }
    }
    &-rejected {
      .b-icon.bi {
        color: map_get($theme-colors, 'danger');
      }
    }

    &__Icon {
      width: 20px;
      height: 20px;
      margin-right: -5px;
      margin-bottom: -4px;
    }
  }
}
</style>