/**
 * Import Dependency
 */

/**
 * Import API
 */

import { CHARGINGPOINT_STATUS, VALIDATION_VOTE } from "@/valueholders/enums";

/**
 * Declare Variable
 */
const state = {
  active : false,
  selectedChargingPointRefId : null,

  /**
   * All charging points
   */
  chargingpoints: [],
  filters: {
    'priority-fase': {
      id: 'priority-fase',
      label: "Plaatsingsvolgorde",
      icon: "chart-bar-regular",
      unit: "",
      min: 0,
      max: 1000000,
      from: 0,
      to: 0,
      active: false
    },
    'to-validate': {
      id: 'to-validate',
      label: "Door mij te valideren",
      icon: "check-double-solid",
      unit: "",
      min: 0,
      max: 1000000,
      from: 0,
      to: 0,
      active: false
    },
    'already-validated': {
      id: 'already-validated',
      label: "Door mij reeds gevalideerd",
      icon: "check-double-solid",
      unit: "",
      min: 0,
      max: 1000000,
      from: 0,
      to: 0,
      active: false
    },
    'to-decide': {
      id: 'to-decide',
      label: "Door mij te beslissen",
      icon: "exchange-alt-solid",
      unit: "",
      min: 0,
      max: 1000000,
      from: 0,
      to: 0,
      active: false
    },
  },

  /**
   * There is a layer per charging point type
   */
  layers: null,

  /*
  * All comments
  */
  comments: [],
}

const getters = {
  isActive: state => !!state.active,
  isChargingpointSelected: state => state.selectedChargingPointRefId !== null,
  getSelectedChargingpointRefId: state => state.selectedChargingPointRefId,
  getSelectedChargingpoint: state => state.chargingpoints.find(chargingpoint => chargingpoint.ref['@ref'].id === state.selectedChargingPointRefId),
  getSelectedChargingpointUuid: (state, getters) => getters['getSelectedChargingpoint']?.data.uuid,
  getChargingPoints: state => state.chargingpoints.filter(chargingpoint => !chargingpoint.data.deleted_at),
  getChargingPointById: state => ({ id }) => {
    return state.chargingpoints.find(chargingpoint => chargingpoint.data.properties.id === id)
  },
  getChargingPointByRefId : state => ({ refId }) => {
    return state.chargingpoints.find(chargingpoint => chargingpoint.ref['@ref'].id === refId)
  },
  getPlannedChargingPoints : state => {
    return state.chargingpoints.filter(chargingpoint => chargingpoint.data.properties.status === CHARGINGPOINT_STATUS.DEFINITIVE || chargingpoint.data.properties.status === CHARGINGPOINT_STATUS.SUGGESTION)
  },
  /**
   * Get the next chargingpoint id, which should be 1 more than the highest id of the current active chargingpoints
   */
  getNextChargingpointId: (state, getters) => {
    return getters.getChargingPoints.reduce((number, chargingpoint) => {
      return Math.max(number, chargingpoint.data.properties.id)
    }, 0) + 1
  },
  getChargingPointIndexByRefId: state => ({ refId }) => {
    return state.chargingpoints.findIndex(chargingpoint => chargingpoint.ref['@ref'].id === refId)
  },
  isBeheerder : (_, getters) =>  ({ userId }) => getters['getChargingPoints']
    .filter(chargingpoint => chargingpoint.data.properties.beheerder !== undefined)
    .findIndex(chargingpoint => chargingpoint.data.properties.beheerder.user_id === userId) !== -1
  ,
  getValidatiorsCount: (state, getters) => getters['getSelectedChargingpoint']?.data.properties.validators?.length || 0,

  // TODO: Fix hackjob (e.g. no fallback for missing stuff, etc)
  getFilters: state => Object.values(state.filters),
  getFilterById: state => ({ id }) => {
    return state.filters[id]
  },

  /**
   * Chargingpoint layer state
   */
  getLayers: state => state.layers,

  /**
   * Chargingpoint comments
   */
  getComments: state => state.comments,
  getCommentIndexByRefId: state => ({ refId }) => state.comments.findIndex(comment => comment.ref['@ref'].id === refId),
  getCommentsByChargingpointUuid: (state) => ({chargingpointUuid}) => state.comments.filter(comment => comment.data.chargingpointUuid === chargingpointUuid),
  hasUserAlreadyVoted : state => ({ chargingpointUuid, userId }) => state.comments
    .filter(comment => comment.data.chargingpointUuid === chargingpointUuid)
    .filter(comment => comment.data.UserId === userId)
    .filter(comment => comment.data.deleted_at === undefined)
    .some(comment => !!comment.data.validation),
  getCommentsBySelectedChargingpoint: (state, getters) => state.comments.filter(comment => comment.data.chargingpointUuid === getters['getSelectedChargingpointUuid']),
  // todo: get only last vote of a validator
  getApprovedCountBySelectedChargingpoint: (state, getters) =>
    getters['getCommentsBySelectedChargingpoint']
      .filter(comment => comment.data.deleted_at === undefined)
      .filter(comment => comment.data.validation === VALIDATION_VOTE.APPROVED)
      .filter(comment => getters['getSelectedChargingpoint'].data.properties.validators.some((validator) => comment.data.UserId === validator.user_id))
      .length,
  getRejectedCountBySelectedChargingpoint: (state, getters) =>
    getters['getCommentsBySelectedChargingpoint']
      .filter(comment => comment.data.deleted_at === undefined)
      .filter(comment => comment.data.validation === VALIDATION_VOTE.REJECTED)
      .filter(comment => getters['getSelectedChargingpoint'].data.properties.validators.some((validator) => comment.data.UserId === validator.user_id))
      .length,
}
const actions = {
  /**
   * Add or update a chargingpoint that was saved to the Data Storage after the initial load event
   *  This may be triggered due to an action by the current user, or by another user through a Pusher event
   */
  addOrUpdateChargingPoint({ commit, getters, rootGetters }, { chargingpoint }) {

    // Ignore actions that relate to chargingpoints from a municipality that is no longer active
    if (rootGetters['access/getActiveMunicipality'] !== chargingpoint.data.code) return

    let refId = chargingpoint.ref['@ref'].id
    let index = getters.getChargingPointIndexByRefId({ refId })

    if (index !== -1) {
      commit('updateChargingpoint', { chargingpoint, index })
    } else {
      commit('addChargingpoint', { chargingpoint })
    }
  },
  // eslint-disable-next-line no-unused-vars
  async fetchCommentsByChargingpointUuid({ _, dispatch }, { token, code, chargingpointUuid }) {
    const api = await fetch('/api/commentsload', {
      method: "POST",
      headers: {
        authorization: 'Bearer ' + token
      },
      body: JSON.stringify({ chargingpointUuid, code })
    });

    return await api.json()
      .then(response => {
        if (response.error_description) {
          throw response.error_description
        }

        if (response.comments) {
          response.comments.forEach(comment => dispatch('addOrUpdateComment', ({comment})))
        }
      })
  },
  // eslint-disable-next-line no-unused-vars
  async fetchCommentsByUser({ _, dispatch }, { token, code, userId }) {
    const api = await fetch('/api/commentsloadbyuser', {
      method: "POST",
      headers: {
        authorization: 'Bearer ' + token
      },
      body: JSON.stringify({ userId, code })
    });

    return await api.json()
      .then(response => {
        if (response.error_description) {
          throw response.error_description
        }

        if (response.comments) {
          response.comments.forEach(comment => dispatch('addOrUpdateComment', ({comment})))
        }
      })
  },
  addOrUpdateComment({ commit, getters }, { comment }) {
    let refId = comment.ref['@ref'].id
    let index = getters.getCommentIndexByRefId({ refId })

    if (index !== -1) {
      commit('updateComment', { comment: comment, index })
    } else {
      commit('addComment', { comment: comment })
    }
  },
  deleteComment({ commit, getters }, { comment }) {
    let refId = comment.ref['@ref'].id
    let index = getters.getCommentIndexByRefId({ refId })
    commit('updateComment', { comment: comment, index })
  },

  selectChargingPoint({commit}, { refId }) {
    commit('setSelectedChargingPoint', { refId })
  },
  deselectChargingPoint({commit}) {
    commit('setSelectedChargingPoint', { refId: null })
  }
}
const mutations = {
  setActiveState(state, { active }) {
    state.active = !!active
  },
  /**
   * Upon loading the Chargingpoints data layer, or switching municipalities, the chargingpoints are loaded / replaced
   */
  setChargingPoints(state, { chargingpoints }) {
    state.chargingpoints = chargingpoints
  },
  setSelectedChargingPoint(state, { refId }) {
    state.selectedChargingPointRefId = refId
  },
  addChargingpoint(state, { chargingpoint }) {
    state.chargingpoints.push(chargingpoint)
  },
  updateChargingpoint(state, { chargingpoint, index }) {
    state.chargingpoints.splice(index, 1, chargingpoint)
  },

  addComment(state, { comment }) {
    state.comments.push(comment)
  },
  updateComment(state, { comment, index }) {
    state.comments.splice(index, 1, comment)
  },

  /**
   * TODO: Is this future proof?
   */
  setFilter(state, { id, options }) {
    state.filters[id] = options
  },

  /**
   * Set the layer details (e.g. which types of charging points are visible)
   */
  storeActiveLayerVisibility(state, { layers }) {
    state.layers = layers
  }

  // /**
  //  * Add or update a chargingpoint that was saved to the Data Storage after the initial load event
  //  *  This may be triggered due to an action by the current user, or by another user through a Pusher event
  //  */
  // addOrUpdateChargingPoint(state, { chargingpoint }) {
  //   let refId = chargingpoint.ref['@ref'].id
  //   let index = state.chargingpoints.findIndex(chargingpoint => chargingpoint.ref['@ref'].id === refId)

  //   if (index !== -1) {
  //     state.chargingpoints.splice(index, 1, chargingpoint)
  //   } else {
  //     state.chargingpoints.push(chargingpoint)
  //   }
  // }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
