/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  connectionPoints: [],
  locationData: [],
  selectedLocation: null,
}

const getters = {
  getSelectedConnectionPoints: (state) => {
    return state.connectionPoints
      .filter(connectionPoint => connectionPoint.data.location === state.selectedLocation)
      .map(connectionPoint => connectionPoint.data)
  },
  getSelectedLocationData: (state) => {
    return state.locationData
      .find(location => location.location === state.selectedLocation)
  }
}

const actions = {
  selectLocation({commit}, locationName) {
    commit('setSelectedLocation', {locationName})
  },
  deselectLocation({commit}) {
    commit('setSelectedLocation', {locationName: null})
  }
}

const mutations = {
  addConnectionPoints: function (state, {connectionPoints}) {
    connectionPoints.forEach((newConnectionPoint) => {
      let index = state.connectionPoints.findIndex(inStateConnectionPoint => newConnectionPoint.data.evse_id === inStateConnectionPoint.data.evse_id)
      if (index === -1) {
        state.connectionPoints.push(newConnectionPoint)
      }
    })
  },
  addLocationData: function (state, {locationData}) {
    let index = state.locationData.findIndex(stateLocationData => locationData.location === stateLocationData.location)
    if (index === -1) {
      state.locationData.push(locationData)
    }
  },
  setSelectedLocation(state, { locationName }) {
    state.selectedLocation = locationName
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
