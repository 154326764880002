<template>
  <div class="PlanModeSearch d-flex flex-column flex-grow-1 flex-shrink-1">

    <div
      class="PlanModeSearch__filterOverlay PMS-Filter"
      :class="{ 'PMS-Filter--active': showFilter }"
    >
      <div class="PMS-Filter__header">
        <span>
          <SvgIcon icon="filter-regular" class="PMS-Filter__icon" />
          <h4 class="PMS-Filter__title">Filter</h4>
        </span>
        <span @click="toggleFilter">
          <SvgIcon
            icon="times-regular"
            class="PMS-Filter__icon PMS-Filter__close"
          />
        </span>
      </div>
      <div class="PMS-Filter__body">

        <div class="PMS-Filter-Item">
          <div class="PMS-Filter-Item__head">
            <SvgIcon icon="chart-bar-regular" /> <span>Plaatsingsvolgorde</span>
          </div>
          <div class="d-flex pt-2">
            <span class="mr-3">Highlight Fase 1</span> <ToggleSwitch v-model="applyFirstFilter" />
          </div>
          <div class="d-flex pt-2" v-if="isBraLiProvincieCode">
            <span class="mr-3">Highlight Fase 2</span> <ToggleSwitch v-model="applySecondFilter" />
          </div>
        </div>

        <div class="PMS-Filter-Item">
          <div class="PMS-Filter-Item__head">
            <SvgIcon icon="user-check-solid" /> <span>Door mij te valideren</span>
          </div>
          <div class="d-flex pt-2">
            <span class="mr-3">Nog te valideren</span> <ToggleSwitch v-model="applyThirdFilter" />
          </div>
        </div>

        <div class="PMS-Filter-Item">
          <div class="PMS-Filter-Item__head">
            <SvgIcon icon="check-double-solid" /> <span>Door mij reeds gevalideerd</span>
          </div>
          <div class="d-flex pt-2">
            <span class="mr-3">Reeds gevalideerd</span> <ToggleSwitch v-model="applyFourthFilter" />
          </div>
        </div>

        <div
          v-if="isBeheerder({userId: currentUserId})"
          class="PMS-Filter-Item"
        >
          <div class="PMS-Filter-Item__head">
            <SvgIcon icon="check-double-solid" /> <span>Beslissingen</span>
          </div>
          <div class="d-flex pt-2">
            <span class="mr-3">Nog te nemen</span> <ToggleSwitch v-model="applyFithFilter" />
          </div>
        </div>

        <!-- todo:: make it loop again, but the right way; with more abstracrion -->
        <!--          <div
                    class="PMS-Filter-Item"
                    v-for="(filter) in filters"
                    v-bind:key="filter.label"
                  >
                    <div class="PMS-Filter-Item__head">
                      <SvgIcon :icon="filter.icon" /> <span>{{ filter.label }}</span>
                    </div>
                    <div class="d-flex pt-2">
                      <span class="mr-3">Highlight Fase 1</span> <ToggleSwitch v-model="applyFirstFilter" />
                    </div>
                    <div class="d-flex pt-2" v-if="isBraLiProvincieCode">
                      <span class="mr-3">Highlight Fase 2</span> <ToggleSwitch v-model="applySecondFilter" />
                    </div>
                   &lt;!&ndash; <div class="PMS-Filter-Item__content">
                      <span class="label">van</span>
                      <input
                        class="input"
                        type="number"
                        :placeholder="filter.min"
                        v-model="filters[idx].from"
                        :min="filter.min"
                        :max="filter.max"
                      />
                      <span class="label" v-show="filter.unit">{{ filter.unit }}</span
                      ><span class="label">tot</span>
                      <input
                        class="input"
                        type="number"
                        :placeholder="filter.max"
                        v-model="filters[idx].to"
                        :min="filter.min"
                        :max="filter.max"
                      />
                      <span class="label" v-show="filter.unit">{{ filter.unit }}</span>
                    </div> &ndash;&gt;
                  </div>-->
        <button class="PMS-Filter__save" @click='toggleFilter'>Toepassen</button>
      </div>
    </div>
    <div class="PlanModeSearch__header p-4">
      <Fuse
        :list="list"
        :options="fuseOptions"
        placeholder="Zoek op nummer, adres of omschrijving"
        @resultsChanged="handleResultsChanged"
      />
      <span @click="toggleFilter" class="PlanModeSearch__filter">
        <SvgIcon icon="filter-regular" />
      </span>

      <ul class="PMS-Active-Filter" v-show="filterTags.length !== 0">
        <li
          class="PMS-Active-Filter__tag"
          v-for="filter in filterTags"
          :key="filter.label"
        >
          <!-- van {{ filter.from }} tot {{ filter.to }} {{ filter.unit }} -->
          {{ filter.label }}
          <span @click='resetFilter({ id: filter.id, value: filter.value } )'>
            <SvgIcon icon="times-regular" />
          </span>
        </li>
      </ul>
    </div>

    <ul v-if="results" class="SearchResults py-3">
      <li
        class="SearchResult pr-3 pl-5 py-1"
        v-for="chargingpoint in results"
        :key="chargingpoint.refId"
        :class="{
          SearchResult__Rejected: chargingpoint.status === 'rejected',
          SearchResult__FastChargerRejected: chargingpoint.status === 'fastcharger-rejected',
          SearchResult__Suggestion: chargingpoint.status === 'suggestion',
          SearchResult__Definitive: chargingpoint.status === 'definitive',
          SearchResult__InProgress: chargingpoint.status === 'in-progress',
          SearchResult__Realized: chargingpoint.status === 'realized',
          SearchResult__RealizedPrivate: chargingpoint.status === 'realized-private',
          SearchResult__Alert: chargingpoint.status === 'alert',
        }"
        @click="handleSelectChargingpoint({ chargingpoint })"
      >
        {{ chargingpoint.id }}:
        {{
          chargingpoint.address ||
          chargingpoint.remark ||
          chargingpoint.statusLabel
        }}
        <span class="SearchResult__Icon"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import Fuse from "@/components/common/Fuse";
import SvgIcon from "@/components/common/SvgIcon";
import ToggleSwitch from '@/components/form/ToggleSwitch';

import {mapActions, mapGetters, mapMutations} from "vuex";
import {statusSlugToLabel} from "@/services/statusTranslations";
import userMixin from "@/mixins/common/userMixin";
import privilegesMixin from "@/mixins/common/privilegesMixin";
import {uniqueIntId} from "@/helpers/number";
import {EventBus} from "@/services/eventbus";

export default {
  name: "PlanModeSearch",
  mixins: [userMixin, privilegesMixin],
  components: { Fuse, SvgIcon, ToggleSwitch },
  data() {
    return {
      /**
       * Fuse.js options
       */
      fuseOptions: {
        threshold: 0.2,
        keys: ["id", "remark", "statusLabel", "address"],
      },
      /**
       * Generated by Fuse
       */
      results: [],
      /**
       * Priority filter
       */
      showFilter: false,
      applyFilter: false,
      applyFirstFilter: false,
      applySecondFilter: false,
      applyThirdFilter: false,
      applyFourthFilter: false,
      applyFithFilter: false,
      filters: [
        {
          id: 'priority-fase',
          label: "Plaatsingsvolgorde",
          icon: "chart-bar-regular",
          unit: "",
          min: 0,
          max: 1000000,
          from: 0,
          to: 0,
        },
        {
          id: 'to-validate',
          label: "door mij te valideren",
          icon: "user-check-solid",
          unit: "",
          min: 0,
          max: 1000000,
          from: 0,
          to: 0,
        },
        {
          id: 'already-validated',
          label: "Door mij reeds gevalideerd",
          icon: "check-double-solid",
          unit: "",
          min: 0,
          max: 1000000,
          from: 0,
          to: 0,
          active: false
        },
        {
          id: 'to-decide',
          label: "Door mij te beslissen",
          icon: "exchange-alt-solid",
          unit: "",
          min: 0,
          max: 1000000,
          from: 0,
          to: 0,
          active: false
        },
      ],
    };
  },
  computed: {
    ...mapGetters("planmode", ["getChargingPoints", "getFilterById", "hasUserAlreadyVoted", "isBeheerder"]),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    /**
     * Map chargingpoint objects to searchable items
     */
    list() {

      // Ignore filters that are not active
      let filters = this.filters.filter(filter => filter.active)

      // reset the feature state
      if (this.$store.map?.getSource("chargingpoints")) {
        this.$store.map.removeFeatureState({
          source: 'chargingpoints'
        });
      }

      return (
        this.getChargingPoints
          // Apply filter options
          .filter((chargingpoint) => {
            let result = filters.every(filter => {
              if (filter.id === 'priority-fase') {

                // If the priority filter is applied, only planned locations are relevant
                if (chargingpoint.data.properties.status !== 'definitive') {
                  return false
                }
                // Required data
                if (!chargingpoint.data.prio) {
                  return false
                }

                // Check the position in the prioritized planning
                let fase = chargingpoint.data.prio.fase || 0

                return (fase >= parseInt(filter.from, 10) && fase <= parseInt(filter.to, 10))
              }

              if (filter.id === 'to-validate') {
                if (chargingpoint.data.properties.status !== 'suggestion') {
                  return false
                }

                if (! (chargingpoint.data.properties.validators?.length > 0)) {
                  return false
                }

                const isValidator = chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === this.currentUserId) !== -1
                if (! isValidator) {
                  return false
                }

                const hasVoted = this.hasUserAlreadyVoted({chargingpointUuid: chargingpoint.data.uuid, userId: this.currentUserId})
                const needsToBeVotedByValidator = hasVoted === false

                this.$store.map.setFeatureState(
                  { source: 'chargingpoints', id: uniqueIntId({ chargingpoint }) },
                  {
                    needsToBeVotedByValidator
                  }
                )

                return needsToBeVotedByValidator
              }

              if (filter.id === 'already-validated') {
                if (! (
                  chargingpoint.data.properties.status === 'suggestion' ||
                  chargingpoint.data.properties.status === 'definitive' ||
                  chargingpoint.data.properties.status === 'rejected'
                )) {
                  return false
                }

                if (! (chargingpoint.data.properties.validators?.length > 0)) {
                  return false
                }

                const isValidator = chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === this.currentUserId) !== -1
                if (! isValidator) {
                  return false
                }

                const hasVoted = this.hasUserAlreadyVoted({chargingpointUuid: chargingpoint.data.uuid, userId: this.currentUserId})
                const hasAlreadyValidated = hasVoted === true

                this.$store.map.setFeatureState(
                  { source: 'chargingpoints', id: uniqueIntId({ chargingpoint }) },
                  {
                    hasAlreadyValidated
                  }
                )

                return hasAlreadyValidated
              }

              if (filter.id === 'to-decide') {
                if (chargingpoint.data.properties.status !== 'suggestion') {
                  return false
                }

                if (! chargingpoint.data.properties.beheerder) {
                  return false
                }

                const isBeheerder = chargingpoint.data.properties.beheerder.user_id === this.currentUserId
                if (! isBeheerder) {
                  return false
                }

                if (! chargingpoint.data.properties.isValidated) {
                  return false
                }

                this.$store.map.setFeatureState(
                  { source: 'chargingpoints', id: uniqueIntId({ chargingpoint }) },
                  {
                    needsToBeDecided: true
                  }
                )

                return true
              }

              return true
            })

            return result
          })

          // (for now) Only superuser get access to private charging points
          .filter(
            (chargingpoint) =>
              this.superuser ||
              !["realized-private"].includes(
                chargingpoint.data.properties.status
              )
          )
          .map((chargingpoint) => {
            return {
              id: chargingpoint.data.properties.id,
              refId: chargingpoint.ref["@ref"].id,
              status: chargingpoint.data.properties.status,
              statusLabel: statusSlugToLabel({
                status: chargingpoint.data.properties.status,
              }),
              remark: chargingpoint.data.properties.remark,
              coordinates: chargingpoint.data.coordinates,
              address: chargingpoint.data.address
                ? chargingpoint.data.address.simple_address
                : "",
            };
          })
          .sort((a, b) => (a.id < b.id ? -1 : 1))
      );
    },
    activeFilters() {
      return this.filters.filter((f) => f.active)
    },
    filterTags() {
      let tags = []

      if (this.applyFirstFilter) {
        tags.push({
          label: 'Plaatsing gedurende fase 1',
          id: 'priority-fase',
          value: 1
        })
      }
      if (this.applySecondFilter) {
        tags.push({
          label: 'Plaatsing gedurende fase 2',
          id: 'priority-fase',
          value: 2
        })
      }
      if (this.applyThirdFilter) {
        tags.push({
          label: 'door mij te valideren',
          id: 'to-validate',
          value: 3
        })
      }
      if (this.applyFourthFilter) {
        tags.push({
          label: 'door mij reeds gevalideerd',
          id: 'already-validated',
          value: 4
        })
      }
      if (this.applyFithFilter) {
        tags.push({
          label: 'door mij te beslissen',
          id: 'to-decide',
          value: 5
        })
      }

      return tags
    },
    isBraLiProvincieCode() {
      console.log('isBraLiProvincieCode', this.getActiveMunicipality, ['9006', '9007'].includes(this.getActiveMunicipality))
      return ['9006', '9007'].includes(this.getActiveMunicipality)
    }
  },
  watch: {
    /**
     * TODO: Fix state management hackjob
     */
    filters: {
      handler: (filters) => {
        filters.forEach(filter => {
          this.setFilter({ id: filter.id, options: filter })
          
          if (filter.id === 'priority-fase') {
            if (! filter.active) {
              this.applyFirstFilter = false
              this.applySecondFilter = false
            }
          }
          if (filter.id === 'to-validate') {
            if (! filter.active) {
              this.applyThirdFilter = false
            }
          }
          if (filter.id === 'already-validated') {
            if (! filter.active) {
              this.applyFourthFilter = false
            }
          }
          if (filter.id === 'to-decide') {
            if (! filter.active) {
              this.applyFithFilter = false
            }
          }
        })
      },
      deep: true
    },
    applyFirstFilter() {
      this.updateFilter()
    },
    applySecondFilter() {
      this.updateFilter()
    },
    applyThirdFilter() {
      this.updateFilter()
    },
    applyFourthFilter() {
      this.updateFilter()
    },
    applyFithFilter() {
      this.updateFilter()
    },
  },
  async created() {
    // TODO: Fix this state management hackjob
    this.filters.forEach(( filter, index) => {
      this.filters[index] = this.getFilterById({ id: filter.id })
    })

    if (this.isBraLiProvincieCode && this.filters[0].to === 2) {
      this.applySecondFilter = true
    } else {
      this.applySecondFilter = false
    }
    if (this.filters[0].from === 1) {
      this.applyFirstFilter = true
    }

    // get user comments
    const token = await this.$auth.getTokenSilently();
    await this.fetchCommentsByUser({
      token: token,
      code: this.getActiveMunicipality,
      userId: this.currentUserId
    })
      .catch(console.log)
  },
  destroyed() {
    this.filters.forEach((filter, index) => this.resetFilter({ id: filter.id, value: index + 1 }))
  },
  methods: {
    ...mapActions("planmode", ["fetchCommentsByUser", "selectChargingPoint"]),
    ...mapMutations('planmode', ["setFilter"]),
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    updateFilter() {
      if (this.applyFirstFilter || (this.isBraLiProvincieCode && this.applySecondFilter)) {

        this.filters[0].to = this.isBraLiProvincieCode && this.applySecondFilter ? 2 : 1 
        this.filters[0].from = this.applyFirstFilter ? 1 : 2 
        this.filters[0].active = true

      } else {
        this.filters[0].active = false
        this.filters[0].to = 0
        this.filters[0].from = 0
      }

      if (this.applyThirdFilter) {
        this.filters[1].active = true
        this.filters[1].to = 1
        this.filters[1].from = 0
      } else {
        this.filters[1].active = false
        this.filters[1].to = 0
        this.filters[1].from = 0
      }

      if (this.applyFourthFilter) {
        this.filters[2].active = true
        this.filters[2].to = 1
        this.filters[2].from = 0
      } else {
        this.filters[2].active = false
        this.filters[2].to = 0
        this.filters[2].from = 0
      }

      if (this.applyFithFilter) {
        this.filters[3].active = true
        this.filters[3].to = 1
        this.filters[3].from = 0
      } else {
        this.filters[3].active = false
        this.filters[3].to = 0
        this.filters[3].from = 0
      }
    },

    resetFilter({ id, value }) {
      let filter = this.filters.find(f => f.id === id);

      if (filter.id === 'priority-fase') {
        if (value === 1) {
          this.applyFirstFilter = false
        } else {
          this.applySecondFilter = false
        }
        this.updateFilter()
        filter = this.filters[0]
      } else {
        filter.to = 0,
        filter.from = 0
        filter.active = false
      }

      if (filter.id === 'to-validate') {
        this.applyThirdFilter = false
      }

      if (filter.id === 'already-validated') {
        this.applyFourthFilter = false
      }

      if (filter.id === 'to-decide') {
        this.applyFithFilter = false
      }

      this.setFilter({
        id: filter.id,
        options: filter
      })
    },
    /**
     * Handle results from Fuse.js
     */
    handleResultsChanged({ results }) {
      this.results = results.map((result) => result.item);
    },
    /**
     * Handle click events
     */
    handleSelectChargingpoint({ chargingpoint }) {
      if (this.$store.map) {
        this.$store.map.flyTo({
          center: chargingpoint.coordinates,
          zoom: 16,
        });
      }

      EventBus.$emit('select-chargingpoint', chargingpoint.refId)
    },
  },
};
</script>

<style lang="scss">
.PlanModeSearch {
  $hover-color: #4f5c6e;
  $PMS-padding: 1.5rem;
  $PMS-blue: #2c3e50;
  max-height: calc(100% - 49px);
  overflow: hidden;

  .PMS-Active-Filter {
    list-style: none;
    margin: 0;
    padding: 0;
    grid-column: 1 / span 2;
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;

    &__tag {
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      padding: 0 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-content: center;
      }

      .svg-container {
        cursor: pointer;
        margin-left: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover, &:focus, &:active {
        .svg-container {
          color: $hover-color;
        }
      }

      &:focus, &:active {
        color: $hover-color;
        border: 1px solid black;
      }
    }
  }
  .PMS-Filter {
    position: absolute;
    background-color: white;
    display: block;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: $PMS-padding 0;
    transition: left ease-in-out 0.2s;
    color: #2c3e50;

    &__header {
      display: grid;
      grid-template-columns: 1fr auto;
      border-bottom: 1px solid #bbc3ce;
      padding-bottom: 1rem;
      margin-left: $PMS-padding;
      margin-right: $PMS-padding;

      span {
        display: flex;
        align-content: center;
      }
    }

    &__body {
      padding: ($PMS-padding / 2) $PMS-padding;
    }

    &__title {
      display: inline-block;
      margin: 0;
      margin-left: 0.5rem;
      font-size: 1.25rem;
    }

    &__save {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      background-color: transparent;
      padding: 0.5rem 1rem;

      &:hover, &:focus, &:active {
        border: 1px solid black;
        box-shadow: inset 0 0 0 1px black;
      }
    }

    &__icon {
      font-size: 1.3rem;
    }

    &__close {
      cursor: pointer;
      &:hover {
        color: $hover-color;
      }
    }

    &--active {
      left: 0;
    }
  }

  .PMS-Filter-Item {
    margin-bottom: 1.5rem;

    &__head {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;

      .svg-container {
        font-size: 1.3rem;
      }
    }
    &__content {
      display: flex;
      align-items: center;

      input {
        width: 5ch;
        flex-grow: 1;
        flex-shrink: 1;
      }

      span,
      input {
        margin-left: 0.5rem;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }

  &__filter {
    font-size: 1.3rem;
    line-height: 0;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 0 0.4em;
    display: flex;
    align-items: center;

    &:hover {
      color: $hover-color;
      border: 1px solid black;
      box-shadow: inset 0 0 0 1px black;
    }
  }
  .SearchResults {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    border-top: 1px solid darken(#E8EAF1, 10%);

    .SearchResult {
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 4px;
        left: 1.5rem;
        height: 16px;
        width: 16px;
        background: transparent;
        background-repeat: no-repeat;
        border-radius: 50%;
        opacity: 0.9;
        background-size: cover;
      }

      &__Rejected:after {
        background-image: url("~@/assets/image/legend/chargingpoint-rejected.png");
      }
      &__FastChargerRejected:after {
        background-image: url("~@/assets/image/legend/chargingpoint-fastcharger-rejected.png");
      }
      &__Suggestion:after {
        background-image: url("~@/assets/image/legend/chargingpoint-suggestion.png");
      }
      &__Definitive:after {
        background-image: url("~@/assets/image/legend/chargingpoint-definitive.png");
      }
      &__InProgress:after {
        background-image: url("~@/assets/image/legend/chargingpoint-in-progress.png");
      }
      &__Realized:after {
        background-image: url("~@/assets/image/legend/chargingpoint-realized.png");
      }
      &__RealizedPrivate:after {
        background-image: url("~@/assets/image/legend/chargingpoint-realized-private.png");
      }
      &__Alert:after {
        background-image: url("~@/assets/image/legend/chargingpoint-alert.png");
      }

      &:hover {
        background: darken(#e8eaf1, 10%);
        color: darken(#e8eaf1, 40%);

        &:after {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>