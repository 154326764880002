<template>
  <a :href="'mailto: ${getEmailAddressSupport}'">{{ getEmailAddressSupport }} </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters('deployment', [
      'getEmailAddressSupport'
    ]),
  }
}
</script>