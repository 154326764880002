<template>
  <vSelect
    class="MunicipalitySelection"
    :disabled="disabled"
    :options="options" 
    :clearable="false" 
    :reduce="option => option.value" 
    v-model="model" />
</template>

<script>
import vSelect from '@/components/form/vSelect'

import { mapGetters, mapMutations } from 'vuex'
import { labelByCode } from '@/services/municipalities'

export default {
  name: 'MunicipalitySelection',
  props: {
    filter: {
      type: String,
      default: null
    }
  },
  components: { vSelect },
  computed: {
    ...mapGetters('access', [
      'municipalityCodeList',
      'getActiveMunicipality',
      'hasAdminAccess'
    ]),
    ...mapGetters('prognose', [
      'ready'
    ]),
    ...mapGetters('config', [
      'getAvailableConfigCodes',
      'isPrognoseEnabledByCode',
      'isUploadEnabledByCode',
      'isFactsheetEnabledByCode',
      'isExportEnabledByCode',
      'isStoredByCode',
      'hasLayers'
    ]),
    /**
     * When looking at the map we want to avoid rapid (e.g. sub second) switching between municipalities
     *  It's hard to actually do, but if you manage it, it can crash the viewer
     */
    disabled() {
      return this.$route.name === 'Viewer' && ! this.ready
    },
    options() {
      let list = []

      if (this.superuser) {
        // A superuser may access all available municipalities, as long as they have been stored once
        list = this.getAvailableConfigCodes.filter(code => this.isStoredByCode({ code }))
        
      } else {
        // Go through all municipalities the user has access to
        list = this.municipalityCodeList
          // Remove municipalities from the list if they are disabled
          .filter(code => this.getAvailableConfigCodes.includes(code))

        
        // Apply an additional feature based filter, specified by the filter prop
        switch (this.filter) {
          case 'export': 
            list = list.filter(code => {
              return this.isExportEnabledByCode({ code }) || ( this.hasAdminAccess({ code }) && this.hasLayers({ code }))
            })            
            break;
          case 'prognose':
            list = list.filter(code => {
              return this.isPrognoseEnabledByCode({ code }) || ( this.hasAdminAccess({ code }) && this.hasLayers({ code }))
            })
            break;
          case 'upload':
            list = list.filter(code => {
              return this.isUploadEnabledByCode({ code }) || this.hasAdminAccess({ code })
            })
            break
          case 'factsheet':
            list = list.filter(code => {
              return this.isFactsheetEnabledByCode({ code }) || this.hasAdminAccess({ code })
            })
            break
        }
      }

      // Turn the code into an option entry
      return list.map(code => {
        return {
          value: code,
          label: labelByCode({ code })
        }
      })
    },

    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    /**
     * The model is bound to the active municipality code
     */
    model: {
      get() {
        return this.getActiveMunicipality
      },
      set(code) {
        // Don't allow fast switching. Mapbox initiation will overlap and cause a crash
        if (this.disabled) return
        this.setActiveMunicipality({ code })
      }
    }
  },
  methods: {
    ...mapMutations('access', [
      'setActiveMunicipality'
    ])
  }
}
</script>

<style>
.MunicipalitySelection {
  min-width: 200px;
}
</style>