
import Pusher from 'pusher-js'

let instance = null

/**
 * Initiate pusher, just once 
 */
export const initiate = ({ token }) => {
  if (instance !== null) return 

  instance = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    authEndpoint: '/.netlify/functions/pusherauth',
    cluster: 'eu',
    forceTLS: true,
    auth: {
      headers: {
        authorization: 'Bearer ' + token
      }
    }
  })
}

/**
 * Enforce use of authenticated presence type channels
 *  https://pusher.com/docs/channels/using_channels/presence-channels 
 */
export const subscribe = ({ channel }) => {
  return new Promise((resolve, reject) => {
    if (! instance) {
      reject(new Error("Pusher is not yet available"))
      return
    }

    if (instance.channel(`presence-${channel}`)) {
      channel = instance.channel(`presence-${channel}`)
      resolve({ channel, members: channel.members })
      return
    }

    channel = instance.subscribe(`presence-${channel}`)
    channel.bind('pusher:subscription_succeeded', (members) => {
      resolve({ channel, members })
    })
    channel.bind('pusher:subscription_error', (err) => {
      console.log(err)
      reject(new Error('Failed to establish a connection'))
    })
  })
}

export const unsubscribe = ({ channel }) => {
  if (!instance) return 

  instance.unsubscribe(`presence-${channel}`)
}


