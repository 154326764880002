<template>
  <vSelect class="vSelect" v-bind="$attrs" :value="value" @input="$emit('input', $event)" >
    <template #open-indicator="{ attributes }">
      <SvgIcon v-bind="attributes" icon="chevron-down-regular"  />
    </template>

    <template #option="option">
      <slot name="option" v-bind:option="option">{{ option.label }}</slot>
    </template>
  </vSelect>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import SvgIcon from '@/components/common/SvgIcon.vue'

export default {
  name: 'VSelect',
  components: { SvgIcon, vSelect },
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    }
  }
}
</script>

<style lang="scss">
.vSelect {
  * {
    flex-shrink: 1;
  }
}
.vs__dropdown-toggle {
  background: white;
  padding: 2px 0 5px;
}
.vs__dropdown-toggle .SvgIcon.SvgIcon {
  font-size: 1rem;
}
</style>