<template>
  <div class="LocatieInformatie__Content">
    <div class="LocatieInformatie__Attachments mb-2">
      <strong>Bijlagen</strong>
      <template v-if="!isLoadingAws">
        <Lightbox
          v-if="images.length > 0"
          :gallery="images"
        >
          <template v-slot:silentbox-item="{ item }">
            {{ item.description }}
            <div class="ratio ratio-16x9">
              <div class="inner">
                <div
                  class="LocatieInformatie__Attachments__Image__Inline"
                  @click.stop
                  title="Download afbeelding"
                >
                  <a :href="item.src" :download="item.filename">
                    <SvgIcon icon="cloud-download-alt-solid" />
                  </a>
                </div>
                <img :src="item.src" />
              </div>
            </div>
          </template>
        </Lightbox>
        <p v-else>Geen bijlagen beschikbaar</p>
      </template>
      <p v-else>Loading...</p>
    </div>

    <template v-if="address">
      <p>
        <strong>Adres</strong><br/>
        {{ address.simple_address }} <br/>
        {{ address.postalcode }} {{ address.city }}
      </p>

      <p v-if="address.source">
        <strong>Bron</strong> <br/>
        {{ addressSource }}
      </p>

      <p v-if="coordinates">
        <strong>Coordinaten</strong><br/>
        {{ coordinates['lat'] }}, <br/>
        {{ coordinates['lng'] }}
      </p>
    </template>
    <p v-else>
      <strong>Adres</strong><br/>
      Onbekend
    </p>

    <p v-if="status">
      <strong>Status</strong><br/>
      {{ statusLabel }}
    </p>

    <p v-if="currentCpo">
      <strong>Charge Point Operator</strong><br/>
      {{ currentCpo.name }}
    </p>

    <p v-if="prio && prio.order">
      <strong>Prioriteit</strong><br/>
      {{ prio.order }}
      {{ prio.fase === 1 ? '(Fase 1)' : '' }}
      {{ isBraLiProvincieCode && prio.fase === 2 ? '(Fase 2)' : '' }}
    </p>

    <p v-if="plannerName">
      <strong>Gebruiker</strong><br/>
      {{ plannerName }}
    </p>

    <p v-if="remark">
      <strong>Toelichting</strong><br/>
      <template>
        {{ remark }}
      </template>
    </p>
    <!--        <p>
              <strong>Type</strong><br />
              {{ properties.type }}
            </p>-->
    <!--        <p>
              <strong>Toegang</strong><br />
              Publiek
            </p>-->
    <p>
      <strong>Locatiebeheer</strong>
    </p>
    <p>
      <strong>Beheerder</strong><br/>
      {{ beheerder ? beheerder.name : 'Geen' }}
    </p>
    <div class="the-validators">
      <p class="mb-0">
        <strong>Validatoren</strong>
      </p>
      <ul v-if="hasValidators">
        <li
          v-for="validator in validators"
          :key="validator.user_id"
        >
          {{ validator.name }}
        </li>
      </ul>
      <span v-else>
        Geen
      </span>
    </div>
  </div>
</template>

<script>
import chargingpointDataMixin from "@/mixins/chargingpoint/chargingpointDataMixin";
import chargingpointAttachmentsMixin from "@/mixins/chargingpoint/chargingpointAttachmentsMixin";
import Lightbox from "@/components/common/lightbox/Lightbox"
import SvgIcon from '@/components/common/SvgIcon'

import {attachmentIDToDescription} from "@/valueholders/attachments";

export default {
  components: {Lightbox, SvgIcon},
  mixins: [chargingpointDataMixin, chargingpointAttachmentsMixin],
  props: {
    chargingpoint: {
      type: Object,
      required: true
    }
  },
  watch: {
    chargingpoint() {
      this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
    }
  },
  created() {
    this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
  },
  computed: {
    images() {
      return Object.values(this.attachments)
        .filter(attachment => !!attachment.url)
        .map(attachment => ({
          src: attachment.url,
          description: attachmentIDToDescription({ id: attachment.id }),
          filename: attachment.filename,
        }))
    }
  },
}
</script>