import {ATTACHMENT_ID} from "@/valueholders/enums";

export const validAttachmentIDs = Object.values(ATTACHMENT_ID)

export const attachmentIDToDescription = ({ id }) => {
  let options = {
    top: 'Bovenaanzicht',
    side: 'Zijaanzicht',
  }

  return options[id] || 'Onbekend'
}
