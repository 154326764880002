<template>
  <div class="Fuse">
    <div class="Fuse__Field">
      <input 
        v-model="termModel" :placeholder="placeholder"
        type="text" 
        class="Fuse__Input" 
        autocomplete="off" 
        autocorrect="off" 
        autocapitalize="off" 
        spellcheck="false" />
      <SvgIcon class="Fuse__Icon" icon="search-regular" />
    </div>
  </div>
</template>
<script>
/**
 * Based on https://github.com/shayneo/vue-fuse/blob/master/src/components/VueFuse.vue
 */
import Fuse from 'fuse.js'

import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'Fuse',
  components: { SvgIcon },
  data () {
    return {
      fuse: null,
      internalTerm: '',
      results: []
    }
  },
  props: {
    /**
     * Fuse.js params
     */
    term: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
    /**
     * Additional component params
     */
    placeholder: {
      type: String,
      default: ''
    },
    defaultAll: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    termModel: {
      get() {
        return this.internalTerm
      },
      set(value) {
        this.internalTerm = value.trim()
      }
    }
  },
  watch: {
    list () {
      this.fuse.setCollection(this.list)
      this.fuseSearch()
    },
    term () {
      this.internalTerm = this.term.trim()
    },
    internalTerm () {
      this.$emit('queryChanged', { term: this.internalTerm } )
      this.fuseSearch()
    },
    results () {
      this.$emit('resultsChanged', { results: this.results })
    }
  },
  methods: {
    initFuse () {
      this.fuse = new Fuse(this.list, this.options)
      if (this.defaultAll) {
        this.results = this.list.map( item => { return {item} })
      }
      if (this.term) {
        this.internalTerm = this.term.trim()
      }
    },
    fuseSearch () {
      if (this.internalTerm === '') {
        if (this.defaultAll) {
          this.results = this.list.map( item => { return {item} })
        } else {
          this.results = []
        }
      } else {
        this.results = this.fuse.search(this.internalTerm)
      }
    }
  },
  mounted () {
    this.initFuse()
  }
}
</script>

<style lang="scss">
.Fuse {
  &__Field {
    position: relative;
  }

  &__Input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 2.5rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  &__Icon {
    font-size: 1.5rem;
    position: absolute;
    top: 6px;
    right: 7px;
  }
}
</style>