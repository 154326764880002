<template>
  <div class="Chart Chart--column">
    <div class="Chart__Header">
      <div class="Chart__Header__Icon">
        <SvgIcon icon="battery-bolt-regular" />
      </div>
      {{ title }}
    </div>
    <GChart
      type="ComboChart"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import SvgIcon from "@/components/common/SvgIcon";
import GoogleChartEntry from "@/components/map/sidebar/models/GoogleChartEntry";

import {animationDuration, labelColor, thresholdConfiguration} from '@/helpers/charts'

import chartMixins from "@/mixins/monitoring/chartMixins";


export default {
  name: 'ChargedVolumeColumnChart',
  components: {SvgIcon, GChart },
  mixins: [chartMixins],
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        limit: 500,
      })
    }
  },
  computed: {
    chartOptions() {
      const viewWindow = this.$_chartMixin_calculateViewWindow(this.options.limit, ...Object.values(this.data).map(entry => entry.volume))

      return {
        legend: { position: 'none' },
        hAxis: { textStyle: { bold: true, color: '#7F7F7F' } },
        vAxis: {format: 'decimal', textStyle: {bold: true, color: labelColor}, viewWindow: {max: viewWindow, min: 0}, gridlines: {count: 4}},
        bar: { groupWidth: "60%" },
        height: 140,
        width: 266,
        chartArea: { left: 30, top: 10, right: 20 },
        seriesType: 'bars',
        series: {
          1: thresholdConfiguration
        },
        animation: {
          duration: animationDuration
        },
        tooltip: {isHtml: true}
      }
    },
    chartData() {
      let chartSerieLabels = [
        'Maand',
        this.title,
        { role: 'annotation' },
        {role: 'tooltip', p: {html: true}},
        { role: 'style' },
        "Limiet",
        {role: 'tooltip', p: {html: true}}
      ]

      const result = [
        chartSerieLabels
      ]

      Object.values(this.data)
        .reverse()
        .forEach((entry) => {
          const preppedEntry = entry.noData ?
            GoogleChartEntry.createEmpty() :
            GoogleChartEntry.create(
              entry.label,
              entry.volume,
              `${entry.volume}`,
              `<strong>${entry.label}</strong> <br /> Geladen: <strong>${entry.volume} kWh</strong>`
            )

          preppedEntry.addLimit(this.options.limit, `${this.options.limit} kWh`)

          result.push(
            preppedEntry.toArrayWithLimit()
          )
        })

      return result
    }
  }
}
</script>