

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * This also returns true on strings that start with a 0
 */
export const isNumeric = (string) => {
  return /^\d+$/.test(string);
}

export const stringToArrayBuffer = ({ string }) => {
  let buf = new ArrayBuffer(string.length);
  let view = new Uint8Array(buf);
  for (let i=0; i!=string.length; ++i) view[i] = string.charCodeAt(i) & 0xFF;
  return buf;
}

export const multilineUnicodeString = (string) => /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@+!?&:;()#/= \r\n])+$/.test(string)