<template>
  <div style="display:none"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CarSharingPrognoseLayer',
  props: {
    loaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {
        name: 'carsharing',
        prop: 'deelauto_ev_'
      }
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentHasLayer',
      'DeploymentLayerName'
    ]),
    ...mapGetters('config', [
      'breakpoints'
    ]),
    ...mapGetters('scenarios', [
      'year', 'forecast'
    ]),
    ...mapGetters('prognose', [
      'getHighlighted', 'labelLayer'
    ]),
    relevantForcast() {
      return this.config.name === this.forecast
    },
    hasLayerData() {
      return this.DeploymentHasLayer({ layer: this.config.name })
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.config.name })
    },
    url() {
      return `mapbox://${this.MapboxAccount}.${this.sourceName}`
    }
  },
  watch: {
    loaded() {
      if (! this.$store.map.getLayer(this.config.name)) {
        this.addLayer()
      }
    },
    year() {
      this.updatePrognose()
    },
    getHighlighted() {
      this.updatePrognose()
    },
    forecast() {
      if (! this.relevantForcast) {
        if (this.$store.map.getLayer(this.config.name)) {
          this.removeLayer()
        }
      } else {
        if (! this.$store.map.getLayer(this.config.name)) {
          this.addLayer()
        } else {
          this.updatePrognose()
        }
      }
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  beforeDestroy() {
    if (this.loaded) {
      this.removeLayer()
    }
  },
  methods: {
    addLayer() {

      // Don't go any further if the layer details are missing
      // Or if the layer is not relevant to the selected forecast
      if (! this.hasLayerData) return
      if (! this.relevantForcast) return

      this.$store.map.addSource(this.config.name, {
        type: 'vector',
        url: this.url
      })
      this.$store.map.addLayer({ 
        "id": this.config.name,
        "type": "fill",
        "source": this.config.name,
        "source-layer": this.sourceName,
        "paint": {
          "fill-color": this.fillHexagons(),
          // Note: The legend opacity is linked
          "fill-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            10.2,
            0.7,
            13,
            0.4
          ],
          "fill-outline-color": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            "hsla(232, 67%, 60%, 0)",
            22,
            "hsla(232, 67%, 60%, 0.9)"
          ]
        }
      }, this.labelLayer)
    },
    removeLayer() {
      if (this.$store.map.getLayer(this.config.name)) {
        this.$store.map.removeLayer(this.config.name)
        this.$store.map.removeSource(this.config.name)
      }
    },
    updatePrognose() {
      if (this.$store.map.getLayer(this.config.name)) {
        this.$store.map.setPaintProperty(this.config.name, 'fill-color', this.fillHexagons())
      }
    },
    fillHexagons() {
      let value = ['get', `${this.config.prop}${this.year}`]


      let styleCondition = [
        "case",
      ]

      // The selected tiles
      this.getHighlighted.forEach(buurtcode => {
        styleCondition.push([
          "==",
          [
            "get",
            "buurtcode"
          ],
          buurtcode,
        ])
        styleCondition.push("hsl(23, 100%, 42%)")
      })

      return styleCondition.concat([
        // The layer we want to ignore
        [
          "<",
          value,
          0.05
        ],
        "hsla(0, 0%, 0%, 0)",
        // 0 - 1
        [
          "<=",
          value,
          this.breakpoints[0]
        ],
        "hsl(213, 50%, 80%)",
        // 1+ - 2
        [
          "<=",
          value,
          this.breakpoints[1]
        ],
        "hsl(213, 65%, 55%)",
        // 2+ - 4
        [
          "<=",
          value,
          this.breakpoints[2]
        ],
        "hsl(213, 80%, 35%)",
        // 4+
        [
          ">",
          value,
          this.breakpoints[2]
        ],
        "hsl(213, 95%, 20%)",
        // Default (required by MapBox)
        "hsla(0, 0%, 0%, 0)"
      ])
    },
  }
}
</script>

<style>

</style>