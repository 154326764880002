d<template>
  <div class="AssignValidatorsPanel d-flex">
    <aside class="AssignValidatorsPanel__Aside flex-shrink-0 mr-4">
      <h3 class="AssignValidatorsPanel__Title">
        Validator instellingen
      </h3>
      <div class="mt-3">
        <Form ref="form" @submit.prevent>
          <div class="d-flex justify-content-between">
            <span>Input overnemen</span>
            <b-form-checkbox v-model="locked" name="check-button" switch></b-form-checkbox>
            <span>vastzetten</span>
          </div>
          <FormField
            v-model="fields.beheerder.value"
            v-bind="fields.beheerder"
            :options="beheerderOptions" />
          <div class="d-flex justify-content-between">
            <span>Overschrijven</span>
            <b-form-checkbox v-model="additional" name="check-button" switch></b-form-checkbox>
            <span>Aanvullen</span>
          </div>
          <div class="d-flex align-items-center add-validator">
            <FormField
              v-model="fields.validatie.value"
              v-bind="fields.validatie"
              :options="usersByCodeAsOptions" />
            <b-icon-plus
              variant="dark"
              class="add-validator__icon u-clickable ml-2 mt-1"
              @click="$_chargingpointValidatorsMixin_addValidator"
            />
          </div>
        </Form>
        <div class="the-validators">
          <ul
            v-if="newValidators.length > 0"
            class="list-unstyled"
          >
            <li
              v-for="(validator, index) in newValidators"
              :key="validator.user_id"
              class="mb-1 d-flex"
            >
              {{validator.email}}
              <b-icon-trash
                variant="dark"
                class="the-validators__validator__icon u-clickable pull-right"
                @click="$_chargingpointValidatorsMixin_removeValidator(index)"
              />
            </li>
          </ul>
          <p
            v-else
            class="text-muted"
          >
            Geen validatoren geselecteerd.
          </p>
        </div>

        <b-button
          type="submit"
          variant="primary"
          @click="handleSubmit"
        >
          Toewijzen
        </b-button>
        <b-button
          type="submit"
          variant="outline-secondary"
          class="ml-1"
          @click="resetForm"
        >
          Reset
        </b-button>
        <div class="mt-3">
          <strong>Instructies</strong>
          <p>
            Kies eerst de laadpaal die je wilt updaten. Je kunt gebruik maken van SHIFT om een reeks te selecteren,
            of van CTRL/CMD om meerdere laadpalen te selecteren/deselecteren.
          </p>
          <p>
            Is er al een beheerder en/of validatoren toegewezen dan wordt dit als selectie overgenomen.
          </p>
          <p>
            Het selecteren van een beheerder is optioneel. Als je een beheerder toewijst, mag alleen die gebruiker de status van de locatie veranderen (bijv. van 'locatievoorstel' naar 'gevalideerd locatievoorstel'). Als je geen beheerder toewijst, mogen alle gebruikers (behalve gasten) de status van de locatie aanpassen.
          </p>
          <p>
            Je kunt een of meerdere validatoren toevoegen door op het plusje te klikken.
          </p>
          <p>
            Zet de schakelaar op ‘Overschrijven’ als je van de geselecteerde laadpalen de huidige ingestelde beheerder en validatoren wilt overschrijven met de nieuwe beheerder en validatoren die je links hebt gekozen. Zet de schakelaar op ‘Aanvullen’ als je van de geselecteerde laadpalende de huidig ingestelde beheerder en validatoren wilt behouden en daar alleen de validatoren die je links hebt gekozen <strong>extra</strong> aan toe wil voegen.
          </p>
          <p>
          </p>
            Zet de schakelaar op ‘Input overnemen’ als je van de geselecteerde laadpalen de huidig ingestelde beheerder en validatoren over wilt nemen in je selectie links. Zet de schakelaar op ‘Input vastzetten’ als je onafhankelijk van de geselecteerde laadpalen een beheerder en validatoren wil selecteren.
          <p>
            Gebruik het knopje 'Toewijzen' om de aanpassing door te voeren.
          </p>
        </div>
      </div>
    </aside>

    <div class="AssignValidatorsPanel__Main flex-grow-1 flex-shrink-1">
      <div v-if="itemsLoaded" class="cp-wrapper">
        <ChargingPointsTable
          :isBusy="isLoadingFauna"
          :items="items"
          @selected-items="addSelectedItems"
        />
      </div>
      <p v-else-if="isLoadingFauna" class="text-muted">
        Loading...
      </p>
      <p v-else class="text-muted">
        Geen voorgestelde of geplande laadpalen
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {BIconPlus,BIconTrash} from "bootstrap-vue";

import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'
import ChargingPointsTable from '@/components/admin/chargingpoints/ChargingPointsTable'

import chargingpointsLoadMixin from "@/mixins/chargingpoint/chargingpointsLoadMixin";
import chargingpointEditMixin from "@/mixins/chargingpoint/chargingpointEditMixin";
import chargingpointValidatorsMixin from "@/mixins/chargingpoint/chargingpointValidatorsMixin";
import userMixin from "@/mixins/common/userMixin";

import {CPO_TYPE} from "@/data/cpos";
import {reduceUserInformation} from "@/helpers/user";

export default {
  components: {Form, FormField, BIconPlus, BIconTrash, ChargingPointsTable},
  mixins: [chargingpointEditMixin, chargingpointValidatorsMixin, chargingpointsLoadMixin, userMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      additional: false,
      locked: false,
      fields: {
        beheerder: {
          label: 'Beheerder',
          value: '',
          type: 'select'
        },
        validatie: {
          label: 'Validatoren',
          value: '',
          type: 'select'
        },
      },
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getPlannedChargingPoints',
    ]),
    itemsLoaded() {
      return ! this.isLoadingFauna && this.items.length > 0
    },
    items() {
      return this.getPlannedChargingPoints
        .filter(chargingpoint => !chargingpoint.data.deleted_at)
        .sort((a, b) => (a.data.properties.id - b.data.properties.id))
        .map(chargingpoint => {
          let cpo = null
          let validators = ''

          if (chargingpoint.data.properties.stakeholders) {
            cpo = chargingpoint.data.properties.stakeholders.find(stakeholder => stakeholder.type === CPO_TYPE)
          }

          if (chargingpoint.data.properties.validators) {
            validators = Object.values(chargingpoint.data.properties.validators)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(validator => validator.name)
          }

          return {
            status: chargingpoint.data.properties.status,
            id: chargingpoint.data.properties.id,
            address: chargingpoint.data.address?.simple_address || 'Onbekend',
            planner: chargingpoint.data.properties.user?.name || 'Onbekend',
            cpo: cpo?.name || null,
            beheerder: chargingpoint.data.properties.beheerder?.name || '',
            validators: validators,
            chargingpoint: chargingpoint,
          }
        })
    }
  },
  watch: {
    additional: function (newVal) {
      this.locked = newVal
    },
    getActiveMunicipality() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    this.resetForm()
  },
  methods: {
    ...mapActions('planmode', [
      'addOrUpdateComment',
      'deleteComment',
    ]),
    async init() {
      this.resetForm()

      await this.$_chargingpointsLoadMixin_loadChargingPoints({
        code: this.getActiveMunicipality,
      })
    },
    handleSubmit() {
      const messageVNode = this.renderConfirmBoxContent()
      const title = this.additional ? 'Bevestig het aanvullen van deze validatoren.' : 'Bevestig het instellen van deze configuratie.'

      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: title,
        okVariant: 'danger',
        okTitle: 'Bevestigen',
        cancelTitle: 'Annuleren'
      })
        .then(confirmed => {
          if (confirmed) {
            this.isBusy = true

            Promise.all(
              this.selectedItems.map(async item => {
                const chargingpoint = item.chargingpoint

                let beheerder = this.usersByCode.find(user => user.user_id === this.fields.beheerder.value)
                if (beheerder !== undefined) {
                  beheerder = reduceUserInformation(beheerder)
                }

                // validators are overwritten by default, only merge if they are additionally added
                let validators = this.newValidators
                if (this.additional) {
                  validators = chargingpoint.data.properties.validators || []

                  const toAdd = this.newValidators.filter(validator => {
                    return validators.findIndex(oldValidator => {
                      return validator.user_id === oldValidator.user_id
                    }) === -1
                  })

                  validators = validators.concat(toAdd)

                  beheerder = chargingpoint.data.properties.beheerder
                }

                return await this.$_chargingpointEditMixin_save({
                  code: chargingpoint.data.code,
                  ref: chargingpoint.ref, // full ref
                  id: chargingpoint.data.properties.id,
                  stakeholders: chargingpoint.data.properties.stakeholders,
                  status: chargingpoint.data.properties.status,
                  user: {
                    name: chargingpoint.data.properties.user_name || chargingpoint.data.properties.user.name,
                  },
                  coordinates: chargingpoint.data.coordinates,
                  remark: chargingpoint.data.properties.remark,
                  geocoder: false,
                  beheerder: beheerder,
                  validators: validators,
                })
              })
            ).then(() => {
              this.resetForm()
              this.isBusy = false
            })
          }
        })
    },
    resetForm() {
      this.newValidators = []
      this.fields.beheerder.value = null
      this.fields.validatie.value = null

      this.additional = false
      this.locked = false

      /* this to reset the "new input"-validation after loading the data into the form */
      this.$nextTick(() => this.$refs.form.resetValidation())
    },
    addSelectedItems(items) {
      this.selectedItems = items

      // don't load data to dropdowns if locked
      if (this.locked) {
        return
      }

      this.resetForm()

      if (items.length === 1) {
        this.newValidators = [...items[0].chargingpoint.data.properties.validators || []]
        this.fields.beheerder.value = items[0].chargingpoint.data.properties.beheerder?.user_id || null
      }
    },

    renderBeheerder() {
      const h = this.$createElement

      const beheerder = this.usersByCode.find(user => user.user_id === this.fields.beheerder.value)

      if (beheerder === undefined) {
        return h('div', { class: 'text-muted'}, 'Geen beheerder')
      }

      return h('div', beheerder.name)
    },
    renderValidators() {
      const h = this.$createElement

      if (this.newValidators.length === 0) {
        return h('div', { class: 'text-muted'}, 'Geen validatoren')
      }

      const rendererValidators = []
      this.newValidators.forEach(validator => rendererValidators.push(
          h('li', [validator.name])
      ))

      return h('ul', { class: 'list-unstyled'}, rendererValidators)
    },
    renderChargingpoints() {
      const h = this.$createElement

      if (this.selectedItems.length === 0) {
        return h('div', { class: 'text-muted'}, 'Geen laadpalen geselecteerd')
      }

      const chargingpoints = []

      this.selectedItems.forEach(chargingpoint => chargingpoints.push(
          h('div', [`${chargingpoint.id} - ${chargingpoint.address}`])
      ))

      return h('ul', { class: 'list-unstyled'}, chargingpoints)
    },
    renderConfirmBoxContent() {
      const h = this.$createElement

      return h('b-row', [
        h('b-col', [
          this.additional ? null : h('div', { class: 'mb-3' }, [
            h('strong', ['Beheerder']),
            this.renderBeheerder(),
          ]),
          h('div', [
            h('strong', ['Validatoren']),
            this.renderValidators()
          ]),
        ]),
        h('b-col', [
          h('div', [
            h('strong', ['Laadpalen']),
            this.renderChargingpoints()
          ]),
        ]),
      ])
    },
  }
}
</script>

<style lang="scss" scoped>
.AssignValidatorsPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }

  .form-group.FormField {
    width: 100%;
  }

  .custom-switch {
    width: 25px;
  }
}
</style>