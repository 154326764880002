
/**
 * IMPORTANT: This module facilitates the user management in the admin panel, it does not track the user that is logged in.
 */

// {
//   "evmaps": {
//     "lucene-query": ["0542", "1930"],
//     "superuser": true,
//     "roles": [
//       {
//         "role": "guest",
//         "municipalities": [
//           "0542",
//           "1930"
//         ]
//       }
//     ]
//   }
// }

/**
 * Import Dependency
 */
import Vue from 'vue'

function getCurrentMinutes() {
  return Math.floor(Date.now() / 1000 / 60)
}

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  usersByCode: {}
}

const getters = {

  /**
   * 
   */
  getUsersByCode: (state) => ({ code }) => {
    return state.usersByCode[code] ? state.usersByCode[code].users : false
  },
  getUserDataAgeByCode: (state) => ({ code }) => {

    return state.usersByCode[code] 
      ? (getCurrentMinutes() - state.usersByCode[code].minutes)
      : -1
  }
}
const actions = {

}
const mutations = {
  setUsersDataByCode(state, { code, users }) {
    Vue.set(state.usersByCode, code, {
      users,
      minutes: getCurrentMinutes()
    })
  },
  setUserData(state, { user }) {
    console.log("store", user)
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
