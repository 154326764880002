<template>
  <div 
    :style="styles"
    class="hexagon"></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#64C7CC"
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  computed: {
    styles() {
      return {
        'backgroundColor': this.color,
        'color': this.color,
        'opacity': this.opacity
      }
    }
  }
}
</script>

<style lang="scss">
.hexagon {
  position: relative;
  width: 20px; 
  height: 11.55px;
  margin: 5.77px 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  &:before {
    bottom: 100%;
    border-bottom: 5.77px solid;
  }
  &:after {
    top: 100%;
    width: 0;
    border-top: 5.77px solid;
  }
}
</style>
