/*
* converts an evse_id to its location name for vattenfall
* Example:
* NL*NUO*EEVB*P1552162*2 => EVB_P1552162_2
*/
export const vtfEvseIdToLocationName = (id) => {
  if (id.startsWith("NL*NUO*E") === false) {
    return false
  }

  const connectionPoint = id
    .substring(8)
    .replace("*", "-")

  return connectionPoint.substring(0, connectionPoint.indexOf("*"))
}