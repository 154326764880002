<template>
  <div class="BarChart">
    <GChart         
      type="BarChart"
      :data="chartData"
      :options="chartOptions" />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import { numberWithDots } from '@/helpers/number'

export default {
  name: 'BarChart',
  components: { GChart },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Toename elektrische auto\'s',
        },
        isStacked: true,
        legend: { position: 'right', textStyle: { color: '#7F7F7F' } },
        hAxis: { textPosition: 'out', textStyle: { color: '#7F7F7F' } },
        vAxis: { textStyle: { bold: true, color: '#7F7F7F' } },
        bar: { groupWidth: "70%" },
        colors: [
          '#E4EDF5',
          '#689AC5',
          '#3B547B'
        ],
        height: 122,
        width: 475,
        chartArea: { left: 50, right: 135 },
        focusTarget: 'category',
        animation: {
          duration: 500
        }
      }
    }
  },
  computed: {
    chartData() {
      let chartSerieLabels = ['Jaar', 'Bewoners', 'Forenzen', 'Bezoekers', { role: 'annotation'}]
      let data = [
        chartSerieLabels
      ]
      Object.entries(this.data).forEach(entry => {
        data.push([
          entry[0], entry[1][0], entry[1][1], entry[1][2], numberWithDots(entry[1][3])
        ])
      })
      
      return data
    }
  }
}
</script>

<style>

</style>