<template>
  <div class="UserCreation d-flex flex-column flex-shrink-1">
    <div class="mt-2 d-flex">
      <div class="d-flex UserCreation__Inputwrapper">
        <label for="name" class="UserCreation__Label">Naam</label>
        <input id="name" v-model="name" />
      </div>
      <div class="d-flex UserCreation__Inputwrapper">
        <label for="email" class="UserCreation__Label ml-4">E-mail</label>
        <input id="email" class="ml-3" v-model="email" />
      </div>
    </div>

    <div class="mt-2 d-flex">
      <div class="d-flex UserCreation__Inputwrapper">
        <label for="password" class="UserCreation__Label">Wachtwoord</label>
        <input id="password" v-model="password" placeholder="Optioneel" />
      </div>
      <div class="d-flex UserCreation__Inputwrapper flex-grow-1">
        <label for="role" class="UserCreation__Label ml-4">Rol</label>
        <span class="flex-grow-1"><SimpleSelect id="role" class="w-100 ml-3" v-model="role" :options="options" /></span>
      </div>
    </div>

    <div class="w-100 mt-3 pr-4  d-flex">
      <b-button class="mr-3" :disabled="!valid" variant="cta" @click="handleSave">
        Gebruiker aanmaken
      </b-button>
    </div>
  </div>
</template>

<script>
import SimpleSelect from '@/components/form/SimpleSelect'

// import { generatePassword } from '@/services/password'

export default {
  name: 'UserCreation',
  components: { SimpleSelect },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      role: 'guest',

      options: [{ 
        label: 'gast',
        value: 'guest'
      }, {
        label: 'medewerker',
        value: 'editor'
      }, {
        label: 'beheerder',
        value: 'admin'
      }]
    }
  },
  computed: {
    /**
     * TODO: Actual validation
     */
    valid() {
      return this.name !== '' && this.email !== '' && this.role !== ''
    }
  },
  methods: {
    handleSave() {
      let user = {
        name: this.name,
        email: this.email,
        password: this.password, //  || generatePassword()
        app_metadata: {
          evmaps: {
            superuser: false,
            roles: []
          }
        }
      }

      // Add the roles, and add the municipality code to the selected role
      this.options.forEach(option => {
        let role = {
          role: option.value,
          municipalities: []
        }
        if (option.value === this.role) {
          role.municipalities.push(this.code)
        }

        user.app_metadata.evmaps.roles.push(role)
      })

      console.log("createUser", user)

      this.$emit('createUser', {
        code: this.code,
        user
      })
    }
  }
}
</script>

<style lang="scss">
.UserCreation {
  .SimpleSelect {
    max-width: 224px;
  }
  &__Label {
    width: 125px;
  }
  &__Inputwrapper {
    flex-direction: column;    
  }
}
</style>