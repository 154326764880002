<template>
  <div id="app">
    <router-view/>
    <TourBundle />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import TourBundle from "@/components/tour/TourBundle";

export default {
  name: 'App',
  components: {TourBundle},
  computed: {
    ...mapState(['pageTitle', 'dropbox'])
  },
  watch: {
    pageTitle(title) { 
      if (title !== null) { 
        this.setTitle({ title })
      }
    }
  },
  created() {
    // this.$rollbar.global({ itemsPerMinute: 5, maxItems: 5 }); // throttle
    // this.$rollbar.configure({ reportLevel: 'error' })

    this.setTitle({
      title: this.pageTitle
    })

    /**
     * Set the user's roles, which specify the user's access to municipalities
     */
    this.$auth.$watch('loading', () => {

      // if (this.$auth.user) {
      //   this.$rollbar.configure({ payload: { person: { email: this.$auth.user.email }}})
      // }

      if (this.$auth.user && this.$auth.user['https://evmaps.nl/forcepass']) {
        
        if (this.$route.name !== 'EnforcePassword') {
          try {
            this.$router.push({
              name: 'EnforcePassword'
            })
          } catch (e) {
            // console.log(e)
          }
        }

        return
      }

      let roles = (this.$auth.user) 
        ? this.$auth.user['https://evmaps.nl/roles'] || []
        : []
      this.setRoles({ roles })

      const userMetaData = this.$auth.user['https://evmaps.nl/tours'] || {}
      this.setUserTourData({ userMetaData })

      if (this.$auth.user) {
        this.getConfigs()
      }
    })
    
    // Utility code to obtain proxy lists for provincies - import `allMunicipalities` from municipality service
    // let codes = allMunicipalities().filter(municipality => municipality.provincie == '30').map(municipality => municipality.code)
    // console.log(codes.join('", "'))
  },
  methods: {
    ...mapMutations('access', [
      'setRoles'
    ]),
    ...mapMutations('tours', [
      'setUserTourData'
    ]),
    ...mapMutations('config', [
      'loadConfigs'
    ]),
    /**
     * Set the page title (seen in browser tabs)
     */
    setTitle({ title }) {
      document.title = title
    },
    /**
     * Get the municipality configs the user has access to
     */
    getConfigs: async function(){
      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/config', {
        headers: {
          authorization: 'Bearer ' + token
        }
      });

      const { configs } = await api.json()
      this.loadConfigs({ configs })
    },
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
  font-family: 'Gibson' !important;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
html {
  overflow-y: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
*, :after, :before {
  box-sizing: border-box;
  flex-shrink: 0;
}
</style>
