
import { gemeentenByDeployment } from '@/data/gemeenten_2020.js'
import { provincies } from '@/data/provincies_2020.js'

const gemeenten = gemeentenByDeployment[process.env.VUE_APP_DEPLOYMENT] || []

/**
 * Simply return all municipalities
 */
export const allMunicipalities = () => {
  return gemeenten
}

/**
 * Get Municipality Objects by code or slug
 */
export const municipalityByCode = ({ code }) => {
  let gemeente = gemeenten.find(gemeente => gemeente.code === code)
  return (gemeente) ? gemeente : false
}

export const municipalityBySlug = ({ slug }) => {
  let gemeente = gemeenten.find(gemeente => gemeente.slug === slug)
  return (gemeente) ? gemeente : false
}

/**
 * Get specific Municipality Attributes by code or slug
 */
export const codeToSlug = ({ code }) => {
  let gemeente = municipalityByCode({ code })
  return (gemeente) ? gemeente.slug : false
}
export const slugByCode = codeToSlug

export const slugToCode = ({ slug }) => {
  let gemeente = municipalityBySlug({ slug })
  return (gemeente) ? gemeente.code : false
}
export const codeBySlug = slugToCode

export const labelByCode = ({ code }) => {
  let gemeente = municipalityByCode({ code })
  return (gemeente) ? gemeente.label : false
}

export const labelBySlug = ({ slug }) => {
  let gemeente = municipalityBySlug({ slug })
  return (gemeente) ? gemeente.label : false
}

export const codeLabelByCode = ({ code }) => {
  let gemeente = municipalityByCode({ code })
  if (!gemeente) return false
  return gemeente.codeLabel ? gemeente.codeLabel : gemeente.code
}


/**
 * Provincie
 */
export const provincieByProvincieCode = ({ code }) => {
  let provincie = provincies.find(provincie => provincie.code === code)
  return (provincie) ? provincie : false
}
export const provincieByMunicipalityCode = ({ code }) => {
  let gemeente = municipalityByCode({ code })
  if (gemeente) {
    let provincie =  provincieByProvincieCode({ code: gemeente.provincie })
    return (provincie) ? provincie : false
  }
  return false
}
export const provincieLabelByProvincieCode = ({ code }) => {
  let provincie = provincieByProvincieCode({ code })
  return (provincie) ? provincie.label : false
}
export const provincieLabelByCode = ({ code }) => {
  let gemeente = municipalityByCode({ code })
  if (gemeente) {
    return provincieLabelByProvincieCode({ code: gemeente.provincie })
  }
  return false
}
export const provincieCodeByMunicipalityCode = ({ code}) => {
  let provincie = provincieByMunicipalityCode({ code })
  return (provincie) ? provincie.code : false  
}
export const countryByMunicipalityCode = ({ code }) => {
  let provincie = provincieByMunicipalityCode({ code })
  return (provincie) ? provincie.country : false
}

/**
 * Proxy (loading chargingstations for multiple municipalities in a read only mode)
 */
export const isProxyByCode = ({ code }) => {
  let gemeente = municipalityByCode({ code })
  if (gemeente) {
    return gemeente.proxy && Array.isArray(gemeente.proxy) && gemeente.proxy.length !== 0
  }
  return false
}

export const proxyCodesByCode = ({ code }) => {
  if (! isProxyByCode({ code })) {
    return []
  }
  let gemeente = municipalityByCode({ code })
  if (gemeente) {
    return gemeente.proxy
  }
  return []
}