
import { image } from '@/helpers/assets'

export const layers = [
  /**
   * The results layer
   */  
  {
    id: 'hexagons',
    label: 'De prognose resultaten',
    ignore: true,
    required: true,
    status: 'primary',
    type: 'results',
    sourceNameHint: 'prognose',
    tooltip: 'De resultaten van de prognose analyse, weergegeven in hexagonen'
  },
  /**
   * Data Layers
   */
  {
    id: 'fuelstations',
    label: 'Pompstation',
    icon: {
      slug: image({ name: 'legend/fuelpump.svg' }),
      width: 20
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'tankstations',
    tooltip: 'De huidige benzine stations'
  },
  {
    id: 'fossilfuel_stations',
    label: 'Pompstation',
    icon: {
      slug: image({ name: 'legend/fuelpump.svg' }),
      width: 20
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De huidige benzine stations'
  },
  {
    id: 'lngcng',
    label: 'LNG / CNG',
    icon: {
      slug: image({ name: 'legend/fuelpump.svg' }),
      width: 20
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'lngcng',
    tooltip: 'De huidige LNG / CNG pompstations'
  },
  {
    id: 'pplusr',
    label: 'Park + Ride',
    icon: {
      svg: false,
      slug: image({ name: 'legend/Parkandride-icon.svg' }),
      width: 20
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'penr',
    tooltip: 'P & R - Let op: deze informatie is niet altijd compleet'
  },
  {
    id: 'park_n_ride',
    label: 'Park + Ride',
    icon: {
      svg: false,
      slug: image({ name: 'legend/Parkandride-icon.svg' }),
      width: 20
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'penr',
    tooltip: 'P & R'
  },
  {
    id: 'hoppin',
    label: 'Hoppin',
    icon: {
      slug: image({ name: 'legend/hoppin.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'national',
    sourceNameHint: 'hoppin',
    tooltip: 'Hoppin locaties (mobiliteithubs).'
  },
  {
    id: 'ls',
    label: 'Laagspanning',
    icon: {
      slug: image({ name: 'legend/ls.png' }),
      width: 15
    },
    visible: true,
    layers: ['ls', 'ovkasten', 'ovkasten-labels'],
    status: 'primary',
    type: 'context',
    sourceNameHint: 'ls_kabels',
    tooltip: 'Het laagspanningsnet. Data van de netbeheerder'
  },
  {
    id: 'ls_kabels',
    label: 'Laagspanning',
    icon: {
      slug: image({ name: 'legend/ls.png' }),
      width: 15
    },
    visible: true,
    layers: ['ls_kabels', 'ovkasten', 'ovkasten-labels'],
    status: 'primary',
    type: 'national',
    tooltip: 'Het laagspanningsnet. Data van de netbeheerder'
  },
  {
    id: 'ms_kabels',
    label: 'Middenspanning',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'Het Middenspanningsnet. Data van de netbeheerder'
  },
  {
    id: 'ls_stations',
    label: 'Laagspanningskasten',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De laagspanningskasten. Data van de netbeheerder'
  },
  {
    id: 'ms_stations',
    label: 'Middenspanningskasten',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De Middenspanningskasten. Data van de netbeheerder'
  },
  {
    id: 'ms_ls_stations',
    label: 'Laag-/Middenspanningskasten',
    icon: {
      slug: image({ name: 'legend/middenspanning.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'middenspanning',
    tooltip: 'De Laag-/Middenspanningskasten. Data van de netbeheerder'
  },
  {
    id: 'nwegen',
    label: 'N Wegen',
    icon: {
      slug: image({ name: 'legend/ls.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'nwegen',
    tooltip: 'De N Wegen'
  },
  {
    id: 'verkeersstromen',
    label: 'Verkeersstromen',
    icon: {
      slug: image({ name: 'legend/trafficflow.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    // sourceNameHint: 'gemeentelijke_eigendom',
    tooltip: 'De verkeersintensiteit in rood tinten.'
  },
  {
    id: 'parking',
    label: 'Parkeervlak',
    icon: {
      slug: image({ name: 'legend/parkeren.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'parkeervlakken',
    tooltip: 'Parkeervlakken vanuit de GIS analyse. Geen exacte data.'
  },

  {
    id: 'parkeerdruk',
    label: 'Parkeerdruk',
    icon: {
      slug: image({ name: 'legend/parkeerdruk.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'parkeerdruk',
    tooltip: 'Parkeerdruk.'
  },
  {
    id: 'trees',
    label: 'Boom',
    icon: {
      slug: image({ name: 'legend/boom.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'bomenregister',
    tooltip: 'De bomen binnen de gemeente, veelal aangeleverd door de gemeente'
  },
  {
    id: 'trees_zone',
    label: 'Boom (met zone)',
    icon: {
      slug: image({ name: 'legend/boom.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    layers: ['trees_p', 'trees_z'],
    sourceNameHint: 'boombeschermzone_p',
    tooltip: 'De bomen binnen de gemeente, inclusief beschermzone'
  },
  {
    id: 'zh_nieuwbouwlocaties',
    label: 'Bouwlocaties',
    icon: {
      slug: image({ name: 'legend/construction-regular.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Klik op de locatie voor details'
  },
  {
    id: 'zh_toplocaties',
    label: 'Top Locaties',
    icon: {
      slug: image({ name: 'legend/top-location.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Winkelcentra, zembaden, religieuze gebouwen, etc. Klik op de locatie voor details.'
  },
  {
    id: 'zh_mrae_plankaart',
    label: 'MRA-E Plankaart',
    icon: {
      slug: image({ name: 'legend/chargingpoint-disabled.png' }),
      width: 15
    },
    visible: true,
    status: 'primary',
    type: 'national',
    tooltip: 'Oude plankaart MRA-E 2017 - 2019'
  },
  {
    id: 'visitorlocations',
    label: 'Bezoekerslocatie',
    icon: {
      svg: false,
      slug: image({ name: 'legend/locaties.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context', 
    sourceNameHint: 'bezoekers',
    tooltip: 'Locaties waar vermoedelijk verkeer op af komt'
  },
  {
    id: 'worklocations',
    label: 'Werklocaties',
    icon: {
      svg: false,
      slug: image({ name: 'legend/werklocaties.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'forenzen',
    tooltip: 'Locaties waar vermoedelijk forenzen verkeer op af komt'
  },
  {
    id: 'lichtmasten',
    label: 'Lichtmasten',
    icon: {
      svg: false,
      slug: image({ name: 'legend/locaties.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context', 
    sourceNameHint: 'lichtmasten',
    tooltip: 'Lichtmasten'
  },
  {
    id: 'straatmeubilair',
    label: 'Straat meubilair',
    icon: {
      svg: false,
      slug: image({ name: 'legend/locaties.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context', 
    sourceNameHint: 'straatmeubilair',
    tooltip: 'Straat meubilair'
  },

  {
    id: 'nieuwbouw',
    label: 'Gebiedsontwikkeling',
    icon: {
      slug: image({ name: 'legend/nieuwbouw.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'nieuwbouw_locaties',
    tooltip: 'Gebiedsontwikkeling locaties in de gemeente.'
  },

  {
    id: 'monuments',
    label: 'Gemeentelijke monumenten',
    icon: {
      slug: image({ name: 'legend/monuments.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'gemeente_monument',
    tooltip: 'Gemeentelijke monumenten.'
  },
  {
    id: 'deelgebied_vlak',
    label: 'Deelgebieden',
    icon: {
      slug: image({ name: 'legend/deelgebieden.png' }),
      width: 15
    },
    layers: ['deelgebied_vlak', 'deelgebied_vlak_text', 'deelgebied_lijn'],
    visible: true,
    status: 'primary',
    type: 'context',
    sourceNameHint: 'deelgebied_vlak',
    tooltip: 'De gemeente opgesplitst in 4 types buurten.'
  },
  {
    id: 'deelgebied_lijn',
    label: 'Deelgebiedgrensen',
    ignore: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'deelgebied_lijn',
    tooltip: 'Gestippelde lijn die de grensen van de deelgebieden markeren'
  },

  {
    id: 'grondeigendom',
    label: 'Grondeigendom',
    icon: {
      slug: image({ name: 'legend/grondeigendom.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'gemeente_eigendom',
    tooltip: 'De grond welke in eigendom is van de gemeente / overheid.'
  },
  
  {
    id: 'highway_rest_areas',
    label: 'Dienstenzones',
    icon: {
      slug: image({ name: 'legend/car-parking.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'highway_rest_areas',
    tooltip: 'Snelweg rustplaatsen.'
  },

  {
    id: 'car_pool_parking_areas',
    label: 'Carpoolplaatsen',
    icon: {
      slug: image({ name: 'legend/car-pool-parking.png' }),
      width: 15
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    sourceNameHint: 'car_pool_parking_areas',
    tooltip: 'Car-pool autos mogen hier worden geplaatst.'
  },

  /**
   * Planmode
   */
  {
    id: 'chargingpoints-realized',
    label: 'Gerealiseerde laadpaal',
    icon: {
      slug: image({ name: 'legend/chargingpoint-realized.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: 'De reeds gerealiseerde laadpaal locaties'
  },
  {
    id: 'chargingpoints-in-progress',
    label: 'In voorbereiding',
    icon: {
      slug: image({ name: 'legend/chargingpoint-in-progress.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: 'Deze laadpaal wordt binnenkort gerealiseerd'
  },
  {
    id: 'chargingpoints-definitive',
    label: 'Gevalideerde laadpaal',
    icon: {
      slug: image({ name: 'legend/chargingpoint-definitive.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: 'Deze laadpaal is definitief gepland'
  },
  {
    id: 'chargingpoints-suggestion',
    label: 'Voorgestelde laadpaal',
    icon: {
      slug: image({ name: 'legend/chargingpoint-suggestion.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: 'Een voorstel om deze locatie in te plannen'
  },
  {
    id: 'chargingpoints-rejected',
    label: 'Afgekeurde laadpaal',
    icon: {
      slug: image({ name: 'legend/chargingpoint-rejected.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode',
    tooltip: 'Een afgekeurde laadpaal'
  },
  {
    id: 'chargingpoints-alert',
    label: 'Aandachtspunt',
    icon: {
      slug: image({ name: 'legend/chargingpoint-alert.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: 'Dit punt markeert een aandachtspunt'
  },
  {
    id: 'chargingpoints-realized-private',
    label: 'Gerealiseerd semi-publiek',
    icon: {
      slug: image({ name: 'legend/chargingpoint-realized-private.png' }),
      width: 20
    },
    visible: false,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: 'De reeds gerealiseerde laadpaal locaties (semi-publiek)'
  },
  // FASTCHARGER
  {
    id: 'chargingpoints-fastcharger-realized',
    label: 'Gerealiseerde snellader',
    icon: {
      slug: image({ name: 'legend/chargingpoint-fastcharger-realized.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: ''
  },
  {
    id: 'chargingpoints-fastcharger-definitive',
    label: 'Gevalideerde snellader',
    icon: {
      slug: image({ name: 'legend/chargingpoint-fastcharger-definitive.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode', 
    tooltip: ''
  },
  {
    id: 'chargingpoints-fastcharger-suggestion',
    label: 'Voorgestelde snellader',
    icon: {
      slug: image({ name: 'legend/chargingpoint-fastcharger-suggestion.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode',
    tooltip: ''
  },
  {
    id: 'chargingpoints-fastcharger-rejected',
    label: 'Afgekeurde snellader',
    icon: {
      slug: image({ name: 'legend/chargingpoint-fastcharger-rejected.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode',
    tooltip: 'Een afgekeurde snellader'
  },
  // LAADPLEIN
  {
    id: 'chargingpoints-charging-hub-definitive',
    label: 'Gevalideerd laadplein',
    icon: {
      slug: image({ name: 'legend/chargingpoint-charging-hub-definitive.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode',
    tooltip: 'Een Gevalideerd laadplein'
  },
  {
    id: 'chargingpoints-charging-hub-suggestion',
    label: 'Voorgesteld laadplein',
    icon: {
      slug: image({ name: 'legend/chargingpoint-charging-hub-suggestion.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode',
    tooltip: 'Een voorgesteld laadplein'
  },
  {
    id: 'chargingpoints-charging-hub-rejected',
    label: 'Afgekeurd laadplein',
    icon: {
      slug: image({ name: 'legend/chargingpoint-charging-hub-rejected.png' }),
      width: 20
    },
    visible: true,
    status: 'primary',
    ignore: true,
    type: 'planmode',
    tooltip: 'Een Afgekeurd laadplein'
  },

  /**
   * Ignore - added for Admin panel
   */
  {
    id: 'ovkasten',
    label: 'Laagspanning - OV Kasten',
    ignore: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'ov_kasten',
    tooltip: 'De locatie van OV kasten is optionele data in de laagspannings kaart laag.'
  },
  {
    id: 'trees_zone_z',
    label: 'Bomen - beschermzone',
    ignore: true,
    type: 'context',
    status: 'secondary',
    sourceNameHint: 'boombeschermzone',
    tooltip: 'De beschermzone om bomen.'
  },

]

export const dataLayers = layers.filter(layer => layer.type !== 'planmode' && !layer.ignore)

export const planmodeLayers = layers.filter(layer => layer.type === 'planmode')
