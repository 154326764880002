export const CHARGINGPOINT_STATUS = {
  DEFINITIVE: "definitive",
  SUGGESTION: "suggestion",
  REJECTED: "rejected",
  REALIZED: "realized",
}

export const VALIDATION_VOTE = {
  APPROVED: 'Goedgekeurd',
  REJECTED: 'Afgekeurd',
}

export const ACTION_SIDEBAR_TAB = {
  INFO: 'info',
  MONITORING: 'monitoring',
  OPMERKINGEN: 'opmerkingen',
}

export const ATTACHMENT_ID = {
  TOP: 'top',
  SIDE: 'side',
}