/**
 * Import Dependency
 */

import { unique } from '@/helpers/array.js'
import { slugToCode } from '@/services/municipalities'

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  active: '',

  /**
   * User roles determine the access to municipalities
   */
  roles: []
}

const getters = {

  /**
   * The active Municipality (code)
   *  TODO: Refactor to use either this active state or the one from the config state
   */
  getActiveMunicipality: (state) => state.active,

  /**
   * Returns a list of codes of municipalities the user can access
   */
  municipalityCodeList: state => unique({ 
    arr: state.roles
      .reduce((result, role) => result.concat(role.municipalities), [])
  }),


  municipalityGuestCodeList: state => unique({
    arr: state.roles
      .filter(role => 'guest' === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), []
      )
  }),

  municipalityEditorCodeList: state => unique({
    arr: state.roles
      .filter(role => 'editor' === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), []
      )
  }),

  municipalityAdminCodeList: state => unique({
    arr: state.roles
      .filter(role => 'admin' === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), []
      )
  }),

  /**
   * Whether the user can access a particular municipality, by code or slug
   */
  hasAccess: (state, getters) => ({ slug, code }) => {
    if ( slug ) {
      code = slugToCode({ slug })
    }
    return getters.municipalityCodeList.includes(code)
  },

  hasAdminAccess: (state, getters) => ({ code, slug }) => {
    if ( slug ) {
      code = slugToCode({ slug })
    }
    return getters.municipalityAdminCodeList.includes(code)
  },


  /**
   * Check whether the user has editor or admin rights to the current municipality
   */
  canAccessPlanmode: (state, getters) => {
    let codes = getters.municipalityEditorCodeList.concat(getters.municipalityAdminCodeList)
    return codes.includes(getters.getActiveMunicipality)
  },

  /**
   * Check whether the user can pre-access the plan mode, 
   *  before it is made available to the regular municipality users
   */
  canPreAccessPlanMode: (state, getters) => {
    return getters.hasAdminAccess({ code: getters.getActiveMunicipality })
  },
  canPreAccessPlanModeBy: (state, getters) => ({ code, slug }) => {
    if ( slug ) {
      code = slugToCode({ slug })
    }
    return getters.hasAdminAccess({ code })
  }
}
const actions = {

}
const mutations = {
  /**
   * Set the active municipality, by code or slug
   */
  setActiveMunicipality(state, { code, slug }) {
    if ( slug ) {
      code = slugToCode({ slug })
    }
    state.active = code
  },
  setRoles(state, { roles }) {
    state.roles = roles
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
