<template>
  <div class="SideBar d-flex flex-column flex-shrink-0">
    
    <div class="SideBar__main d-flex flex-column p-4 flex-grow-1">
      <slot />
    </div>

    <div
      v-if="version"
      class="SideBar__version p-4 d-flex align-items-center">
      
      <!-- TODO: Add Vue-Tour: https://github.com/pulsardev/vue-tour -->
      <!-- <SvgIcon icon="question-circle-regular" class="mr-2" /> -->
      
      <span>
        Versie {{ version }}
      </span>
      <span class="flex-grow-1"></span>
      <span class="SideBar__logout d-flex align-items-center" @click="logout">
        Uitloggen
        <SvgIcon icon="sign-out-regular" class="ml-2" />
      </span>
    </div>

  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'

import { mapGetters } from 'vuex'

export default {
  name: "SideBar",
  components: { SvgIcon },
  computed: {
    ...mapGetters('version', [
      'version'
    ])
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
  }
}
</script>

<style lang="scss">
.SideBar {
  position: relative;
  background: white;
  box-shadow: -1px 0px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;
  color: #7F8FA4;
  max-height: 100%;

  &__main {
    overflow-y: scroll;
    flex-shrink: 1;
  }

  p {
    font-size: 14px
  }
  
  &__version {
    background: #E8EAF1;
  }
  &__logout {
    cursor: pointer;

    &:hover {
      color: darken(#7F8FA4, 20%);
    }
  }
}
</style>