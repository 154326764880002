<template>
  <NavBar class="ViewerNavBar">

    <div v-if="showPlanModeToggle" class="d-flex flex-column mr-3 align-items-center">
      <span style="color: white">Planmode</span>
      <ToggleSwitch v-model="planmode" /> 
    </div>

    <MunicipalitySelection v-if="canAccessMultipleMunicipalities" filter="prognose" class="mr-3" />

    <ScenarioInputs />
    
    <router-link 
      v-if="superuser" 
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Dashboard', params: { municipality: activeMunicipalitySlug } }">
      <SvgIcon icon="cog-regular" :hasFill="true" />
    </router-link>

    <!-- <router-link 
      v-if="isUploadEnabled || superuser" 
      class="d-inline-flex align-center mr-3" 
      :to="{ name: 'Upload', params: { municipality: activeMunicipalitySlug } }">
      <SvgIcon icon="file-upload-regular" :hasFill="true"  />
    </router-link> -->
    
  </NavBar>
</template>

<script>
import NavBar from '@/components/common/NavBar'
import ScenarioInputs from '@/components/navbar/ScenarioInputs'
import MunicipalitySelection from '@/components/navbar/MunicipalitySelection'
import SvgIcon from '@/components/common/SvgIcon'
import ToggleSwitch from '@/components/form/ToggleSwitch'

import { codeToSlug, isProxyByCode } from '@/services/municipalities'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ViewerNavBar',
  components: {
    NavBar, ScenarioInputs, MunicipalitySelection, SvgIcon, ToggleSwitch
  },
  computed: {
    ...mapGetters('config', [
      'isUploadEnabled',
      'isPlanmodeEnabled'
    ]),
    ...mapGetters('access', [
      'municipalityCodeList', 
      'getActiveMunicipality',
      'canAccessPlanmode',
      'canPreAccessPlanMode'
    ]),
    ...mapGetters('planmode', {
      isPlanmodeActive: 'isActive'
    }),
    /**
     * This may be not 100% accurate, due to feature restrictions. 
     *  To avoid complexity the dropdown is then simply shown with 1 item.
     */
    canAccessMultipleMunicipalities() {
      return this.municipalityCodeList.length > 1
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    activeMunicipalitySlug() {
      return codeToSlug({ code: this.getActiveMunicipality })
    },
    showPlanModeToggle() {
      return !isProxyByCode({ code: this.getActiveMunicipality }) && (this.superuser || (this.isPlanmodeEnabled && this.canAccessPlanmode) || this.canPreAccessPlanMode)
    },
    planmode: {
      get() {
        return this.isPlanmodeActive
      },
      set(active) {
        this.setPlanmodeState({ active })
      }
    }
  },
  methods: {
    ...mapMutations('planmode', {
      setPlanmodeState: 'setActiveState'
    })
  }
}
</script>