<template>
  <div class="Legend">
    <LegendPlanmode label="Laadpalen" class="mt-3" />
    <LegendPrognose class="mt-5" />
    <LegendSegment label="Context informatie" class="mt-5" />
  </div>
</template>

<script>

import LegendSegment from '@/components/sidebar/LegendSegment'
import LegendPrognose from '@/components/sidebar/LegendPrognose'
import LegendPlanmode from '@/components/sidebar/LegendPlanmode'

export default {
  name: 'Legend',
  components: {
    LegendSegment, LegendPrognose, LegendPlanmode
  }
}
</script>