<template>
  <select  
    class="SimpleSelect" 
    :disabled="disabled"
    :value="value" 
    @input="$emit('input', $event.target.value)">
    <option 
      v-for="(option, index) in options" 
      :disabled="!!option.disabled"
      :value="option.value"
      :key="`${index}-${option.value}`">
      {{ option.text || option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'SimpleSelect',
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => { return [] }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
/**
 * Styling based on Bootstrap-Vue
 */
.SimpleSelect {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
}
</style>