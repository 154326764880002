/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  model: 'NAL',
  year: 2025,
  access: 'public',
  forecast: 'determinative'
}

const getters = {
  model: state => state.model,
  year: state => state.year,
  access: state => state.access,
  forecast: state => state.forecast
}
const actions = {

}
// TODO: Store in localstorage (like P31 filters - see App.vue in Wijkprofielen)
// TODO: Or store as user_meta ...
const mutations = {
  setModel(state, { model, scenario }) {
    state.model = model || scenario
  },
  setYear(state, { year, scenario }) {
    state.year = year || scenario
  },
  setAccess(state, { access, scenario }) {
    state.access = access || scenario
  },
  setForecast(state, { forecast, scenario }) {
    state.forecast = forecast || scenario
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
